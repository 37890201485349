import React, { useState, useEffect, useRef } from "react";
import { Icon, Loader, Modal, Button, Dimmer, Tab } from "semantic-ui-react";
import { deleteTeam } from "../../../config/functions";

import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { CloseIcon } from "../../../../Assets/Icons/svg";
import useDeletePermissionModal from "../../../../hooks/Messages/useDeletePermissionModal";
import ToolTip from "react-power-tooltip";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import Details from "./details";
import { Resizable } from "re-resizable";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
import ReactTooltip from "react-tooltip";

function Preview({
  toggled,
  untoggle,
  targetedData,
  edit,
  setSearchTitle,
  setFilterOptions,
  setModalWidth,
  modalWidth,
}) {
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteHandler = async () => {
    const response = await deleteTeam(targetedData?.id);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      setFilterOptions({
        locations: [],
        customers: [],
        teams: [],
        superior: [],

        priority: [],
        status: [],
      });
      setSearchTitle("");
      toast.success(t.teams.team_deleted);
      toggleDeleteSuccessModal("close");
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.teams.team_connected);
      toggleDeleteSuccessModal();
    } else {
      toast.error(t.common.something_wrong);
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }
  };
  const { userPermissions, checkForPermission } = usePermissionsSimplified();

  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const t = useSelector(selectTranslations);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const panes = [
    {
      menuItem: t.common.details,
      pane: {
        key: "details",
        content: (
          <div className="pane-body">
            <Details untoggle={untoggle} targetedData={targetedData} />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Log targetedData={targetedData} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const generatePDF = () => {
    let csvData = [];

    let teams_data = [];

    teams_data.id = targetedData.id;
    teams_data.name = targetedData.attributes.name || t.common.na;

    teams_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, " ").trim()
      : t.common.na;
    teams_data.superior = targetedData?.attributes?.superior?.data
      ? targetedData?.attributes.superior?.data?.attributes?.firstName +
        " " +
        targetedData?.attributes.superior?.data?.attributes?.lastName
      : t.common.na;

    let locationsData = targetedData.attributes.locations?.data;
    let locationsString = "";

    if (locationsData?.length > 0) {
      locationsString = locationsData
        .map((each) => each.attributes?.name)
        .join(", ");
    } else {
      locationsString = t.common.na;
    }

    teams_data.locations = locationsString;

    let assigneePeople = targetedData.attributes?.customers?.data;
    let assignString = "";

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map((people) => people?.attributes?.name)
        .join(", ");
    } else {
      assignString = t.common.na;
    }
    teams_data.customer = assignString;

    let teamsMember = targetedData.attributes?.users?.data;
    let teamsMemberString = "";
    if (teamsMember?.length > 0) {
      teamsMemberString = teamsMember
        .map(
          (people) =>
            `${people?.attributes?.firstName || ""} ${people.attributes
              .lastName || ""}`
        )
        .join(", ");
    } else {
      teamsMemberString = t.common.na;
    }
    teams_data.teamMember = teamsMemberString;

    teams_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        " " +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      teams_data.id,
      teams_data.name,
      teams_data.description,
      teams_data.superior,
      teams_data.locations,
      teams_data.customer,
      teams_data.teamMember,
      teams_data.createdBy,
    ]);

    const columnWidths = [, 30, 30, 30, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      teams_data.name || t.teams.add,
      columnWidths,
      "A3"
    );
  };

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeletePermissionModal(true);
    setPopupIsOpen(false);
  };

  const downloadXLS = () => {
    let csvData = [];

    let teams_data = [];

    teams_data.id = targetedData.id;
    teams_data.name = targetedData.attributes.name || t.common.na;

    teams_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, " ").trim()
      : t.common.na;
    teams_data.superior = targetedData?.attributes?.superior?.data
      ? targetedData?.attributes.superior?.data?.attributes?.firstName +
        " " +
        targetedData?.attributes.superior?.data?.attributes?.lastName
      : t.common.na;

    let locationsData = targetedData.attributes.locations?.data;
    let locationsString = "";

    if (locationsData?.length > 0) {
      locationsString = locationsData
        .map((each) => each.attributes?.name)
        .join(", ");
    } else {
      locationsString = t.common.na;
    }

    teams_data.locations = locationsString;

    let assigneePeople = targetedData.attributes?.customers?.data;
    let assignString = "";

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map((people) => people?.attributes?.name)
        .join(", ");
    } else {
      assignString = t.common.na;
    }
    teams_data.customer = assignString;

    let teamsMember = targetedData.attributes?.users?.data;
    let teamsMemberString = "";
    if (teamsMember?.length > 0) {
      teamsMemberString = teamsMember
        .map(
          (people) =>
            `${people?.attributes?.firstName || ""} ${people.attributes
              .lastName || ""}`
        )
        .join(", ");
    } else {
      teamsMemberString = t.common.na;
    }
    teams_data.teamMember = teamsMemberString;

    teams_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        " " +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      teams_data.id,
      teams_data.name,
      teams_data.description,
      teams_data.superior,
      teams_data.locations,
      teams_data.customer,
      teams_data.teamMember,
      teams_data.createdBy,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 35 }, // Procedure Items column
      { wch: 35 }, // Created By column
      { wch: 35 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData?.attributes?.name || t.teams.add
    );
  };

  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.people.table.name, key: "name" },
    { label: t.assets.form.description, key: "description" },
    { label: t.teams.team_info_form.superior, key: "superior" },
    { label: t.teams.team_info_form.location, key: "location" },
    { label: t.teams.team_info_form.customers, key: "customer" },
    { label: t.teams.team_info_form.team_members, key: "team_members" },
    { label: t.common.created_by, key: "createdBy" },
  ];

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.teams.add} #{targetedData?.attributes?.countId}
              </div>

              <div className="flexbox align-center detail-header-buttons">
                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={userPermissions?.team?.delete}
                  isEdit={
                    userPermissions?.team?.edit ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                />

                <span
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                    setModalWidth(Math.max(window.innerWidth / 2, 514));
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {targetedData ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => {
          untoggle();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
      ></div>

      <Modal
        size="tiny"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <div className="checkmark-wrapper red">
              <Icon name="close" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">{t.teams.delete_teams}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setDeleteModal(false);
              deleteHandler();
            }}
          >
            {t.common.delete}
          </Button>
          <Button color="black" onClick={() => setDeleteModal(false)}>
            {t.common.keep}
          </Button>
        </Modal.Actions>
      </Modal>

      <DeletePermissionModal
        yesCallback={() => {
          setDeleteModal(false);
          deleteHandler();
        }}
        title={t.teams.add}
      >
        <span>{t.teams.delete_teams}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
