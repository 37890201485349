import React, { useState, useEffect, createRef, useRef } from "react";
import { Loader, Dimmer, Modal, Tab } from "semantic-ui-react";
import { useHistory } from "react-router";

import moment from "moment";
import { deleteSet, getAllWorkOrdersBySetId } from "../../../config/functions";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import ToolTip from "react-power-tooltip";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { CloseIcon } from "../../../../Assets/Icons/svg";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import useDeletePermissionModal from "../../../../hooks/Messages/useDeletePermissionModal";
import { toast } from "react-toastify";
import Details from "./details";
import { Resizable } from "re-resizable";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
import ReactTooltip from "react-tooltip";
// import HandleUnitType from '../../../components/parts/handleUnitype';

function Preview({
  targetedData,
  toggled,

  untoggle,
  edit,

  setRefresh,
  refresh,
  setModalWidth,
  modalWidth,
}) {
  const [fetching, setFetchLoading] = useState(true);
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [WOdata, setWOData] = useState(null);

  const [toggleWOModal, setToggleWOModal] = useState(false);

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const [toggleAsideModal, setToggleAsideModal] = useState(false);

  const { userPermissions, checkForPermission } = usePermissionsSimplified();

  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();

  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const headers = [
    { label: t.workOrders.table.ID, key: "id" },
    { label: t.workOrders.table.title, key: "title" },
    { label: t.assets.form.description, key: "description" },
    { label: t.workRequests.table.wr_status, key: "status" },
    { label: t.workRequests.table.wo_status, key: "wo_status" },
    { label: t.workOrders.table.priority, key: "priority" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.workRequests.table.asset, key: "asset" },
    { label: t.workRequests.form.people, key: "people" },
    { label: t.workRequests.form.teams, key: "teams" },
    { label: t.workRequests.table.wo_id, key: "wo_id" },
    { label: t.workRequests.table.sub_date, key: "sub_date" },
    { label: t.workRequests.table.sub_by, key: "sub_by" },
  ];

  const generatePDF = () => {
    let csvData = [];

    let wr_data = [];

    wr_data.id = data.id;
    wr_data.title = data.attributes.title || t.common.na;

    wr_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;

    wr_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    let assets = data.attributes?.assets?.data;
    let assetsString = "";

    if (assets?.length > 0) {
      assetsString = assets.map((asset) => asset?.attributes?.name).join(", ");
    } else {
      assetsString = t.common.na;
    }
    wr_data.asset = assetsString;

    let people = data?.attributes?.people?.data;
    let peopleString = "";

    if (people?.length > 0) {
      peopleString = people
        .map(
          (people) =>
            people?.attributes?.firstName + " " + people?.attributes?.lastName
        )
        .join(", ");
    } else {
      peopleString = t.common.na;
    }
    wr_data.people = peopleString;

    let teams = data?.attributes?.teams?.data;
    let teamsString = "";

    if (teams?.length > 0) {
      teamsString = teams.map((team) => team?.attributes?.name).join(", ");
    } else {
      teamsString = t.common.na;
    }
    wr_data.team = teamsString;

    wr_data.wo_id = data.attributes?.workOrder?.data?.id
      ? data.attributes?.workOrder?.data?.id
      : "";

    wr_data.sub_date = data?.attributes?.createdAt
      ? moment(data.attributes?.createdAt).format("YYYY-MM-DD")
      : t.common.na;

    wr_data.sub_by =
      data.attributes?.submittedBy?.data?.attributes?.firstName ||
      data?.attributes?.submittedBy?.data?.attributes?.lastName
        ? data.attributes?.submittedBy?.data?.attributes?.firstName +
          " " +
          data.attributes.submittedBy.data.attributes.lastName
        : t.common.na;

    csvData.push([
      wr_data.id,
      wr_data.title,
      wr_data.description,
      wr_data.status,
      wr_data.wo_status,
      wr_data.priority,
      wr_data.location,
      wr_data.asset,
      wr_data.people,
      wr_data.team,
      wr_data.wo_id,
      wr_data.sub_date,
      wr_data.sub_by,
    ]);

    const columnWidths = [10, 22, 22, 22, 23, 20, 23, 25, 25, 23, 16, 26, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      data?.attributes?.title || t.workRequests.title,
      columnWidths,
      "A3"
    );
  };

  const panes = [
    {
      menuItem: t.common.details,
      pane: {
        key: "details",
        content: (
          <div className="pane-body">
            <Details untoggle={untoggle} data={targetedData} />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Log targetedData={targetedData} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      let execlude_ids = ["popupTrigger"];
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
    });
  }, [popUpRef]);

  const deleteHandler = async () => {
    const response = await deleteSet(targetedData.id);

    if (response.status == 200) {
      toast.success(t.sets.delete_success);
      setData();
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setRefresh(!refresh);
    } else if (response.status == 400) {
      toast.error(t.sets.delete_error);
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeletePermissionModal(true);
    setPopupIsOpen(false);
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.sets.add} #{data?.attributes?.countId}
              </div>

              <div className="flexbox align-center detail-header-buttons">
                <DetailsViewOptions
                  // generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isPdf={false}
                  isDelete={userPermissions?.part?.delete}
                  isEdit={
                    userPermissions?.part?.edit ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                />

                <span
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                    setModalWidth(Math.max(window.innerWidth / 2, 514));
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </span>
              </div>
            </div>
          </div>

          <div className="body">
            {data ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled || toggleAsideModal
            ? "aside-modal-background active"
            : "aside-modal-background"
        }
        onClick={() => {
          setData();
          untoggle();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setMessage("");
          setToggleAsideModal(false);
        }}
      ></div>

      {/* delete modal */}

      <DeletePermissionModal
        yesCallback={() => {
          setDeleteModal(false);
          deleteHandler();
        }}
        title={t.sets.add}
      >
        <span>{t.sets.delete_set_statement}</span>
      </DeletePermissionModal>

      <Modal
        size="tiny"
        className="assets-modal"
        open={toggleWOModal}
        onClose={() => {
          setToggleWOModal(false);
          setWOData([]);
          // setToggleStatus(true);
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.sets.edit_set}</span>

          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setToggleWOModal(false);
                setWOData([]);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <span className="assets-label ml-1">{t.sets.wo_statement}</span>

          {WOdata?.length ? (
            <div
              style={{ maxHeight: "136px" }}
              className="deactive_work_order_list"
            >
              {WOdata.map((one) => {
                return (
                  <p
                    className={`detail-intro-link mb-1 ml-1 mr-1 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                    basic
                    onClick={() =>
                      history.push(`/work-orders?id=${one.attributes?.countId}`)
                    }
                  >
                    {`#${one.id} `} {one?.attributes?.title}
                  </p>
                );
              })}
            </div>
          ) : null}
        </Modal.Content>

        <div
          className={`modal-button-container mt-3 ${
            selectedLanguage == "en" ? "mr-6" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setToggleWOModal(false);
              setWOData([]);
            }}
            style={{ color: "black" }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Preview;
