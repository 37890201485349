import React, { useState, useEffect, useRef } from "react";
import { Loader, Dimmer, Tab } from "semantic-ui-react";

import { Country, State } from "country-state-city";

import { deleteVendor } from "../../../config/functions";
import useDeletePermissionModal from "../../../../hooks/Messages/useDeletePermissionModal";

import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { toast } from "react-toastify";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import VendorInfo from "./vendorInfo";
import BillingInfo from "./billingInfo";
import { CloseIcon } from "../../../../Assets/Icons/svg";
import moment from "moment";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import { Resizable } from "re-resizable";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
import ReactTooltip from "react-tooltip";

function Preview({
  toggled,
  untoggle,
  edit,
  targetedData,
  refresh,
  setRefresh,
  setModalWidth,
  modalWidth,
}) {
  const t = useSelector(selectTranslations);

  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [data, setData] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const popUpRef = useRef(null);

  let modeOptions = [
    {
      key: "1",
      value: "cash",
      text: t.vendors.mode.cash,
    },
    {
      key: "2",
      value: "credit card",
      text: t.vendors.mode.credit_card,
    },
    {
      key: "3",
      value: "bank transfer",
      text: t.vendors.mode.bank,
    },
    {
      key: "4",
      value: "procurement card",
      text: t.vendors.mode.procurement_card,
    },
    {
      key: "5",
      value: "check",
      text: t.vendors.mode.check,
    },
    {
      key: "6",
      value: "cliq",
      text: t.vendors.mode.cliq,
    },
  ];

  let accountTypeOption = [
    {
      key: "0",
      value: "salaries account",
      text: t.vendors.account.salary,
    },
    {
      key: "1",
      value: "basic bank account",
      text: t.vendors.account.basic,
    },
    {
      key: "2",
      value: "time deposit account",
      text: t.vendors.account.time_deposit,
    },
    {
      key: "3",
      value: "current account",
      text: t.vendors.account.current,
    },
    {
      key: "4",
      value: "time deposit with prizes",
      text: t.vendors.account.time_deposit_price,
    },
    {
      key: "5",
      value: "POS current account",
      text: t.vendors.account.pos,
    },
  ];

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      let execlude_ids = ["popupTrigger"];
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
    });
  }, [popUpRef]);

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.vendors.table.name, key: "name" },
    { label: t.customers.form.description, key: "description" },
    { label: t.customers.form.email, key: "email" },
    { label: t.customers.form.phone, key: "phone" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.customers.form.website, key: "website" },
    { label: t.customers.form.service_type, key: "service_type" },
    { label: t.common.industry, key: "industry" },
    { label: t.vendors.form.credit_days, key: "credit_days" },
    { label: t.vendors.form.start_date, key: "start_date" },
    { label: t.vendors.form.end_date, key: "end_date" },
    { label: t.customers.contact_name, key: "contact_name" },
    { label: t.customers.contact_email, key: "contact_email" },
    { label: t.customers.contact_phone, key: "contact_phone" },
    { label: t.customers.billing_name, key: "billing_name" },
    { label: t.locations.table.country, key: "country" },
    { label: t.locations.table.city, key: "city" },
    { label: t.customers.form.postal_code, key: "postal_code" },
    { label: t.customers.form.address, key: "address" },
    { label: t.customers.form.rate, key: "rate" },
    { label: t.customers.form.currency, key: "currency" },
    { label: t.vendors.form.pre_days, key: "pre_days" },
    { label: t.vendors.form.mode, key: "mode" },
    { label: t.vendors.form.bank, key: "bank" },
    { label: t.vendors.form.account, key: "account" },
    { label: t.vendors.form.acc_no, key: "acc_no" },
    { label: t.vendors.form.IBAN, key: "IBAN" },
    {
      label: t.vendors.form.disbursement_currency,
      key: "disbursement_currency",
    },
    { label: t.workOrders.table.createdByUser, key: "createdBy" },
  ];

  const downloadXLS = () => {
    let csvData = [];

    let customers_data = [];

    customers_data.id = targetedData.id;
    customers_data.name = targetedData.attributes.name || t.common.na;

    customers_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, " ").trim()
      : t.common.na;

    customers_data.email = targetedData?.attributes?.email
      ? targetedData.attributes?.email
      : t.common.na;

    customers_data.phone = targetedData?.attributes?.phone
      ? `+${targetedData?.attributes?.phone}`
      : t.common.na;

    customers_data.locations = targetedData?.attributes?.location?.data?.id
      ? targetedData?.attributes?.location?.data?.attributes?.name
      : t.common.na;

    customers_data.website = targetedData?.attributes?.website
      ? targetedData.attributes?.website
      : t.common.na;

    customers_data.service_type = targetedData?.attributes?.serviceType
      ? targetedData.attributes?.serviceType
      : t.common.na;

    customers_data.industry = targetedData?.attributes?.Industry
      ? targetedData.attributes?.Industry
      : t.common.na;

    customers_data.credit_days = targetedData?.attributes?.creditDays
      ? targetedData.attributes?.creditDays
      : t.common.na;

    customers_data.start_date = targetedData?.attributes?.startDate
      ? moment(targetedData?.attributes?.startDate).format("DD.MM.YYYY")
      : t.common.na;

    customers_data.end_date = targetedData?.attributes?.endDate
      ? moment(targetedData?.attributes?.endDate).format("DD.MM.YYYY")
      : t.common.na;
    customers_data.contact_name = targetedData?.attributes?.contactInformation
      ?.name
      ? targetedData?.attributes?.contactInformation?.name
      : t.common.na;

    customers_data.contact_email = targetedData?.attributes?.contactInformation
      ?.email
      ? targetedData?.attributes?.contactInformation?.email
      : t.common.na;

    customers_data.contact_phone = targetedData?.attributes?.contactInformation
      ?.phone
      ? `+${targetedData?.attributes?.contactInformation?.phone}`
      : t.common.na;

    customers_data.billing_name = targetedData?.attributes?.billingInformation
      ?.name
      ? targetedData?.attributes?.billingInformation?.name
      : t.common.na;

    customers_data.country = targetedData?.attributes?.billingInformation
      ?.country
      ? Country.getCountryByCode(
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.city = targetedData?.attributes?.billingInformation?.city
      ? State.getStateByCodeAndCountry(
          targetedData?.attributes?.billingInformation?.city,
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.postal_code = targetedData?.attributes?.billingInformation
      ?.postalCode
      ? targetedData?.attributes?.billingInformation?.postalCode
      : t.common.na;

    customers_data.address = targetedData?.attributes?.billingInformation
      ?.address
      ? targetedData?.attributes?.billingInformation?.address
      : t.common.na;

    customers_data.rate = targetedData?.attributes?.billingInformation?.rate
      ? targetedData?.attributes?.billingInformation?.rate +
        " " +
        (targetedData?.attributes?.billingInformation?.currency
          ? targetedData?.attributes?.billingInformation?.currency
          : "")
      : t.common.na;

    customers_data.currency = targetedData?.attributes?.billingInformation
      ?.currency
      ? targetedData?.attributes?.billingInformation?.currency
      : t.common.na;

    customers_data.pre_days = targetedData?.attributes?.billingInformation
      ?.preProcessDays
      ? targetedData?.attributes?.billingInformation?.preProcessDays
      : t.common.na;

    const mode = modeOptions.find(
      (status) =>
        status.value ==
        targetedData?.attributes?.billingInformation?.paymentDetails?.mode
    );

    customers_data.mode = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.mode
      ? mode?.text
      : t.common.na;

    customers_data.bank = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.bank
      ? targetedData?.attributes?.billingInformation.paymentDetails?.bank
      : t.common.na;

    const acc_type = accountTypeOption.find(
      (status) =>
        status.value ==
        targetedData?.attributes?.billingInformation?.paymentDetails
          ?.accountType
    );

    customers_data.account = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.accountType
      ? acc_type?.text
      : t.common.na;

    customers_data.acc_no = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.accountNo
      ? targetedData?.attributes?.billingInformation.paymentDetails?.accountNo
      : t.common.na;

    customers_data.IBAN = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.IBAN
      ? targetedData?.attributes?.billingInformation.paymentDetails?.IBAN
      : t.common.na;

    customers_data.disbursement_currency = targetedData?.attributes
      .billingInformation?.paymentDetails?.disbursementCurrency
      ? targetedData?.attributes?.billingInformation.paymentDetails
          ?.disbursementCurrency
      : t.common.na;

    customers_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        " " +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      customers_data.id,
      customers_data.name,
      customers_data.description,
      customers_data.email,

      customers_data.phone,
      customers_data.locations,
      customers_data.website,
      customers_data.service_type,

      customers_data.industry,
      customers_data.credit_days,
      customers_data.start_date,
      customers_data.end_date,

      customers_data.contact_name,
      customers_data.contact_email,
      customers_data.contact_phone,

      customers_data.billing_name,
      customers_data.country,
      customers_data.city,
      customers_data.postal_code,

      customers_data.address,
      customers_data.rate,
      customers_data.currency,
      customers_data.pre_days,

      customers_data.mode,
      customers_data.bank,
      customers_data.account,
      customers_data.acc_no,
      customers_data.IBAN,
      customers_data.disbursement_currency,

      customers_data.createdBy,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 10 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 15 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column
      { wch: 10 }, // Updated At column
      { wch: 10 }, // Updated At column
      { wch: 15 }, // Updated At column

      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData.attributes.name || t.vendors.vendor
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let customers_data = [];

    customers_data.id = targetedData.id;
    customers_data.name = targetedData.attributes.name || t.common.na;

    customers_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, " ").trim()
      : t.common.na;

    customers_data.email = targetedData?.attributes?.email
      ? targetedData.attributes?.email
      : t.common.na;

    customers_data.phone = targetedData?.attributes?.phone
      ? `+${targetedData?.attributes?.phone}`
      : t.common.na;

    customers_data.locations = targetedData?.attributes?.location?.data?.id
      ? targetedData?.attributes?.location?.data?.attributes?.name
      : t.common.na;

    customers_data.website = targetedData?.attributes?.website
      ? targetedData.attributes?.website
      : t.common.na;

    customers_data.service_type = targetedData?.attributes?.serviceType
      ? targetedData.attributes?.serviceType
      : t.common.na;

    customers_data.industry = targetedData?.attributes?.Industry
      ? targetedData.attributes?.Industry
      : t.common.na;

    customers_data.credit_days = targetedData?.attributes?.creditDays
      ? targetedData.attributes?.creditDays
      : t.common.na;

    customers_data.start_date = targetedData?.attributes?.startDate
      ? moment(targetedData?.attributes?.startDate).format("DD.MM.YYYY")
      : t.common.na;

    customers_data.end_date = targetedData?.attributes?.endDate
      ? moment(targetedData?.attributes?.endDate).format("DD.MM.YYYY")
      : t.common.na;
    customers_data.contact_name = targetedData?.attributes?.contactInformation
      ?.name
      ? targetedData?.attributes?.contactInformation?.name
      : t.common.na;

    customers_data.contact_email = targetedData?.attributes?.contactInformation
      ?.email
      ? targetedData?.attributes?.contactInformation?.email
      : t.common.na;

    customers_data.contact_phone = targetedData?.attributes?.contactInformation
      ?.phone
      ? `+${targetedData?.attributes?.contactInformation?.phone}`
      : t.common.na;

    customers_data.billing_name = targetedData?.attributes?.billingInformation
      ?.name
      ? targetedData?.attributes?.billingInformation?.name
      : t.common.na;

    customers_data.country = targetedData?.attributes?.billingInformation
      ?.country
      ? Country.getCountryByCode(
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.city = targetedData?.attributes?.billingInformation?.city
      ? State.getStateByCodeAndCountry(
          targetedData?.attributes?.billingInformation?.city,
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.postal_code = targetedData?.attributes?.billingInformation
      ?.postalCode
      ? targetedData?.attributes?.billingInformation?.postalCode
      : t.common.na;

    customers_data.address = targetedData?.attributes?.billingInformation
      ?.address
      ? targetedData?.attributes?.billingInformation?.address
      : t.common.na;

    customers_data.rate = targetedData?.attributes?.billingInformation?.rate
      ? targetedData?.attributes?.billingInformation?.rate +
        " " +
        (targetedData?.attributes?.billingInformation?.currency
          ? targetedData?.attributes?.billingInformation?.currency
          : "")
      : t.common.na;

    customers_data.currency = targetedData?.attributes?.billingInformation
      ?.currency
      ? targetedData?.attributes?.billingInformation?.currency
      : t.common.na;

    customers_data.pre_days = targetedData?.attributes?.billingInformation
      ?.preProcessDays
      ? targetedData?.attributes?.billingInformation?.preProcessDays
      : t.common.na;

    const mode = modeOptions.find(
      (status) =>
        status.value ==
        targetedData?.attributes?.billingInformation?.paymentDetails?.mode
    );

    customers_data.mode = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.mode
      ? mode?.text
      : t.common.na;

    customers_data.bank = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.bank
      ? targetedData?.attributes?.billingInformation.paymentDetails?.bank
      : t.common.na;

    const acc_type = accountTypeOption.find(
      (status) =>
        status.value ==
        targetedData?.attributes?.billingInformation?.paymentDetails
          ?.accountType
    );

    customers_data.account = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.accountType
      ? acc_type?.text
      : t.common.na;

    customers_data.acc_no = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.accountNo
      ? targetedData?.attributes?.billingInformation.paymentDetails?.accountNo
      : t.common.na;

    customers_data.IBAN = targetedData?.attributes?.billingInformation
      ?.paymentDetails?.IBAN
      ? targetedData?.attributes?.billingInformation.paymentDetails?.IBAN
      : t.common.na;

    customers_data.disbursement_currency = targetedData?.attributes
      .billingInformation?.paymentDetails?.disbursementCurrency
      ? targetedData?.attributes?.billingInformation.paymentDetails
          ?.disbursementCurrency
      : t.common.na;

    customers_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        " " +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      customers_data.id,
      customers_data.name,
      customers_data.description,
      customers_data.email,

      customers_data.phone,
      customers_data.locations,
      customers_data.website,
      customers_data.service_type,

      customers_data.industry,
      customers_data.credit_days,
      customers_data.start_date,
      customers_data.end_date,

      customers_data.contact_name,
      customers_data.contact_email,
      customers_data.contact_phone,

      customers_data.billing_name,
      customers_data.country,
      customers_data.city,
      customers_data.postal_code,

      customers_data.address,
      customers_data.rate,
      customers_data.currency,
      customers_data.pre_days,

      customers_data.mode,
      customers_data.bank,
      customers_data.account,
      customers_data.acc_no,
      customers_data.IBAN,
      customers_data.disbursement_currency,

      customers_data.createdBy,
    ]);
    const columnWidths = [, 30, 30];

    DownloadPdfFileWithData(
      headers,
      csvData,
      customers_data.name || t.vendors.vendor,
      columnWidths,
      "A1"
    );
  };

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeleteModal(true);
    setDeletePermissionModal(true);
    setPopupIsOpen(false);
  };

  const deleteHandler = async () => {
    const response = await deleteVendor(targetedData.id);

    // console.log(response);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      toast.success(t.vendors.form.vendor_delete_success);
      toggleDeleteSuccessModal("data");
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.vendors.form.vendor_delete_related_error);
      toggleDeleteSuccessModal();
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      setData();
      untoggle();
      setRefresh(!refresh);
    }
  };

  const panes = [
    {
      menuItem: t.vendors.table.vendor_info,
      pane: {
        key: "vendor-info",
        content: (
          <div className="pane-body">
            <VendorInfo
              untoggle={untoggle}
              refresh={refresh}
              setRefresh={setRefresh}
              targetedData={targetedData}
            />
          </div>
        ),
      },
    },
    {
      menuItem: t.customers.form.billing_information,
      pane: {
        key: "billing-info",
        content: (
          <div className="pane-body">
            <BillingInfo
              untoggle={untoggle}
              refresh={refresh}
              setRefresh={setRefresh}
              targetedData={targetedData?.attributes?.billingInformation}
            />
          </div>
        ),
      },
    },
    // {
    //   menuItem: `${t.workOrders.details_view.log}`,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Logs targetedData={targetedData} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.vendors.vendor} #{data?.attributes?.countId}
              </div>
              <div className="flexbox align-center detail-header-buttons">
                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={userPermissions?.vendor?.delete}
                  isEdit={
                    userPermissions?.vendor?.edit ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                />

                <span
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {data ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => {
          setData(null);
          untoggle();
          setFetchLoading(true);
        }}
      ></div>

      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.vendors.vendor}
      >
        <span>{t.vendors.form.delete_vendor_statement}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
