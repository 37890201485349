export default {
  na: "N/A",
  download: "Download",
  action_btn:"Action Menu",
  required_field: "Field is required",
  characters_field: "Your text exceeds the character limit",
  cancel_statement: "Are you sure you want to cancel?",
  cancel_2statement:
    "Are you sure you want to cancel? The changes you made won’t be saved.",
  ok: "OK",
  unread:"Unread",
  yes_cancel: "Yes, cancel",
  invalid_msg:"The uploaded file format is invalid. Please ensure the file matches the valid format",
  submit: "Submit",
  valid_files_video:".jpg, .png, .gif, .svg, .webp, HEIC, .mp4, .webm, .mov ",
  valid_files_img:".jpg, .png, .gif, .svg, .webp, HEIC",
  bulk_upload:"Bulk upload",
  upload_img:"Upload image",
  next: "Next",
  keep: "Keep",
  edit: "Edit",
  back: "Back",
  delete: "Delete",
  remove_file: "Remove file?",
  remove: "Remove",
  add: "Add",
  allowed_type: "Allowed Type:",
  no_data: "No data available",
  added_success: "added successfully",
  update: "Update",
  update_by: "Updated By",
  cancel: "Cancel",
  updated_success: "Updated successfully",
  add_a_category: "Add a New category",
  add_a_jobTitle: "Add Job Title",
  enter_category_name: "Enter category name",
  enter_job_name: "Enter job title name",
  enter_jobTitle_name: "Enter job title name",
  details: "Details",
  orders: "Orders",
  created_by: "Created By",
  yes_remove: "Yes, Remove",
  files: "Files",
  click_qr_to_download: "Click on QR code to download",
  name: "Name",
  type: "Type",
  size: "Size",
  view: "View",
  no_result_found: "No result found",
  no_results_found: "No results found",
  yes: "Yes",
  no: "No",
  hi: "Hi",
  my_profile: "My Profile",
  settings: "Settings",
  sign_out: "Sign out",
  profile_information: "Profile Information",
  user_name: "User Name",
  user_email: "User Email",
  phone: "Phone",
  profile_image: "Profile Image",
  change_password: "Change Password",
  old_password: "Old Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  save: "Save",
  saved: "Saved",
  password_validation:
    "'The password should contain numbers, capital letters, small letters and specials characters #@!*& '",
  min_6_symbol: "Minimum 6 symbols",
  max_50_symbol: "Maximum 50 symbols",
  password_should_match: "Password doesn't match, re-enter password",
  language: "Language",
  company_profile: "Company Profile",
  on: "on",
  invited_by: "Invited By",
  company_information: "Company Information",
  description: "Description",
  company_name: "Company Name",
  company_logo: "Company Logo",
  industry: "Industry",
  country: "Country",
  currency: "Currency",
  timezone: "Timezone",
  contact_information: "Contact Information",
  license_information: "License Information",
  plan: "Plan",
  licenses: "Licenses",
  company_profile_update_success: "Company profile updated successfully.",
  later: "Later",
  add_files: "Add files",
  invalid_email: "Invalid email",
  forgot_password_success: "Link sent successfully. Please check your mail.",
  phone_number: "Phone Number",
  address: "Address",
  sms: "SMS",
  reach_quiqflow: "Reach Quiqflow",
  about: "About",
  contact: "Contact",
  notifications: "Notifications",
  date_placeholder: "dd/mm/yyyy",
  something_went_wrong: "Something went wrong, Please try again later.",
  of: "of",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  all: "All",
  export_pdf: "Export Pdf",
  export_excel: "Export Excel",
  close: "Close",
  page_action: "Page actions",
  share: "Share",
  download_sheet: "Download sheets",
  download_pdf: "Download as PDF",
  upload: "Upload",
  print: "Print",
  download_QR: "Download QR Code",
  there_no: "There are no",
  there_no_customer: "There are no",
  added: "added",
  added_customer: "added",
  something_wrong: "Something went wrong. Please try after some time",
  loading: "Loading",
  reset: "Reset",
  hrs: "Hrs",
  files_attachments:".jpg, .png, .gif, .svg, .webp, HEIC, .mp4, .webm, .mov, .txt, .pdf, .csv, .doc, .docx, .xls, .xlsx",
  yr: "Yr",
  no_option: "No option Found",
  add_to: "Add to",
  newOrder: "New Order",
  reason: "Reason",
  power_by: "Powered by",
  quantity_must_be_greater_than_zero: "Quantity should be greater than zero",
  cost_must_be_greater_than_zero: "Unit cost should be greater than zero",
  valid_digits: "Exceeds 8 digits",
  excel_download: "Download Sheet",
  upload_sheet: "Upload Sheet",
  mark_read: "Mark all as read",
  early_noti: "Earlier",
  update_date: 'Date Updated',
  completed_date:"Date Completed",
  start_date:"Start Date",
  done_date:"Date Done",
  cancel_date:"Date Cancelled",
  file_upload_valid:"The uploaded file size exceeds",
  file_upload_second:"MB. Please upload a file size within the size limit.",
  number_error_1:"The number of uploaded files should not exceeds ",
  number_error_2:"files.",
  days:{
    Sun: "Sun",
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat"
  }
};
