import { cloneDeep, filter } from "lodash";
import React, { useEffect, useState, createRef, useRef } from "react";
import _ from "lodash";
import { Input, DropdownMenu, DropdownItem, Modal } from "semantic-ui-react";
import CategoryDropdown from "../../components/common/CategoryDropdown";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import * as auth from "../../modules/Auth/_redux/authRedux";
import {
  getUsersByRole,
  getAllCustomers,
  IMAGES_URL,
  getAllCategories,
  getAllParentLocations,
  getAllAssets,
  getAllLocations,
  getUserData,
  toggle,
} from "../../config/functions";
import location_icon from "../../../Assets/Icons/loc_option_parent.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import {
  ActionBtnIcon,
  AddIconWithCircle,
  BarcodeIcon,
  Close,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  NewSheetIcon,
  NewXlsIcon,
  PdfIcon,
  QRcodeIconInactive,
  SearchIcon,
  ShareIcon,
  TextSnippet,
  UploadIcon,
  WhiteDownChevron,
  initData,
} from "../../../Assets/Icons/svg";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";

import moment from "moment";

import { useDispatch, useSelector, connect } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";

import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import DownloadPdfFileWithData from "../../../services/DownloadPdfFileWithData";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";

import DatePicker from "react-multi-date-picker";
import AssetsBulkUploadModal from "../../components/modal/assets/UploadBulkAssetsModal";
import AssetsBulkUploadResult from "../../components/modal/assets/AssetsBulkUploadResult";
import QRCode from "react-qr-code";
import QrScanner from "qr-scanner";
import useDeleteResultModal from "../../../hooks/Messages/useDeleteResultModal";
import PriorityDropdown from "../../components/common/PriorityDropdown";

import {
  fetchAllAssetData,
  fetchAllData,
  fetchAssets,
  fetchAssetsInitData,
  fetchAssetsWithFilter,
} from "../../../redux/reducers/assetReducer";
import { toast } from "react-toastify";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import Quagga from "@ericblade/quagga2";
import QRComponentToPrint from "./preview/qrCodeComponent";
import ToolTip from "react-power-tooltip";
import LocationSearchDropdown from "../../components/WorkOrder/LocationSearchDropdown";

const Filters = (props) => {
  const {
    data,
    fileData = [],
    setFilterOptions,
    filterOptions,
    searchTitle,
    setSearchTitle,
    debounceValue,
    setloader,
    loader,
    setSelectedDates,
    selectedDates,
    sortData,
    setSortdata,
    roleData,
    allIds,
    setAssetUploadModal,
    assetUploadModal,
    initData,
  } = props;
  const t = useSelector(selectTranslations);
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [showTooltip, setShowTooltip] = useState(false);

  const lang = useSelector((state) => state.i18n.lang);
  const [activeBtn, setActiveBtn] = useState(2);

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const [selectedQrcode, setSelectedQrcode] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [filtersTrigger, setFiltersTrigger] = useState({
    vendors: {
      initOptions: [],
      options: [],
    },
    status: {
      initOptions: [],
      options: [
        {
          key: "status0",
          value: "",
          text: t.common.all,
        },
        {
          key: "status1",
          value: "active",
          text: t.assets.active,
        },
        {
          key: "status2",
          value: "inactive",
          text: t.assets.inActive,
        },
        {
          key: "status3",
          value: "dispose",
          text: t.assets.disposed_asset,
        },
      ],
    },

    customers: {
      initOptions: [],
      options: [],
    },
    categories: {
      initOptions: [],
      options: [],
    },
    locations: {
      initOptions: [],
      options: [],
    },
    createdByUser: {
      initOptions: [],
      options: [],
    },
    wed: {
      options: [
        { key: 0, text: t.assets.warranty_options.any_date, value: 0 },
        { key: 1, text: t.assets.warranty_options.today, value: 1 },
        { key: 2, text: t.assets.warranty_options.tomorrow, value: 2 },
        { key: 3, text: t.assets.warranty_options.next_7_days, value: 3 },
        { key: 5, text: t.assets.warranty_options.this_month, value: 4 },
        { key: 7, text: t.assets.warranty_options.this_year, value: 5 },
        { key: 13, text: t.assets.warranty_options.expired, value: 6 },
      ],
    },

    createdAt: {
      options: [
        { key: 0, text: t.assets.created_at_options.any_date, value: 0 },
        { key: 1, text: t.assets.created_at_options.today, value: 1 },
        { key: 2, text: t.assets.created_at_options.this_month, value: 2 },
        { key: 3, text: t.assets.created_at_options.this_year, value: 3 },
        { key: 4, text: t.assets.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.assets.created_at_options.last_month, value: 5 },
        { key: 7, text: t.assets.created_at_options.last_year, value: 6 },
      ],
    },
  });

  const [filterSearch, setFilterSearch] = useState({
    customer: "",
    vendor: "",
    createdByUser: "",
    area: "",
    model: "",
    barcode: "",
    location: "",
  });

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        initOptions: filtersTrigger.locations.initOptions,
        options:
          filterSearch.location.length > 0
            ? _.filter(filtersTrigger.locations.initOptions, isMatch)
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.location]);

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const [resetKey, setResetKey] = useState(0);

  const [newData, setNewData] = useState(data);

  const [assetUploadResultModal, setAssetUploadResultModal] = useState(false);
  const [bulkUploadResultData, setBulkUploadResultData] = useState([]);
  const [
    bulkUploadResultSuccessData,
    setBulkUploadResultSuccessData,
  ] = useState();
  const [openQRmodal, setOpenQRmodal] = useState(false);

  const [selectedFile, setSelectedFile] = useState({});

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const { userPermissions, checkForPermission } = usePermissionsSimplified();

  /* Excel and Pdf file Headers Data */
  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.assets.table.name, key: "name" },
    { label: t.assets.table.serialNumber, key: "serialNumber" },
    { label: t.assets.form.location, key: "location" },

    { label: t.assets.form.category, key: "category" },
    { label: t.assets.table.model, key: "model" },
    { label: t.assets.form.placed_in_service_date, key: "placedInServiceDate" },
    { label: t.assets.table.status, key: "status" },
    // { label: t.assets.form.description, key: 'description' },
    // { label: t.assets.form.image, key: 'image' },

    // { label: t.assets.form.procedure, key: 'procedure' },
    // { label: t.assets.form.customer, key: 'customer' },
    // { label: t.assets.form.vendor, key: 'vendor' },
    // { label: t.assets.form.parts, key: 'parts' },
    // { label: t.assets.form.warranty_file, key: 'expiryFile' },
    // { label: t.assets.form.warranty_expiration_date, key: 'expiryDate' },

    // {
    //   label: t.assets.form.additional_information,
    //   key: 'additionalInformation',
    // },
  ];

  /* The function `downloadXLS` fetches assets data, formats it, and downloads it as an Excel file. */
  const downloadXLS = async () => {
    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );

    if (!arraysNotEmpty && searchTitle == "" && selectedDates.length == 0) {
      let mydata = await dispatch(
        fetchAllAssetData({ organisationId, role })
      );

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    let csvData = [];
    for (let i = 0; i < fileData.length; i++) {
      let assets_data = [];

      assets_data.id = fileData[i].id;
      assets_data.name = fileData[i].attributes.name || t.common.na;
      assets_data.status =
        fileData[i]?.attributes?.status === "active"
          ? t.assets.active
          : fileData[i]?.attributes?.status === "inactive"
          ? t.assets.inActive
          : t.assets.disposed_asset;
      assets_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description
        : t.common.na;
      assets_data.additionalInformation = fileData[i].attributes
        ?.additionalInformation
        ? fileData[i].attributes?.additionalInformation
        : t.common.na;
      assets_data.image = fileData[i].attributes?.image?.data?.attributes?.url
        ? IMAGES_URL + fileData[i].attributes.image.data.attributes.url
        : t.common.na;
      assets_data.serialNumber =
        fileData[i].attributes.serialNumber || t.common.na;
      assets_data.location = fileData[i].attributes?.location?.data?.attributes
        ?.name
        ? fileData[i].attributes.location.data.attributes.name
        : t.common.na;
      assets_data.subLocation = fileData[i].attributes?.subLocations?.data
        ?.attributes?.name
        ? fileData[i].attributes.subLocations.data.attributes.name
        : t.common.na;

      let areaData = fileData[i].attributes?.area;
      let areaString = "";

      if (areaData?.length > 0) {
        areaString = areaData.map((each) => each.area).join(", ");
      } else {
        areaString = t.common.na;
      }

      assets_data.area = areaString;

      assets_data.category = fileData[i].attributes?.categories?.data
        ?.attributes?.name
        ? fileData[i].attributes.categories.data.attributes.name
        : t.common.na;
      assets_data.model = fileData[i].attributes.model || t.common.na;

      let procedureData = fileData[i].attributes?.procedure?.data;
      let procedureString = "";

      if (procedureData?.length > 0) {
        procedureString = procedureData
          .map((each) => each.attributes?.title)
          .join(", ");
      } else {
        procedureString = t.common.na;
      }

      assets_data.procedure = procedureString;
      assets_data.customer = fileData[i].attributes?.customer?.data?.attributes
        .name
        ? fileData[i].attributes.customer.data.attributes.name
        : t.common.na;
      assets_data.vendor = fileData[i].attributes?.vendor?.data?.attributes
        ?.name
        ? fileData[i].attributes?.vendor?.data?.attributes?.name
        : t.common.na;

      let partsData = fileData[i].attributes?.parts?.data;
      let partsString = "";

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(", ");
      } else {
        partsString = t.common.na;
      }

      assets_data.parts = partsString;

      assets_data.warrantyExpirationDate = fileData[i].attributes
        ?.warrantyExpirationDate
        ? moment(fileData[i].attributes?.warrantyExpirationDate).format(
            "YYYY-MM-DD"
          )
        : t.common.na;

      assets_data.warranty_file = fileData[i].attributes?.warrantyFile?.data
        ?.attributes?.url
        ? IMAGES_URL +
          fileData[i].attributes?.warrantyFile?.data?.attributes?.url
        : t.common.na;

      assets_data.placedInServiceDate =
        moment(fileData[i].attributes.createdAt).format("YYYY-MM-DD") ||
        t.common.na;

      csvData.push([
        assets_data.id,
        assets_data.name,
        assets_data.serialNumber,
        assets_data.location,
        assets_data.category,
        assets_data.model,
        assets_data.placedInServiceDate,
        assets_data.status,
        // assets_data.description,
        // assets_data.image,
        // assets_data.subLocation,
        // assets_data.area,
        // assets_data.procedure,
        // assets_data.customer,
        // assets_data.vendor,
        // assets_data.parts,
        // assets_data.warranty_file,
        // assets_data.warrantyExpirationDate,

        // assets_data.additionalInformation,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 15 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 35 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.assets.title
    );
  };

  /*
   * The function `generatePDF` fetches assets data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = async () => {
    let mydata = await dispatch(
      fetchAllAssetData({ organisationId, role})
    );
    let csvData = [];
    if (mydata.payload.response) {
      fileData = mydata.payload.response;
    } else {
      toast.error(t.common.something_wrong);
    }
    for (let i = 0; i < fileData.length; i++) {
      let assets_data = {};

      assets_data.id = fileData[i].id;
      assets_data.name = fileData[i].attributes.name || t.common.na;
      assets_data.status =
        fileData[i]?.attributes?.status === "active"
          ? t.assets.active
          : fileData[i]?.attributes?.status === "inactive"
          ? t.assets.inActive
          : t.assets.disposed_asset;
      assets_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description
        : t.common.na;
      assets_data.image = fileData[i].attributes?.image?.data?.attributes?.url
        ? IMAGES_URL + fileData[i].attributes.image.data.attributes.url
        : t.common.na;
      assets_data.serialNumber =
        fileData[i].attributes.serialNumber || t.common.na;
      assets_data.location = fileData[i].attributes?.location?.data?.attributes
        ?.name
        ? fileData[i].attributes.location.data.attributes.name
        : t.common.na;
      assets_data.subLocation = fileData[i].attributes?.subLocations?.data
        ?.attributes?.name
        ? fileData[i].attributes.subLocations.data.attributes.name
        : t.common.na;

      let areaData = fileData[i].attributes?.area;
      let areaString = "";

      if (areaData?.length > 0) {
        areaString = areaData.map((each) => each.area).join(", ");
      } else {
        areaString = t.common.na;
      }

      assets_data.area = areaString;

      assets_data.category = fileData[i].attributes?.categories?.data
        ?.attributes?.name
        ? fileData[i].attributes.categories.data.attributes.name
        : t.common.na;
      assets_data.model = fileData[i]?.attributes?.model
        ? fileData[i].attributes.model
        : t.common.na;

      let procedureData = fileData[i].attributes?.procedure?.data;
      let procedureString = "";

      if (procedureData?.length > 0) {
        procedureString = procedureData
          .map((each) => each.attributes?.title)
          .join(", ");
      } else {
        procedureString = t.common.na;
      }

      assets_data.procedure = procedureString;
      assets_data.customer = fileData[i].attributes?.customer?.data?.attributes
        .name
        ? fileData[i].attributes.customer.data.attributes.name
        : t.common.na;
      assets_data.vendor = fileData[i].attributes?.vendor?.data?.attributes
        ?.name
        ? fileData[i].attributes?.vendor?.data?.attributes?.name
        : t.common.na;

      let partsData = fileData[i].attributes?.parts?.data;
      let partsString = "";

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(", ");
      } else {
        partsString = t.common.na;
      }

      assets_data.parts = partsString;

      assets_data.warranty_file = fileData[i].attributes?.warrantyFile?.data
        ?.attributes?.url
        ? IMAGES_URL +
          fileData[i].attributes?.warrantyFile?.data?.attributes?.url
        : t.common.na;

      assets_data.warrantyExpirationDate = fileData[i].attributes
        ?.warrantyExpirationDate
        ? moment(fileData[i].attributes?.warrantyExpirationDate).format(
            "YYYY-MM-DD"
          )
        : t.common.na;

      assets_data.placedInServiceDate =
        moment(fileData[i].attributes.createdAt).format("YYYY-MM-DD") ||
        t.common.na;

      assets_data.additionalInformation = fileData[i].attributes
        ?.additionalInformation
        ? fileData[i].attributes?.additionalInformation
        : t.common.na;

      csvData.push([
        assets_data.id,
        assets_data.name,
        assets_data.serialNumber,
        assets_data.location,
        assets_data.category,
        assets_data.model,
        assets_data.placedInServiceDate,
        assets_data.status,
      ]);
    }

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(headers, csvData, t.assets.title, [], "A3");
  };

  /*
   * The `handleDeselect` function clears the selected dates.
   */
  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedDates([]);
    setResetKey((pre) => !pre);
  };

  /*
   * The function `handleBrowseButtonClick` triggers a click event on a file input element
   */
  const handleBrowseButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /**
   * The `handleQRCode` function takes a file, scans it for a QR code, extracts a numeric value from the QRcode result, and sets the serial number in the filter options.
   */
  const handleQRCode = (setFilterSearch, file) => {
    console.log(activeBtn, "dds");

    setSelectedQrcode(file);
    if (activeBtn == 2) {
      QrScanner.scanImage(file)
        .then((result) => {
          console.log(result);
          setFilterSearch({
            ...filterOptions,
            serialNumber: result,
          });
        })
        .catch((err) => {
          toast.error(t.assets.no_barcode);
          setSelectedQrcode(null);
          setSelectedFile({});
        });
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("e: ", e);
        // Decode barcodes from the image
        Quagga.decodeSingle(
          {
            src: e.target.result,
            numOfWorkers: 0, // Needs to be set to 0 when used within a React component
            locate: true,
            // inputStream: {
            //   size: 2000, // Image size
            // },
            decoder: {
              readers: ["code_128_reader"], // Supported barcode formats
            },
          },
          (result) => {
            console.log("result: ", result);
            if (result && result.codeResult) {
              setFilterSearch({
                ...filterOptions,
                serialNumber: result.codeResult.code,
              });
            } else {
              toast.error(t.assets.no_barcode);
              setSelectedFile({});
            }
          }
        );
      };
      reader.readAsDataURL(file);
    }

    setOpenQRmodal(false);
  };

  /*
   * The function `toggleDeleteSuccessModal` opens a delete result modal and clears the selected file.
   */
  const toggleDeleteSuccessModal = () => {
    setOpenQRmodal(true);
    setSelectedFile({});
  };

  /*
   * The `handleChange` function takes an array of dates, converts them to ISO format, and sets the selected dates.
   */
  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setSelectedDates(isoDates);
    }
  };

  /*
   * Fetching all filters data and set in state
   */
  useEffect(() => {
    (async function() {
      // const fetchData = await getAllCustomers(organisationId);
      let the_options_customer = [];
      let the_options_people = [];
      let the_options_category = [];
      let the_options_location = [];

      // if (fetchData.response) {
      //   fetchData.response.forEach((el) => {
      //     the_options_customer.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.logo.data
      //           ? IMAGES_URL + el.attributes.logo.data.attributes.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      const fetchDataCategory = await getAllCategories(organisationId);

      if (fetchDataCategory.response) {
        fetchDataCategory.response.forEach((el) => {
          the_options_category.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
        });
      }

      // getAllParentLocations,
      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );

      let the_options = [];
      let parentOptions = [];
      let subOptions = [];

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(roleData === "super_admin")) {
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              className: "main-location-dropdown-option",
              image: { src: location_icon },
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options.push(subOption);
            }
          });
        });

        // Update the options state
        // setOptions((prev) => ({
        //   ...prev,
        //   locations: the_options,
        // }));

        // return the_options;
      }

      // const fetchDataPeople = await getUsersByRole(
      //   [
      //     'user',
      //     'supervisor',
      //     'view_only',
      //     'admin',
      //     'requester',
      //     'super_admin',
      //   ],
      //   organisationId
      // );

      // if (fetchDataPeople.response) {
      //   the_options_people = fetchDataPeople.response.map((el) => {
      //     return {
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.firstName + ' ' + el.lastName,
      //       image: {
      //         avatar: true,
      //         src: el?.profileImage?.url
      //           ? IMAGES_URL + el?.profileImage?.url
      //           : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     };
      //   });
      // }
      setFiltersTrigger({
        ...filtersTrigger,
        customers: {
          ...filtersTrigger.customers,
          initOptions: the_options_customer,
          options: the_options_customer,
        },
        categories: {
          ...filtersTrigger.categories,
          initOptions: the_options_category,
          options: the_options_category,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options,
          options: the_options,
        },
        createdByUser: {
          ...filtersTrigger.createdByUser,
          initOptions: the_options_people,
          options: the_options_people,
        },
      });
    })();
  }, []);
  let _newData = newData;

  /*
   * Fetching asset data based on filters
   */
  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchAssetsWithFilter({
          organisationId,
          searchName: debounceValue,
          page: "1",
          pageSize: "25",
          location: filterOptions.locations,
          category: filterOptions.categories,
          status: filterOptions.status[0],
          serialNumber: filterOptions.serialNumber,
          placedDate:
            selectedDates.length == 1 ? [selectedDates[0]] : selectedDates,
          sort: sortData,
          role,
      
        })
      );
      setloader(false);
    })();
  }, [filterOptions, debounceValue, filterSearch, selectedDates]);

  return (
    <>
      {initData?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
          <div className="flexbox table-right-dropdown d-flex ">
            <PriorityDropdown
              title={t.assets.table.status}
              options={filtersTrigger.status.options}
              selectedValues={filterOptions.status}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    status: e.target.value ? [e.target.value] : [],
                  });
                } else {
                  setFilterOptions({
                    ...filterOptions,
                    satus: [],
                  });
                }
              }}
              icon={false}
              image={false}
              pill={false}
            />

            <LocationSearchDropdown
              title={t.workOrders.table.location}
              options={filtersTrigger.locations.options}
              selectedValues={filterOptions.locations}
              searchValue={filterSearch.location}
              handleSearchValue={handleSearchLocationChange}
              onClick={(value) => {
                let finalValue = [value.value];
                if (!value?.parentId) {
                  let subData = filtersTrigger.locations.initOptions.filter(
                    (el) => el?.parentId === value.value
                  );

                  let subDataValues = subData?.map((item) => item?.value);
                  let subValues = subData?.map((item) => item?.value);

                  subDataValues.push(value.value);
                  let filterOptionsValues = filterOptions.locations;

                  let filteredSubDataValues = subDataValues.filter((value) =>
                    filterOptionsValues.includes(value)
                  );

                  let valuesNotInFilterOptions = subValues.filter(
                    (value) => !filterOptionsValues.includes(value)
                  );
                  let valuesInFilterOptions = subValues.filter((value) =>
                    filterOptionsValues.includes(value)
                  );

                  if (filteredSubDataValues?.length > 0) {
                    if (
                      subValues.every((value) =>
                        filterOptionsValues.includes(value)
                      ) &&
                      !filterOptionsValues.includes(value.value)
                    ) {
                      finalValue = [value.value];
                    } else if (
                      valuesNotInFilterOptions?.length > 0 &&
                      filterOptionsValues?.includes(value.value)
                    ) {
                      finalValue = [...valuesInFilterOptions, value.value];
                    } else if (valuesNotInFilterOptions?.length > 0) {
                      finalValue = [...valuesNotInFilterOptions, value.value];
                    } else {
                      finalValue = [...filteredSubDataValues];
                    }
                  } else {
                    finalValue = [...subDataValues];
                  }
                }

                let newArray = toggle(filterOptions.locations, finalValue);
                setFilterOptions({
                  ...filterOptions,
                  locations: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  location: "",
                });
              }}
              // onClick={(value) => {
              //   let newArray = toggle(filterOptions.locations, value.value);
              //   setFilterOptions({
              //     ...filterOptions,
              //     locations: newArray,
              //   });

              //   setFilterSearch({
              //     ...filterSearch,
              //     location: "",
              //   });
              // }}
            />

            <CategoryDropdown
              title={t.assets.form.category}
              options={filtersTrigger.categories.options}
              selectedValues={filterOptions.categories}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    categories: [...filterOptions.categories, e.target.value],
                  });
                } else {
                  let filteredCategories = filterOptions.categories.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    categories: filteredCategories,
                  });
                }
              }}
              icon={false}
              image={false}
            />

            <div className="wo-date-picker" key={resetKey}>
              <DatePicker
                style={
                  selectedDates.length <= 1
                    ? { width: "154px" }
                    : { width: "181px" }
                }
                value={selectedDates}
                onChange={handleChange}
                range
                showMonthDropdown
                showYearDropdown
                placeholder={t.assets.form.placed_in_service_date}
                className={"active"}
                format="DD/MM/YY"
                // locale={lang == 'ar' ? arabic_ar : ''}
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
              >
                <button
                  onClick={handleDeselect}
                  className="btn-datepicker blue"
                >
                  {t.common.reset}
                </button>
              </DatePicker>
              {/* <img
              className="date-picker-image procedure"
              style={{ left: "-31px" }}
              src={calendar_icon}
            /> */}
            </div>

            {selectedDates.length > 0 ||
            Object.entries(filterOptions).some(([key, value]) =>
              Array.isArray(value) ? value.length > 0 : value !== ""
            ) ? (
              <div className="form-button-container mt-3">
                <button
                  // content={t.procedures.form.submit.add}
                  // primary
                  onClick={() => {
                    setSelectedDates([]);
                    setFilterOptions({
                      status: [],
                      locations: [],
                      createdByUser: [],
                      customers: [],
                      vendors: [],
                      categories: [],
                      serialNumber: "",
                    });
                  }}
                  // loading={buttonStatus == 1}
                  style={{ marginTop: "-10px" }}
                  className="form-action-button dashboard"
                >
                  {t.dashboard.cards.clear}
                </button>
              </div>
            ) : null}

            {/* Page Actions Dropdown */}
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <div
              // style={selectedLanguage == "en" ? {width:"129px"} : {width:"100px"}}
              className="table-searchbar d-flex flex-wrap-ans"
            >
              <button
                className={`upload-btn  mb-2-css`}
                onClick={() => setOpenQRmodal(true)}
              >
                {t.parts.upload_code}
              </button>
            </div>
            <div className="table-searchbar work-order-upload">
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: "search" }}
                placeholder={t.workOrders.filter.search}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>
            <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
              <UncontrolledDropdown className="mr-2" direction="down">
                <DropdownToggle
                  style={{ position: "relative", cursor: "pointer" }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  color="primary"
                  className="option-toggle"
                >
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "ar" ? "top right" : "top left"
                    }
                    show={showTooltip}
                    textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                    arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                    fontSize="12px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="4px 4px 4px 8px"
                    borderRadius="5px"
                    static
                    moveRight="10px"
                    moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
                  >
                    <span className="work-order-tooltip">
                      {t.common.page_action}
                    </span>
                  </ToolTip>
                  <ActionBtnIcon />{" "}
                </DropdownToggle>
                <DropdownMenu
                  className={selectedLanguage == "ar" ? "rtl asset" : ""}
                >
                  {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}

                  <DropdownItem onClick={() => downloadXLS()}>
                    <NewXlsIcon /> {t.common.excel_download}
                  </DropdownItem>
                  {userPermissions?.asset?.edit ? (
                    <>
                      <DropdownItem className="divider" />

                      <DropdownItem onClick={() => setAssetUploadModal(true)}>
                        <NewSheetIcon /> {t.common.upload_sheet}
                      </DropdownItem>
                    </>
                  ) : null}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>{" "}
          </div>

          {/* Asset upload modal open */}
        </div>
      ) : null}
      <AssetsBulkUploadModal
        isOpen={assetUploadModal}
        setModalOpenState={setAssetUploadModal}
        handleSuccess={async (res, failedData) => {
          setBulkUploadResultData(failedData);
          setBulkUploadResultSuccessData(res);
          if (!(role === "super_admin")) {
            const [userData] = await Promise.all([getUserData(user.id)]);

            dispatch(props.fulfillUser(userData));
          }

          if (initData?.length == 0) {
            const fetchData = await dispatch(
              fetchAssetsInitData({ organisationId, role })
            );
          }
          setAssetUploadResultModal(true);
          setAssetUploadModal(false);
          setFilterOptions({
            status: [],

            locations: [],
            createdByUser: [],
            customers: [],
            vendors: [],
            categories: [],
            serialNumber: "",
          });

          setSearchTitle("");
          setSelectedDates([]);
        }}
      />

      {/* Asset upload result modal open */}
      <AssetsBulkUploadResult
        isOpen={assetUploadResultModal}
        setModalOpenState={setAssetUploadResultModal}
        data={bulkUploadResultData}
        totalCount={bulkUploadResultSuccessData}
      />
      {/* Asset QRcode modal open */}
      <Modal
        className="assets-modal qr-modal"
        size="tiny"
        open={openQRmodal}
        onClose={() => setOpenQRmodal(false)}
      >
        <div className="header-container">
          <span className="assets-header"> {t.parts.upload_code}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => setOpenQRmodal(false)}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <div className="flexbox barcode-btns mb-5">
            <button
              active={activeBtn == 1}
              onClick={() => setActiveBtn(1)}
              className={`qr-button ${activeBtn == 1 ? "active" : ""}`}
            >
              <span>
                <BarcodeIcon />
              </span>{" "}
              {t.parts.form.barcode}
            </button>

            <button
              className={`qr-button ${activeBtn == 2 ? "active" : ""}`}
              active={activeBtn == 2}
              onClick={() => setActiveBtn(2)}
            >
              <span className="mr-1">
                <QRcodeIconInactive />
              </span>
              {t.parts.form.qrcode}
            </button>
          </div>

          {!selectedFile?.name ? (
            <div
              onClick={handleBrowseButtonClick}
              className="warrenty-file-dropzone d-flex align-items-center justify-content-between"
            >
              <div className="d-flex">
                <span className="mt-1">
                  <TextSnippet />
                </span>
                <div className="pl-8 pr-8">
                  <p className="p1 m-0">{t.workOrders.form.file}</p>
                  <p className="p2 m-0">{t.parts.form.attach_file}</p>
                </div>
              </div>
              <div>
                <AddIconWithCircle />
              </div>
              <input
                id="barcodePopup"
                type="file"
                hidden
                className="file-input"
                ref={fileInputRef}
                fluid
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length) {
                    setSelectedFile(e.target.files[0]);
                  }
                }}
              />
            </div>
          ) : null}

          <div className="modal-input d-block">
            {selectedFile?.name ? (
              <>
                <label
                  className={`text-label ${
                    selectedLanguage == "en" ? "ml-2" : "mr-2"
                  }`}
                >
                  <div className={"mt-3 mb-3"}>
                    <span className="file-label">{selectedFile?.name}</span>
                    <span
                      className={`${
                        lang == "ar" ? "c-pointer mr-3" : "c-pointer ml-3"
                      }`}
                      onClick={() => {
                        setSelectedFile({});
                        setFilterOptions({
                          ...filterOptions,
                          serialNumber: "",
                        });
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                  {activeBtn == 2 ? (
                    <div>
                      <QRCode
                        id="QRCode"
                        title="Parts QR Code"
                        value={true}
                        size={80}
                        disabled
                      />
                    </div>
                  ) : (
                    <div className="">
                      <QRComponentToPrint titleData={"ID"} isQRcode={false} />
                    </div>
                  )}
                </label>
              </>
            ) : null}
            <input
              id="barcodePopup"
              type="file"
              hidden
              className="file-input"
              ref={fileInputRef}
              fluid
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
          </div>
        </Modal.Content>
        {selectedFile?.name ? (
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-6" : "ml-10"
            }`}
          >
            <button
              onClick={() => setOpenQRmodal(false)}
              className="modal-form-action-button black"
              style={{ fontWeight: "500" }}
            >
              {t.common.cancel}
            </button>

            <button
              onClick={() => handleQRCode(setFilterOptions, selectedFile)}
              className="modal-form-action-button blue"
            >
              {t.common.view}
            </button>
          </div>
        ) : null}
      </Modal>

      {/* Asset delete result modal open */}
      <DeleteResultModal
        yesCallback={() => {
          toggleDeleteSuccessModal();
        }}
        closeCallback={() => {
          setOpenQRmodal(false);
          setSelectedFile({});
        }}
        deleteErrorMessage={true}
        btnName={t.workOrders.search_again}
      >
        <div className="mb-2">
          <Close />
        </div>
        <p className="status-modal-popup-message">
          {t.assets.no_related_QR_msg}
        </p>
      </DeleteResultModal>
    </>
  );
};

export default connect(null, auth.actions)(Filters);
