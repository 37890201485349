import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const WorkOrderItems = ({
  date,
  workOrderNumber,
  title,
  timestamp,
  isPast = true,
  setInstancesModal,
}) => {
  const history = useHistory();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  return (
    <div
      onClick={() => {
        setInstancesModal(false);
        history.push(`/work-orders?id=${workOrderNumber}`);
      }}
      className={`work-order-item c-pointer ${!isPast ? "blue" : ""}`}
    >
      <div className={`date-box ${!isPast ? "white" : ""}`}>
        <span className="day">{date.day}</span>
        <span className="month">{date.month}</span>
      </div>
      <div className="content-data">
        <div className="header-data">
          <span dir="ltr" className="work-order-number">
            {`#${workOrderNumber}`}
          </span>{" "}
          <span className="title">{title}</span>
        </div>
        <div className="timestamp">
          <span>{timestamp}</span>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderItems;
