import React, { useEffect, useState, createRef, memo } from "react";
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
  Loader,
  Sticky,
} from "semantic-ui-react";
import * as auth from "../../modules/Auth/_redux/authRedux";
import Pagination from "../../components/common/CommonPagination";
import Tooltip from "react-power-tooltip";

import Moment from "react-moment";

import DataTable from "react-data-table-component";

import moment from "moment";

import { NavLink } from "react-router-dom";

import { useLocation, useHistory } from "react-router";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";

import {
  getAllAssets,
  getLocationByID,
  getUserData,
  IMAGES_URL,
} from "../../config/functions";

import Add from "./add";
import Edit from "./edit";

import Preview from "./preview/index";

import md5 from "md5";

import Filters from "./filters";
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";

import { connect, useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import UserProfilePlaceholder, {
  AssetProfilePlaceholder,
  LetteredAvatarImageUrl,
} from "../../components/common/userProfilePlaceholder";
import DateConvert from "../../../utilFunctions/DateFormat";
import { useDebounce } from "use-debounce";
import {
  AddIcon,
  ArrowLeft,
  ArrowRight,
  AssetIcon,
  AssetNewIcon,
  AssetsIconBlue,
  AssetsNewIconBlue,
  CloseIcon,
  CreateIcon,
  LinkBlueIcon,
  PlusIcon,
  ProcedurePlusIcon,
  SortIcon,
} from "../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import {
  fetchAllAssetData,
  fetchAssetsInitData,
  fetchAssetsWithFilter,
} from "../../../redux/reducers/assetReducer";
import Animation from "../../components/common/CustomLoader";
import { all } from "redux-saga/effects";

const customStyles = {
  rows: {
    style: {
      height: "72px",
    },
  },
  headCells: {
    style: {
      paddingTop: "11px",
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

/*
 * The `Assets` component handles the display and management of asset data, including filtering, sorting, pagination, and modals for adding, editing, and previewing assets.
 */
const Assets = (props) => {
  const { userPermissions } = usePermissionsSimplified();
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const dispatch = useDispatch();
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [sortData, setSortData] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [grandAsset, setGrandAsset] = useState();
  const [loader, setloader] = useState(false);
  const [assetUploadModal, setAssetUploadModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const customSubStyles = {
    rows: {
      style: {
        // height: '72px',
        background: "#E9F2FF", // override the row height
      },
    },
    headCells: {
      style: {
        paddingTop: "4px", // override the cell padding for head cells
        paddingBottom: "4px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "16px",
        paddingTop: "19px",
        paddingBottom: "19px",
      },
    },
  };
  const customChildSubStyles = {
    rows: {
      style: {
        // height: '72px',
        background: "#E9F2FF80", // override the row height
      },
    },
    headCells: {
      style: {
        paddingTop: "4px", // override the cell padding for head cells
        paddingBottom: "4px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "16px",
        paddingTop: "19px",
        paddingBottom: "19px",
      },
    },
  };
  const t = useSelector(selectTranslations);

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      // location.subLocations.forEach((subLocation) => {
      //   if (!allIds.some((inv) => inv == subLocation?.id)) {
      //     allIds.push(subLocation?.id);
      //   }
      // });
    });

  const [loadUserData, setLoadUserData] = useState(false);
  const GrandChildComponent = ({ data }) => {
    // console.log('someTitleProp: ', someTitleProp);
    // const grandChildAssets = data?.grandChildAsset?.data || [];
    const columns = [
      {
        selector: (row) => row?.attributes?.storedAssets,
        sortable: true,
        width: "47px",
      },
      {
        selector: (row) => row?.attributes?.countId,
        width: selectedLanguage === "en" ? "177px" : "190px",
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className="id-text-style"
          >
            {row?.attributes?.countId}
          </span>
        ),
      },
      {
        width: "268px",
        selector: (row) => row?.name,
        sortable: true,
        cell: (row) => (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {row?.attributes?.image?.data?.attributes?.url ? (
              <>
                <div className="d-flex ">
                  <Image
                    width="34px"
                    avatar
                    height="34px"
                    title={row?.attributes.name}
                    src={
                      IMAGES_URL + row?.attributes?.image?.data?.attributes?.url
                    }
                    onClick={() =>
                      window.open(
                        IMAGES_URL +
                          row?.attributes?.image?.data?.attributes?.url,
                        "_blank"
                      )
                    }
                  />
                  <span className="id-text-style ml-4 mr-4 mt-2">
                    {DisplayFormattedText(
                      row?.grandChildAsset?.data?.attributes.name,
                      20
                    )}
                  </span>
                </div>
              </>
            ) : (
              <>
                <AssetProfilePlaceholder
                  name={`${row?.attributes.name ? row?.attributes.name : ""}`}
                  width={"34px"}
                  height={"34px"}
                />
                <span className="id-text-style ml-2 mr-2">
                  {DisplayFormattedText(row?.attributes.name, 20)}
                </span>
              </>
            )}
          </div>
        ),
      },
      {
        width: "180px",
        // sortField: 'serialNumber',
        // name: t.assets.table.serialNumber,

        sortable: true,
        cell: (row) => row?.attributes?.serialNumber || t.common.na,
      },
      {
        minWidth: "150px",
        // selector: (row) => row?.attributes?.address,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className=""
          >
            {row?.attributes?.location?.data?.attributes?.name
              ? DisplayFormattedText(
                  row?.attributes?.location?.data?.attributes?.name,
                  9
                )
              : t.common.na}
          </span>
        ),
      },

      {
        selector: (row) => row?.attributes?.categories,
        sortable: true,
        minWidth: "153px",
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {DisplayFormattedText(
              row?.attributes?.categories?.data?.attributes?.name,
              10
            ) || t.common.na}
          </span>
        ),
      },

      {
        minWidth: "140px",

        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {DisplayFormattedText(row?.attributes?.model, 8) || t.common.na}
          </span>
        ),
      },

      {
        minWidth: "210px",
        sortField: "placedDate",
        name: t.assets.form.placed_in_service_date,

        sortable: true,
        cell: (row) =>
          row?.attributes?.placedDate ? (
            <div
              onClick={() => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
            >
              <div>
                <Moment
                  format="DD/MM/YYYY"
                  date={row?.attributes?.placedDate}
                />
              </div>
              <div className="time-text-style text-left">
                <Moment format="h a" date={row?.attributes?.placedDate} />
              </div>
            </div>
          ) : (
            <>{t.common.na}</>
          ),
      },
      {
        minWidth: "118px",
        sortField: "status",
        name: t.assets.table.status,

        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`asset pill  ${
              row?.attributes?.status === "active" ||
              row?.attributes?.status === "true"
                ? "active-green"
                : row?.attributes?.status === "inactive" ||
                  row?.attributes?.status === "false"
                ? "inactive-grey"
                : "high-low"
            }`}
          >
            {row?.attributes?.status === "active" ||
            row?.attributes?.status === "true"
              ? t.assets.active
              : row?.attributes?.status === "inactive" ||
                row?.attributes?.status === "false"
              ? t.assets.inActive
              : t.assets.disposed_asset}
          </span>
        ),
      },
    ];

    return (
      <>
        <DataTable
          columns={columns}
          onRowClicked={(row) => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
          className="datatable-div"
          data={data}
          // expandableRows
          // expandableRowsComponent={<ExpandedComponent />}

          direction={selectedLanguage === "en" ? "ltr" : "rtl"}
          // onRowClicked={async (row) => {
          //   const reponse = await getLocationByID(row.id, organisationId);
          //   //console.log('reponse', reponse);
          //   if (reponse.status == 200) {
          //     setTargetedData(reponse.data.data[0]);
          //     setTogglePreviw(true);
          //   }
          // }}
          customStyles={customSubStyles}
          persistTableHead={true}
          pointerOnHover={true}
          highlightOnHover={false}
          noTableHead
        />
      </>
    );
  };

  const ExpandedComponent = memo(({ data }) => {
    const childAssets = data?.attributes?.storedAssets || [];

    // setGrandAsset(data)
    const columns = [
      // {
      //   selector: (row) => row?.attributes?.storedAssets,
      //   sortable: true,
      //   width: '47px',
      // },
      {
        selector: (row) => row?.attributes?.countId,
        width: selectedLanguage === "en" ? "177px" : "192px",
        sortable: true,
        cell: (row) =>
          selectedLanguage === "en" ? (
            <div
              onClick={() => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
              className={`id-text-style`}
            >
              {row?.attributes?.countId}
              {row?.attributes?.grandChildAssets?.data?.length > 0 ? (
                <span
                  style={{ color: "#0C66E4" }}
                  className="ml-2 mr-2 link-span"
                >
                  <LinkBlueIcon />
                  <span className="ml-1">
                    {row.attributes.grandChildAssets.data.length === 1
                      ? ""
                      : row.attributes.grandChildAssets.data.length}
                  </span>
                </span>
              ) : null}
            </div>
          ) : (
            <div
              onClick={() => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
              className={`id-text-style d-flex`}
            >
              {row?.attributes?.countId}
              {row?.attributes?.grandChildAssets?.data?.length > 0 ? (
                <span
                  style={{ color: "#0C66E4" }}
                  className="ml-2 mr-2 link-span"
                >
                  <LinkBlueIcon />
                  <span className="ml-1">
                    {row.attributes.grandChildAssets.data.length === 1
                      ? ""
                      : row.attributes.grandChildAssets.data.length}
                  </span>
                </span>
              ) : null}
            </div>
          ),
      },
      {
        width: "268px",
        selector: (row) => row?.name,
        sortable: true,
        cell: (row) => (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {row?.attributes?.image?.data?.attributes?.url ? (
              <>
                <div className="d-flex ">
                  <Image
                    width="34px"
                    avatar
                    height="34px"
                    title={row?.attributes.name}
                    src={
                      IMAGES_URL + row?.attributes?.image?.data?.attributes?.url
                    }
                    onClick={() =>
                      window.open(
                        IMAGES_URL +
                          row?.attributes?.image?.data?.attributes?.url,
                        "_blank"
                      )
                    }
                  />
                  <span className="id-text-style ml-4 mr-4 mt-2">
                    {DisplayFormattedText(row?.attributes.name, 22)}
                  </span>
                </div>
              </>
            ) : (
              <>
                <AssetProfilePlaceholder
                  name={`${row?.attributes.name ? row?.attributes.name : ""}`}
                  width={"34px"}
                  height={"34px"}
                />
                <span className="id-text-style ml-2 mr-2">
                  {DisplayFormattedText(row?.attributes.name, 22)}
                </span>
              </>
            )}
          </div>
        ),
      },
      {
        width: "180px",
        // sortField: 'serialNumber',
        // name: t.assets.table.serialNumber,

        sortable: true,
        cell: (row) => row?.attributes?.serialNumber || t.common.na,
      },
      {
        minWidth: "150px",
        // selector: (row) => row?.attributes?.address,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className=""
          >
            {row?.attributes?.location?.data?.attributes?.name
              ? DisplayFormattedText(
                  row?.attributes?.location?.data?.attributes?.name,
                  9
                )
              : t.common.na}
          </span>
        ),
      },
      // {
      //   selector: (row) => row?.attributes?.country,
      //   minWidth: '156px',
      //   sortable: true,
      //   cell: (row) =>
      //     row.attributes.country
      //       ? <span onClick={()=>{
      //         setTargetedData(row);
      //         setTogglePreviw(true);
      //       }} >{Country.getCountryByCode(row.attributes.country)?.name}</span>
      //       : t.common.na,
      // },
      // {
      //   selector: (row) => row.attributes?.city,
      //   sortable: true,
      //   minWidth: '156px',
      //   cell: (row) => {
      //     const citiesOfCountry = State.getStatesOfCountry(
      //       row.attributes.country
      //     );
      //     const the_city = row.attributes.city
      //       ? citiesOfCountry.filter((city) => {
      //           return city.isoCode == row.attributes.city;
      //         })[0]?.name
      //       : t.common.na;

      //     const formatted_address = DisplayFormattedText(the_city, 10);

      //     return <span onClick={()=>{
      //       setTargetedData(row);
      //       setTogglePreviw(true);
      //     }}>{formatted_address}</span>;
      //   },
      // },
      {
        selector: (row) => row?.attributes?.categories,
        sortable: true,
        minWidth: "153px",
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {DisplayFormattedText(
              row?.attributes?.categories?.data?.attributes?.name,
              10
            ) || t.common.na}
          </span>
        ),
      },
      // {
      //   selector: (row) =>
      //     row?.attributes?.createdByUser?.data?.attributes
      //       ? row?.attributes?.createdByUser?.data?.attributes?.firstName +
      //         ' ' +
      //         row?.attributes?.createdByUser?.data?.attributes?.lastName
      //       : t.common.na,
      //   sortable: true,
      //   cell: (row) => {
      //     let createdByUser = row?.attributes?.createdByUser?.data ? (
      //       <div>
      //         <Image
      //           avatar
      //           title={
      //             row.attributes.createdByUser.data.attributes.firstName +
      //             ' ' +
      //             row.attributes.createdByUser.data.attributes.lastName
      //           }
      //           src={
      //             row?.attributes?.createdByUser?.data?.attributes?.profileImage
      //               ?.data?.attributes?.url
      //               ? IMAGES_URL +
      //                 row?.attributes?.createdByUser?.data?.attributes
      //                   ?.profileImage?.data?.attributes?.url
      //               : LetteredAvatarImageUrl(
      //                   row?.attributes?.createdByUser?.data?.attributes
      //                     .firstName +
      //                     ' ' +
      //                     row?.attributes?.createdByUser?.data?.attributes
      //                       ?.lastName
      //                 )
      //           }
      //           style={{ width: '34px', height: '34px' }}
      //         />
      //         <span
      //           style={{ marginLeft: '4px', marginRight: '4px' }}
      //           className="id-text-style"
      //         >
      //           {row.attributes.createdByUser.data.attributes.firstName}
      //         </span>
      //       </div>
      //     ) : (
      //       t.common.na
      //     );
      //     return createdByUser;
      //   },
      // },

      {
        minWidth: "140px",

        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {DisplayFormattedText(row?.attributes?.model, 8) || t.common.na}
          </span>
        ),
      },

      {
        minWidth: "210px",
        sortField: "placedDate",
        name: t.assets.form.placed_in_service_date,

        sortable: true,
        cell: (row) =>
          row?.attributes?.placedDate ? (
            <div
              onClick={() => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
            >
              <div>
                <Moment
                  format="DD/MM/YYYY"
                  date={row?.attributes?.placedDate}
                />
              </div>
              <div className="time-text-style text-left">
                <Moment format="h a" date={row?.attributes?.placedDate} />
              </div>
            </div>
          ) : (
            <>{t.common.na}</>
          ),
      },
      {
        minWidth: "118px",
        sortField: "status",
        name: t.assets.table.status,

        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`asset pill ${
              row?.attributes?.status === "active" ||
              row?.attributes?.status === "true"
                ? "active-green"
                : row?.attributes?.status === "inactive" ||
                  row?.attributes?.status === "false"
                ? "inactive-grey"
                : "high-low"
            }`}
          >
            {row?.attributes?.status === "active" ||
            row?.attributes?.status === "true"
              ? t.assets.active
              : row?.attributes?.status === "inactive" ||
                row?.attributes?.status === "false"
              ? t.assets.inActive
              : t.assets.disposed_asset}
          </span>
        ),
      },

      // {
      //   minWidth: '200px',
      //   name: t.locations.form.propertyType,
      //   selector: (row) => row.attributes?.propertyType,
      //   sortable: true,
      //   cell: (row) => {
      //     const locationData = row.attributes?.propertyType;
      //     if (locationData && Array.isArray(locationData)) {
      //       const displayText = locationData
      //         .map((location) => {
      //           const nameParts = location?.property;

      //           return nameParts;
      //         })
      //         .join(', '); // Adjust the join character as needed
      //       return (
      //         <span onClick={()=>{
      //           setTargetedData(row);
      //           setTogglePreviw(true);
      //         }}>
      //           {displayText
      //             ? DisplayFormattedText(displayText, 20)
      //             : t.common.na}
      //         </span>
      //       );
      //     } else {
      //       return <span onClick={()=>{
      //         setTargetedData(row);
      //         setTogglePreviw(true);
      //       }}>{t.common.na} </span>;
      //     }
      //   },
      // },

      // {
      //   selector: (row) => row.attributes?.subLocations?.data,
      //   sortable: true,
      // },
    ];

    return (
      <div>
        {childAssets.map((childAssetWrapper) => {
          const childAsset = childAssetWrapper.childAsset?.data;

          const grandChildAssets =
            childAssetWrapper.grandChildAsset?.data || [];

          return (
            // <div key={childAsset?.id} style={{ marginLeft: '20px', marginTop: '10px' }}>
            <DataTable
              className="datatable-div"
              direction={selectedLanguage === "en" ? "ltr" : "rtl"}
              columns={columns}
              data={[childAsset]}
              expandableRows
              onRowClicked={(row) => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
                // setTargetedData(row);
                // setTogglePreviw(true);
              }}
              // expandableRowsComponent={GrandChildComponent}
              expandableRowsComponent={({ data }) => (
                <GrandChildComponent data={grandChildAssets} />
              )}
              ExpanderComponentProps={{ data: grandChildAssets }}
              expandableRowDisabled={(row) =>
                grandChildAssets?.length > 0 ? false : true
              }
              customStyles={customChildSubStyles}
              persistTableHead={true}
              pointerOnHover={true}
              highlightOnHover={false}
              noTableHead
            />
            // </div>
          );
        })}
      </div>
    );
  });

  /* The function `handleSort` sorts data based on a specified column and sort direction */
  const handleSort = async (column, sortDirection) => {
    setloader(true);

    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchAssetsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        category: filterOptions.categories,
        status: filterOptions.status[0],
        serialNumber: filterOptions.serialNumber,
        placedDate:
          selectedDates.length == 1 ? [selectedDates[0]] : selectedDates,
        sort: sort,
        role,
        allIds,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);

    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchAssetsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        category: filterOptions.categories,
        status: filterOptions.status[0],
        serialNumber: filterOptions.serialNumber,
        placedDate:
          selectedDates.length == 1 ? [selectedDates[0]] : selectedDates,
        sort: sort,
        role,
        allIds,
      })
    );

    setloader(false);
  };

  /**
   * Table Fields
   */
  const columns = [
    {
      name: t.assets.table.ID,
      sortField: "countNumId",
      sortable: true,
      width: selectedLanguage == "en" ? "144px" : "160px",
      cell: (row) =>
        selectedLanguage === "en" ? (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`id-text-style`}
          >
            {row?.attributes?.countId}
            {row?.attributes?.childAssets?.data?.length > 0 ? (
              <span
                style={{ color: "#0C66E4" }}
                className="ml-2 mr-2 link-span"
              >
                <LinkBlueIcon />
                <span className="ml-1">
                  {row.attributes.childAssets.data.length +
                    row?.attributes?.grandChildAssets?.data?.length ===
                  1
                    ? ""
                    : row.attributes.childAssets.data.length +
                      row?.attributes?.grandChildAssets?.data?.length}
                </span>
              </span>
            ) : null}
          </div>
        ) : (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`id-text-style d-flex`}
          >
            {row?.attributes?.countId}
            {row?.attributes?.childAssets?.data?.length > 0 ? (
              <span
                style={{ color: "#0C66E4" }}
                className="ml-2 mr-2 link-span"
              >
                <LinkBlueIcon />
                <span className="ml-1">
                  {row.attributes.childAssets.data.length +
                    row?.attributes?.grandChildAssets?.data?.length ===
                  1
                    ? ""
                    : row.attributes.childAssets.data.length +
                      row?.attributes?.grandChildAssets?.data?.length}
                </span>
              </span>
            ) : null}
          </div>
        ),
    },
    {
      name: t.assets.table.name,
      sortField: "name",
      width: "300px",
      fixed: true,
      sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
        >
          {row.attributes?.image?.data?.attributes?.url ? (
            <>
              <div className="d-flex ">
                <Image
                  width="34px"
                  avatar
                  height="34px"
                  title={row.attributes.name}
                  src={
                    IMAGES_URL + row.attributes?.image?.data?.attributes?.url
                  }
                  onClick={() =>
                    window.open(
                      IMAGES_URL + row.attributes?.image?.data?.attributes?.url,
                      "_blank"
                    )
                  }
                />
                <span className="id-text-style ml-4 mr-4 mt-2">
                  {DisplayFormattedText(row?.attributes?.name, 30)}
                </span>
              </div>
            </>
          ) : (
            <>
              <AssetProfilePlaceholder
                name={`${row?.attributes?.name ? row?.attributes?.name : ""}`}
                width={"34px"}
                height={"34px"}
              />
              <span className="id-text-style ml-2 mr-2">
                {DisplayFormattedText(row?.attributes?.name, 30)}
              </span>
            </>
          )}
        </div>
      ),
    },

    {
      width: "180px",
      sortField: "serialNumber",
      name: t.assets.table.serialNumber,

      sortable: true,
      cell: (row) => row?.attributes?.serialNumber || t.common.na,
    },
    {
      minWidth: "150px",
      name: t.assets.form.location,
      sortField: "location.name",

      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
        >
          {DisplayFormattedText(
            row?.attributes?.location?.data?.attributes?.name,
            9
          ) || t.common.na}
        </span>
      ),
    },
    {
      name: t.assets.form.category,
      sortField: "categories.name",
      minWidth: "153px",

      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
        >
          {DisplayFormattedText(
            row?.attributes?.categories?.data?.attributes?.name,
            10
          ) || t.common.na}
        </span>
      ),
    },
    {
      minWidth: "140px",
      sortField: "model",
      name: t.assets.table.model,

      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
        >
          {DisplayFormattedText(row?.attributes?.model, 8) || t.common.na}
        </span>
      ),
    },

    {
      minWidth: "210px",
      sortField: "placedDate",
      name: t.assets.form.placed_in_service_date,

      sortable: true,
      cell: (row) =>
        row?.attributes?.placedDate ? (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            <div>
              <Moment format="DD/MM/YYYY" date={row?.attributes?.placedDate} />
            </div>
            <div className="time-text-style text-left">
              <Moment format="h a" date={row?.attributes?.placedDate} />
            </div>
          </div>
        ) : (
          <>{t.common.na}</>
        ),
    },
    {
      minWidth: "118px",
      sortField: "status",
      name: t.assets.table.status,

      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row?.attributes?.location?.data?.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
          className={`asset pill ${
            row?.attributes?.status === "active" ||
            row?.attributes?.status === "true"
              ? "active-green"
              : row?.attributes?.status === "inactive" ||
                row?.attributes?.status === "false"
              ? "inactive-grey"
              : "high-low"
          }`}
        >
          {row?.attributes?.status === "active" ||
          row?.attributes?.status === "true"
            ? t.assets.active
            : row?.attributes?.status === "inactive" ||
              row?.attributes?.status === "false"
            ? t.assets.inActive
            : t.assets.disposed_asset}
        </span>
      ),
    },
  ];
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.asset.assetData,
    isLoading: state.asset.isLoading,
    error: state.asset.error,
    initData: state.asset.assetInitData,
    page: state.asset.page,
    pageSize: state.asset.pageSize,
    total: state.asset.total,
    initLoading: state.asset.initLoading,
  }));

  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const assetId = queryParams.get("id");
  const childId = queryParams.get("isChild");
  const history = useHistory();

  const [filterOptions, setFilterOptions] = useState({
    status: [],
    locations: [],
    createdByUser: [],
    customers: [],
    vendors: [],
    categories: [],
    serialNumber: "",
  });

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [fetching, setFetchLoading] = useState(true);

  const [expandedRow, setExpandedRow] = useState();
  const [targetedData, setTargetedData] = useState();
  const [ischild, setIschild] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [activeChecklistReport, setActiveChecklistReport] = useState({
    report: "",
    isOpen: false,
  });

  const [activeInspectionReport, setActiveInspectionReport] = useState({
    report: "",
    isOpen: false,
  });

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  /* Fetching initial data for assets based on the `organisationId`. */
  useEffect(() => {
    const fetchData = async () => {
      setLoadUserData(true);
      if (!(role === "super_admin")) {
        const userData = await getUserData(user.id);

        dispatch(props.fulfillUser(userData));
      }
      const fetchData = dispatch(
        fetchAssetsInitData({ organisationId, role, allIds })
      );
      setLoadUserData(false);

      if (fetchData) {
        if (assetId) {
          let fetchAllData = await dispatch(
            fetchAllAssetData({ organisationId, role, allIds })
          );
          let selectedAsset = fetchAllData.payload.response.find(
            (each) => each?.attributes?.countId == assetId
          );
          if (selectedAsset) {
            console.log("selectedAsset: ", selectedAsset);

            setTargetedData(selectedAsset);
            setTogglePreviw(true);
            if (childId) {
              setActiveIndex(1);
            }
          } else {
            let childAssetData = [];
            let grandChildAssetData = [];

            fetchAllData.payload.response.map((each) => {
              let assetData = each?.attributes?.childAssets?.data?.find(
                (child) => child?.attributes?.countId == assetId
              );

              // Store the assetData in the variable
              if (assetData) {
                childAssetData.push(assetData);
              }
            });
            if (childAssetData?.length !== 0) {
              if (childId) {
                setActiveIndex(1);
              }
              setTargetedData(childAssetData[0]);
              setTogglePreviw(true);
            } else {
              fetchAllData.payload.response.map((each) => {
                let assetGrandData = each?.attributes?.grandChildAssets?.data?.find(
                  (child) => child?.attributes?.countId == assetId
                );

                if (assetGrandData) {
                  grandChildAssetData.push(assetGrandData);
                }

                // Store the assetData in the variable
              });

              if (grandChildAssetData?.length !== 0) {
                setTargetedData(grandChildAssetData[0]);
                setTogglePreviw(true);
              }
            }

            // Now you can use the childAssetData variable for further processing
          }
        }
      }
    };

    fetchData();
  }, [assetId]);

  const handleToggleEdit = (data) => {
    setTargetedData(data);
    setTogglePreviw(false);
    setToggleEditModal(true);
  };

  return (
    <>
      <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <AssetNewIcon />
            {t.assets.title}
          </Header>
          <div></div>

          {userPermissions?.asset?.create && initData.length > 0 ? (
            //   <button
            //   onClick={() => {
            //     setToggleAsideModal(true);
            //   }}
            //   className={`add-item-btn ${
            //     selectedLanguage == "en" ? "mr-7" : "ml-7"
            //   }`}
            // >
            //   <span>
            //     <CreateIcon />
            //     {t.assets.create_asset}
            //   </span>
            // </button>
            <button
              onClick={() => {
                setToggleAsideModal(true);
              }}
              type="button"
              className="add-button asset"
            >
              <span className="add-button-text"> {t.assets.add}</span>
              <span className="add-button-img">
                <PlusIcon />
              </span>
            </button>
          ) : // <Button
          //   icon="add"
          //   primary
          //   className={`add-item-btn ${
          //     selectedLanguage == "en" ? "mr-7" : "ml-7"
          //   }`}
          //   onClick={() => {
          //     setToggleAsideModal(true);
          //   }}
          // >
          //   {t.assets.create_asset}
          //   {/* <ProcedurePlusIcon /> */}
          // </Button>
          null}
        </div>
        {/* {initData?.length > 0 ? ( */}
        <div
          className={
            initData?.length > 0
              ? "new-listing-filter wo-filter sticky-filter mt-8"
              : null
          }
        >
          <Filters
            roleData={role}
            setAssetUploadModal={setAssetUploadModal}
            assetUploadModal={assetUploadModal}
            initData={initData}
            allIds={allIds}
            data={initData}
            fileData={data}
            refresh={refresh}
            setRefresh={setRefresh}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            setSearchTitle={setSearchTitle}
            debounceValue={debounceValue}
            searchTitle={searchTitle}
            setloader={setloader}
            loader={loader}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            sortData={sortData}
            setSortdata={setSortData}
          />
        </div>
        {/* ) : null} */}
      </div>
      <div className="new-listing-container">
        {initLoading || loadUserData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
            {/* <Placeholder fluid>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder> */}
          </div>
        ) : (
          <div>
            <div className="new-listing-filter">
              {initData.length > 0 ? (
                <>
                  {error ? (
                    <div className="mt-8 mb-8 ">
                      <Animation />
                      {/* <Loader
                      active
                      inline="centered"
                      content={t.common.loading}
                    /> */}
                    </div>
                  ) : (
                    <DataTable
                      className="datatable-div"
                      columns={columns}
                      onSort={handleSort}
                      fixedHeader
                      data={data}
                      customStyles={customStyles}
                      expandableRows
                      expandableRowsComponent={ExpandedComponent}
                      expandableRowDisabled={(row) =>
                        row?.attributes?.storedAssets?.length > 0 ? false : true
                      }
                      noDataComponent={
                        initData.length > 0 ? (
                          <div className="no-data-container">
                            <div className="">
                              <p className="no-data-text">
                                {t.common.no_result_found}
                              </p>
                            </div>
                          </div>
                        ) : null
                      }
                      direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                      onRowClicked={(row) => {
                        if (!(role === "super_admin")) {
                          if (
                            allIds?.includes(
                              row?.attributes?.location?.data?.id
                            )
                          ) {
                            setTargetedData(row);
                            setTogglePreviw(true);
                          }
                        } else {
                          setTargetedData(row);
                          setTogglePreviw(true);
                        }
                      }}
                      sortIcon={
                        sortData ? (
                          <div>
                            <span>
                              <SortIcon />
                            </span>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveSorting();
                              }}
                            >
                              <CloseIcon />
                            </span>
                          </div>
                        ) : (
                          <span></span>
                        )
                      }
                      persistTableHead={true}
                      pointerOnHover={true}
                      highlightOnHover={true}
                      progressPending={loader}
                      progressComponent={
                        <div className="mt-8 mb-8 ">
                          {t.common.loading}
                          {/* <Animation/> */}
                          {/* <Loader
                          active
                          inline="centered"
                          content={t.common.loading}
                        /> */}
                        </div>
                      }
                    />
                  )}

                  <div className="d-flex justify-content-end">
                    <Pagination
                      ofString={t.common.of}
                      total={total}
                      prevItem={
                        selectedLanguage === "en" ? (
                          <ArrowLeft />
                        ) : (
                          <ArrowRight />
                        )
                      }
                      nextItem={
                        selectedLanguage === "en" ? (
                          <ArrowRight />
                        ) : (
                          <ArrowLeft />
                        )
                      }
                      page={page}
                      limit={pageSize}
                      handleNext={async () => {
                        setloader(true);
                        await dispatch(
                          fetchAssetsWithFilter({
                            organisationId,
                            searchName: debounceValue,
                            page: page + 1,
                            pageSize: pageSize,
                            location: filterOptions.locations,
                            category: filterOptions.categories,
                            status: filterOptions.status[0],
                            serialNumber: filterOptions.serialNumber,
                            placedDate:
                              selectedDates.length == 1
                                ? [selectedDates[0]]
                                : selectedDates,
                            sort: sortData,
                            role,
                            allIds,
                          })
                        );
                        setloader(false);
                      }}
                      handlePrevious={async () => {
                        setloader(true);
                        await dispatch(
                          fetchAssetsWithFilter({
                            organisationId,
                            searchName: debounceValue,
                            page: page - 1,
                            pageSize: pageSize,
                            location: filterOptions.locations,
                            category: filterOptions.categories,
                            status: filterOptions.status[0],
                            serialNumber: filterOptions.serialNumber,
                            placedDate:
                              selectedDates.length == 1
                                ? [selectedDates[0]]
                                : selectedDates,
                            sort: sortData,
                            role,
                            allIds,
                          })
                        );
                        setloader(false);
                      }}
                    />
                  </div>
                </>
              ) : null}

              {initData.length == 0 ? (
                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <AssetsNewIconBlue />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "66px" : "134px"
                          }
                          arrowAlign="start"
                          fontSize="14px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={selectedLanguage == "ar" ? "95px" : "70px"}
                          moveUp={selectedLanguage == "ar" ? "-2px" : "0px"}
                        >
                          <span className="work-order-tooltip">
                            {t.assets.how_to_add_assets}
                          </span>
                        </Tooltip>

                        {" " + t.assets.title + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.asset?.create ? (
                      <div
                        style={{ gap: "15px" }}
                        className="d-flex justify-content-center"
                      >
                        <button
                          className="work-order-button-transparent"
                          onClick={() => {
                            setToggleAsideModal(true);
                          }}
                        >
                          {t.assets.start_add_asset}
                        </button>

                        <button
                          className="work-order-button-transparent"
                          onClick={() => setAssetUploadModal(true)}
                        >
                          {t.common.bulk_upload}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        {/* Modal component for add asset. */}
        <Add
          toggled={toggleAsideModal}
          role={role}
          allIds={allIds}
          untoggle={() => {
            setToggleAsideModal(false);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          setSearchTitle={setSearchTitle}
          setSelectedDates={setSelectedDates}
        />

        {/* Modal component for edit asset. */}
        <Edit
          role={role}
          setActiveIndex={setActiveIndex}
          allIds={allIds}
          targetedData={targetedData}
          toggled={toggleEditModal}
          untoggle={() => {
            setToggleEditModal(false);
            setTargetedData();
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            history.push("/assets");
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setFilterOptions={setFilterOptions}
          setSearchTitle={setSearchTitle}
          setSelectedDates={setSelectedDates}
        />

        {/* Modal component for view assets. */}
        <Preview
          targetedData={targetedData}
          allIds={allIds}
          role={role}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setModalWidth={setModalWidth}
          modalWidth={modalWidth}
          toggled={togglePreview}
          edit={() => {
            setTogglePreviw(false);

            setToggleEditModal(true);
          }}
          untoggle={() => {
            setTargetedData();
            setActiveIndex(0);
            setTogglePreviw(false);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            history.push("/assets");
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setFilterOptions={setFilterOptions}
          setSearchTitle={setSearchTitle}
          setSelectedDates={setSelectedDates}
        />
      </div>
    </>
  );
};
export default connect(null, auth.actions)(Assets);
