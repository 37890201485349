import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllVendros, getAllWorkRequest } from '../../app/config/functions';
const initialState = {
  WRData: [],
  allWRData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  WRInitData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch initial Work request data
 */
export const fetchWRInitData = createAsyncThunk(
  'wr/initData',
  async ({organisationId,role,userId}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllWorkRequest(organisationId, '', 1, 25,role,[],userId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all Work request data
 */
export const fetchAllWRData = createAsyncThunk(
  'wr/allData',
  async ({organisationId,role,userId}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllWorkRequest(organisationId, '', 1, 99999,role,[],userId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter Work request data
 */
export const fetchWRWithFilter = createAsyncThunk(
  'wr/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      role,
      userId,
      people = [],
      status = [],
      priority = [],
      location = [],
      assets = [],
      submissionDate = [],
      sort = [],
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllWorkRequest(
        organisationId,
        searchName,
        page,
        pageSize,
        role,
        [],
        userId,
        people,
        status,
        priority,
        location,
        assets,
        submissionDate,
        sort,
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const workRequestSlice = createSlice({
  name: 'workRequest',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.WRData = action.payload;
    },
    setInitData: (state, action) => {
      state.WRInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchWRInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchWRInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.WRInitData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchWRInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchWRWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWRWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.WRData = action.payload.response;
        state.allWRData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
        // if (state.WRInitData && state.WRInitData.length <= 0) {
        //   state.WRInitData = action.payload.response;
        // }
      })
      .addCase(fetchWRWithFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllWRData.pending, (state) => {})
      .addCase(fetchAllWRData.fulfilled, (state, action) => {
        state.allWRData = action.payload.response;
      })
      .addCase(fetchAllWRData.rejected, (state, action) => {});
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = workRequestSlice.actions;

export default workRequestSlice.reducer;
