export default {
  vendors: "الموردين",
  vendor: 'مورد',
  add_vendor: 'إضافة مورد',
  title_small: 'موردين',
  how_to_add_vendor: 'كيفية إضافة الموردين',
  no_vendor_added: 'ابدء  في إضافة الموردين',
  title_vendor: 'بائع',
  title_vendor_1: 'مورّد',
  table: {
    id: 'رمز التعريف',
    name: 'اسم المورد',
    phone: 'رقم هاتف',
    email: 'البريد إلكتروني',
    website: 'الموقع إلاكتروني',
    country: 'البلد',
    city: 'المدينة',
    service_type: 'نوع الخدمة',
    hourly_rate: 'الأجر بالساعة',
    created_by: 'انشأ من قبل',
    created_at: 'أنشا في',
    updated_at: 'تاريخ التحديث',
    vendor_info: 'التفاصيل',
  },
  filter: {
    search: 'بحث',
    country: 'البلد',
    search_by_country: 'البحث حسب البلد',
    cities: 'المدن',
    search_by_cities: 'البحث حسب المدن',
    created_at: 'أنشا في',
    updated_at: 'تاريخ التحديث',
    selected: 'محدد',
  },
  created_at_options: {
    any_date: 'أي تاريخ',
    today: 'اليوم',
    this_month: 'هذا الشهر',
    this_year: 'هذا العام',
    last_7_days: '٧ ايام الماضية',
    last_month: 'الشهر الماضي',
    last_year: 'العام الماضي',
  },
  mode: {
    credit_card: 'بطاقة إئتمان',
    cash: 'نقدي',
    bank: 'حوالة بنكية',
    procurement_card: 'بطاقة مشتريات',
    check: 'شيك',
    cliq: 'كليك',
  },
  account: {
    basic: 'الحساب البنكي الأساسي',
    time_deposit: 'حساب الوديعة الزمني',
    time_deposit_price: 'وديعة زمنية مع جوائز',
    current: 'الحساب الجاري',
    pos: 'حساب جاري لنقطة البيع',
    salary: 'حساب الرواتب',
  },
  no_vendor_statement: 'يبدو أنك ليس لديك بائعين حتى الآن',
  press_to_add_vendor: "اضغط على زر '+' لإضافة أول بائع لك",
  form: {
    acc_no: 'رقم الحساب',
    start_date_new:"Start Date/Time",
    due_date_new:"Due Date/Time",
    mode: 'طريقة الدفع',
    bank: 'البنك',
    account: 'نوع الحساب',
    IBAN: 'رقم الحساب المصرفي الدولي',
    disbursement_currency: 'عملة الصرف',
    add_disbursement_currency: 'أضف عملة الصرف',
    IBAN_placeholder: 'JO 0000 0000 0000 0000 0000 ',
    account_no: 'رقم الحساب',
    add_account_no: 'أضف رقم الحساب',
    add_account: 'اختر نوع الحساب',
    add_bank: 'أضف اسم البنك',
    add_mode: 'اختر طريقة الدفع',
    add_pre_days: 'أضف أيام المعالجة المُسبقة',
    pre_days: 'فترة التحضير',
    add_credit_days: 'أضف أيام فترة السماح',
    credit_days: 'فترة السماح',
    start_dt: 'تاريخ/وقت البدء',
    end_dt: 'تاريخ/وقت الانتهاء',
    start_date: 'تاريخ البدء',
    end_date: 'تاريخ الانتهاء',
    add_start_date: 'أضف تاريخ البدء',
    add_end_date: 'أضف تاريخ الانتهاء',
    add_vendor: 'إضافة مورد',
    edit_vendor: 'تعديل مورد',
    name: 'الإسم',
    add_vendor_name: 'أضف اسم المورد',
    description: 'الوصف',
    location: 'الموقع',
    select_location: 'اختر موقعا',
    phone: 'رقم الهاتف',
    website: 'الموقع الإلكتروني',
    service_type: 'نوع الخدمة',
    example: 'مثال: السباكة الكهرباء وغيرها',
    email: 'البريد إلكتروني',
    logo: 'الشعار',
    add: 'إضافة+',
    billing_information: 'معلومات الفواتير',
    payment_info: 'تفاصيل الدفع',
    country: 'الدولة',
    city: 'المدينة',
    postal_code: 'الرمز البريدي',
    address: 'العنوان',
    address_line_2: 'العنوان ٢',
    address_line_3: 'العنوان ٣',
    rate: 'السعر',
    currency: 'العملة',
    submit: 'تقديم الطلب',
    cancel: 'إلغاء',
    vendor_added_success: 'تمت إضافة البائع بنجاح',
    vendor_updated_success: 'تم تحديث المورد بنجاح',
    invalid_email: 'بريد إلكتروني غير صالح',
    invalid_phone: 'رقم الهاتف غير صحيح',
    delete_vendor_statement: 'هل أنت متأكد أنك تريد حذف هذا المورد؟',
    vendor_delete_success: "تم حذف المورد بنجاح",
    vendor_delete_related_error:
    'لا يمكن حذف هذا المورد. مرتبط باشكال اخرى',
  },
};
