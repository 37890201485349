import React from 'react'
import { CsvImg, DocImg, DocxImg, Mp4File, PdfBlueIcon, PngPhotos, TxtFile, XlsxFile } from '../../../Assets/Icons/svg';

const RenderFiles = ({file}) => {
    const fileTypesCompare = {
        images: [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/gif",
          "image/svg+xml",
          "image/webp",
          "image/heic",
          'application/jpg'
        ],
        txt: ["text/plain"],
        csv: ["text/csv"],
        docx: [
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
        xlsx: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
        documents: ["application/msword"],
        pdf: ["application/pdf"],
        video: [
          "video/mp4",
          "video/quicktime",
          "application/octet-stream",
          "video/webm",
          "video/hevc",
        ],
      };
      
      const renderFileIcon = (file) => {
        if (fileTypesCompare?.images?.includes(file)) {
          return <PngPhotos />;
        } else if (fileTypesCompare?.txt?.includes(file)) {
          return <TxtFile />;
        } else if (fileTypesCompare?.csv?.includes(file)) {
          return <CsvImg />;
        } else if (fileTypesCompare?.documents?.includes(file)) {
          return <DocImg />;
        } else if (fileTypesCompare?.video?.includes(file)) {
          return <Mp4File />;
        } else if (fileTypesCompare?.pdf?.includes(file)) {
          return <PdfBlueIcon />;
        } else if (fileTypesCompare?.docx?.includes(file)) {
          return <DocxImg />;
        } else if (fileTypesCompare?.xlsx?.includes(file)) {
          return <XlsxFile />;
        } else {
          return null;
        }
      };
  return (
    <>{renderFileIcon(file)}</>
  )
}

export default RenderFiles