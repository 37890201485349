import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllAssets, getAllCategories } from '../../app/config/functions';
const initialState = {
  assetData: [], // Initial empty array for asset data
  isLoading: false,
  initLoading: false,
  error: null, //
  assetInitData: [], //
  page: 1, //
  pageSize: 25, //
  total: 25, //
};

/*
 * Fetch assets data
 */
export const fetchAssets = createAsyncThunk(
  'asset/list',
  async ({organisationId,role}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllAssets(organisationId, '', 0, 25,[],[],'','',[],[],role);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial assets data
 */
export const fetchAssetsInitData = createAsyncThunk(
  'asset/initData',
  async ({organisationId,role}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllAssets(organisationId, '', 1, 25,[],[],'','',[],[],role);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all assets data
 */
export const fetchAllAssetData = createAsyncThunk(
  'asset/allData',
  async ({organisationId,role}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllAssets(organisationId, '', 1, 99999,[],[],'','',[],[],role);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter assets data
 */
export const fetchAssetsWithFilter = createAsyncThunk(
  'asset/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      location = [],
      category = [],
      status = '',
      serialNumber = '',
      placedDate = [],
      sort = [],
      role='',

    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllAssets(
        organisationId,
        searchName,
        page,
        pageSize,
        location,
        category,
        status,
        serialNumber,
        placedDate,
        sort,
        role,
   
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const categorySlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.assetData = action.payload;
    },
    setInitData: (state, action) => {
      state.assetInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssets.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assetInitData = action.payload.response;
        state.assetData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchAssets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAssetsInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchAssetsInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.assetInitData = action.payload.response;
        state.error = null; // Reset error state on fulfillment
      })
      .addCase(fetchAssetsInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAssetsWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAssetsWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.assetData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;

        state.total = action.payload.pagination.total;
        state.error = null;

        // if (state.assetInitData && state.assetInitData.length <= 0) {
        //   state.assetInitData = action.payload.response;
        // }
      })
      .addCase(fetchAssetsWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllAssetData.pending, (state) => {})
      .addCase(fetchAllAssetData.fulfilled, (state, action) => {})
      .addCase(fetchAllAssetData.rejected, (state, action) => {});
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = categorySlice.actions;

export default categorySlice.reducer;
