/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../../app/config/i18n/slice";
import { Accordion } from "semantic-ui-react";
import { usePermissionsSimplified } from "../../../../../hooks/usePermissionsSimplified";
export function AsideMenuList({ layoutProps }) {
  const { userPermissions } = usePermissionsSimplified();

  const t = useSelector(selectTranslations);
  const [activeIndex, setActiveIndex] = useState(false);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const getAccordianMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"}`
      : "";
  };

  const handleToggle = () => {
    setActiveIndex(!activeIndex);
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul
        style={{ minHeight: "550px", padding: "0px" }}
        className={`menu-nav ${layoutProps.ulClasses}`}
      >
        {/* {userPermissions?.["dashboard"]?.view ? (
          <>
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/dashboard.svg"
                    )}
                  />
                </span>
                <span className="menu-text">{t.aside.dashboard}</span>
              </NavLink>
            </li>
          </>
        ) : null} */}

        <li className="is-spacer"></li> 

        {userPermissions["work-order"]?.view ? (
          <li
            className={`menu-item ${getMenuItemActive("/work-orders", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/work-orders"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                />
              </span>
              <span className="menu-text">{t.aside.workOrders}</span>
            </NavLink>
          </li>
        ) : null}
        {userPermissions["work-request"]?.view ? (
          <li
            className={`menu-item ${getMenuItemActive(
              "/work-requests",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/work-requests"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Files/Work-Request.svg")}
                />
              </span>
              <span className="menu-text">{t.aside.workRequests}</span>
            </NavLink>
          </li>
        ) : null}

        {/* 
        {userPermissions?.timesheet?.view ? (
          <li
            className={`menu-item ${getMenuItemActive('/timesheet', false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/timesheet">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/General/Timesheet.svg')}
                />
              </span>
              <span className="menu-text">{t.timesheet.title}</span>
            </NavLink>
          </li>
        ) : null} */}

        <li className="is-spacer"></li>

        {/* {userPermissions?.["purchase-order"]?.view ? (
          <li
            className={`menu-item ${getMenuItemActive(
              "/purchase-orders",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/purchase-orders"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Calculator.svg"
                  )}
                />
              </span>
              <span className="menu-text">{t.po.title}</span>
            </NavLink>
          </li>
        ) : null} */}
        {userPermissions?.procedure?.view ? (
          <li
            className={`menu-item ${getMenuItemActive("/procedures", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/procedures"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}
                />
              </span>
              <span className="menu-text">{t.aside.procedureLibrary}</span>
            </NavLink>
          </li>
        ) : null}
        {userPermissions?.part?.view ? (
          <li
            className={`menu-item ${getMenuItemActive("/parts", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/parts"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Puzzle.svg")} />
              </span>
              <span className="menu-text">{t.aside.partsInventory}</span>
            </NavLink>
          </li>
        ) : null}

        {userPermissions?.asset?.view ? (
          <li
            className={`menu-item ${getMenuItemActive("/assets", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/assets"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/assetIcon.svg")}
                />
              </span>
              <span className="menu-text">{t.aside.assets}</span>
            </NavLink>
          </li>
        ) : null}

        <li className="is-spacer"></li>

        {userPermissions?.people?.view || userPermissions?.team?.view ? (
          <li
            className={`menu-item ${getMenuItemActive("/people", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to={userRole == 'requester' ? '/teams' : '/people'}
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">{t.aside.peopleTeams}</span>
            </NavLink>
          </li>
        ) : null}

        {userPermissions?.customer?.view ? (
          <li
            className={`menu-item ${getMenuItemActive("/customers", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/customers"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")}
                />
              </span>
              <span className="menu-text">{t.aside.customersVendors}</span>
            </NavLink>
          </li>
        ) : null}

        {userPermissions?.location?.view ? (
          <li
            className={`menu-item ${
              selectedLanguage == "en" ? "" : "rtl-menu-item"
            } ${getMenuItemActive("/locations", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${
                selectedLanguage == "en" ? "" : "rtl-menu-link"
              }`}
              to="/locations"
            >
              <span
                className={`svg-icon menu-icon ${
                  selectedLanguage == "en" ? "" : "rtl-menu-icon"
                }`}
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")} />
              </span>
              <span className="menu-text">{t.aside.locations}</span>
            </NavLink>
          </li>
        ) : null}

        <li className="is-spacer"></li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/time-tracking", false)}`}
          aria-haspopup="true"
        >
          <NavLink className={`menu-link ${selectedLanguage == "en" ? "": "rtl-menu-link"}`} to="/time-tracking">
            <span className={`svg-icon menu-icon ${selectedLanguage == "en" ? "": "rtl-menu-icon"}`}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Timer.svg")} />
            </span>
            <span className="menu-text">{t.aside.timeTracking}</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/payroll", false)}`}
          aria-haspopup="true"
        >
          <NavLink className={`menu-link ${selectedLanguage == "en" ? "": "rtl-menu-link"}`}to="/payroll">
            <span className={`svg-icon menu-icon ${selectedLanguage == "en" ? "": "rtl-menu-icon"}`}>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
              />
            </span>
            <span className="menu-text">{t.aside.payroll}</span>
          </NavLink>
        </li> */}
        {/* <li className="is-spacer"></li> */}
        <div className="menu-setting-2">
          {userPermissions?.category?.view &&
          userPermissions?.jobTitle?.view ? (
            <Accordion
              className={`mt-3 ${
                selectedLanguage == "en" ? "" : "rtl-accordian-title"
              }`}
            >
              <Accordion.Title
                active={activeIndex}
                index={1}
                className={`${selectedLanguage == "en" ? "" : "rtl-accordian"}`}
                onClick={() => handleToggle()}
              >
                <li className={`menu-item`} aria-haspopup="true">
                  <NavLink
                    className={`menu-link menu-item-- ${
                      selectedLanguage == "en" ? "" : "rtl-menu-link"
                    }  accordion-link`}
                    to="/settings"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleToggle();
                    }}
                  >
                    <span className="svg-icon menu-icon configure-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Settings-2.svg"
                        )}
                      />
                    </span>
                    <span
                      className={`menu-text pl-6 pr-6 configure-text ${
                        selectedLanguage == "en" ? "" : "arabic-text"
                      } `}
                    >
                      {t.aside.configurations}
                    </span>
                    <span>
                      {activeIndex ? (
                        <SVG
                          className="arrow-svg"
                          src={toAbsoluteUrl("/media/svg/icons/arrow-up1.svg")}
                        />
                      ) : (
                        <SVG
                          className="arrow-svg"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/arrow-down1.svg"
                          )}
                        />
                      )}
                    </span>
                  </NavLink>
                </li>
              </Accordion.Title>
              <Accordion.Content active={activeIndex} className={``}>
                {/* {userPermissions?.companySettings?.view ? (
              <li
                className={`menu-item ${getAccordianMenuItemActive(
                  '/company-profile',
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/company-profile">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        '/media/svg/icons/company-profile.svg'
                      )}
                    />
                  </span>
                  <span className="menu-text pl-5 pr-5">
                    {t.aside.company_profile}
                  </span>
                </NavLink>
              </li>
            ) : null} */}
                {/* <li
              className={`menu-item ${getAccordianMenuItemActive(
                '/settings',
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/settings">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/language.svg')} />
                </span>
                <span className="menu-text pl-5 pr-5">{t.aside.language}</span>
              </NavLink>
            </li> */}
                {userPermissions?.category?.view ? (
                  <li
                    className={`${
                      selectedLanguage == "en"
                        ? ""
                        : "rtl-menu-item  menu-item-category-link-rtl"
                    } menu-item menu-item-category-link   ${getAccordianMenuItemActive(
                      "/category",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={`menu-link  menu-item-category ${
                        selectedLanguage == "en" ? "" : "rtl-menu-link"
                      } accordion-link`}
                      to="/category"
                    >
                      <span
                        className={`svg-icon menu-icon  ${
                          selectedLanguage == "en" ? "" : "rtl-menu-icon"
                        } configure-icon`}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Other1.svg"
                          )}
                        />
                      </span>
                      <span
                        className={`menu-text pl-6 pr-6 configure-text ${
                          selectedLanguage == "en" ? "" : "arabic-text"
                        } `}
                      >
                        {t.aside.categories}
                      </span>
                    </NavLink>
                  </li>
                ) : null}

                {userPermissions?.jobTitle?.view ? (
                  <li
                    className={`${
                      selectedLanguage == "en"
                        ? ""
                        : "rtl-menu-item  menu-item-category-link-rtl"
                    } menu-item menu-item-category-link   ${getAccordianMenuItemActive(
                      "/job-title",
                      false
                    )}`}
                    // className={` ${
                    //   selectedLanguage == 'en' ? '' : 'rtl-menu-item'
                    // } menu-item menu-item-category-link ${getAccordianMenuItemActive(
                    //   '/job-title',
                    //   false
                    // )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={`menu-link  menu-item-category ${
                        selectedLanguage == "en" ? "" : "rtl-menu-link"
                      } accordion-link`}
                      to="/job-title"
                    >
                      <span
                        className={`svg-icon menu-icon  ${
                          selectedLanguage == "en" ? "" : "rtl-menu-icon"
                        } configure-icon`}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/jobTitle.svg"
                          )}
                        />
                      </span>
                      <span
                        className={`menu-text pl-6 pr-6 configure-text ${
                          selectedLanguage == "en" ? "" : "arabic-text"
                        } `}
                      >
                        {t.jobTitle.title}
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </Accordion.Content>
            </Accordion>
          ) : null}
        </div>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
