import React from "react";
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import {
  DeleteIcon,
  DownloadIconBucket,
  EditPencil,
  OptionIcon,
} from "../../../Assets/Icons/svg";
import Tooltip from "react-tooltip";
import { selectTranslations } from "../../config/i18n/slice";
import { useSelector } from "react-redux";

const DetailsViewOptions = ({
  isEdit = false,
  isDelete = false,
  handleEdit,
  handleDelete,
  generatePDF,
  isPdf = true,
}) => {
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const t = useSelector(selectTranslations);
  return (
    <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 action-options ml-1 align-self-start">
      <UncontrolledDropdown className="mr-2" direction="down">
        <DropdownToggle color="primary" className="procedure-toggle">
          <div
            className="action-tooltip-container"
            data-tip
            data-for={`tooltip-action`}
          >
            <Tooltip id={`tooltip-action`} place="bottom" effect="solid">
              {t.common.action_btn}
            </Tooltip>
            <OptionIcon />
          </div>
        </DropdownToggle>
        <DropdownMenu className={selectedLanguage == "ar" ? "wo rtl" : "wo"}>
          {isEdit ? (
            <DropdownItem className="items" onClick={() => handleEdit()}>
              <EditPencil /> {t.common.edit}
            </DropdownItem>
          ) : null}

          {isDelete ? (
            <DropdownItem className="items" onClick={() => handleDelete()}>
              <DeleteIcon /> {t.common.delete}
            </DropdownItem>
          ) : null}
          {isPdf ? (
            <DropdownItem className="items" onClick={() => generatePDF()}>
              <DownloadIconBucket /> {t.common.download}
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default DetailsViewOptions;
