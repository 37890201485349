import React, { useState, useEffect, createRef } from "react";
import {
  Card,
  Loader,
  Segment,
  Header,
  Dimmer,
  Modal,
  Dropdown,
  Image,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  List,
  Grid,
  Popup,
  Label,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import {
  uploadFile,
  country_codes,
  updateUserRole,
  updateUserStatusToPending,
  createNewUser,
  getAllParentLocations,
  getAllTeams,
  getUsersByRole,
  createNewUserProfile,
  validateEmail,
  IMAGES_URL,
  getActiveUsersByRole,
} from "../../config/functions";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import ReactCountryFlag from "react-country-flag";
import ReactFlagsSelect from "react-flags-select";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import ar from "date-fns/locale/ar-SA";
import Asterisk from "../../components/common/Asterisk";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import JobTitleCreateModal from "../../components/modal/job_title/JobTitleCreateModal";
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  CloseIcon,
  ContractIcon,
  DeleteIcon,
  EditWhiteIcon,
  FlagIcon,
  ImgPhotos,
  Mp4File,
  PassportIcon,
  PngPhotos,
  TextSnippet,
  VisaIcon,
} from "../../../Assets/Icons/svg";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import { format } from "date-fns";
import DatePicker from "react-multi-date-picker";
import { useDropzone } from "react-dropzone";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import ImageCrop from "../MyCompanyProfile/ImageCrop";
const numberRegex = /^[0-9]\d*$/;
const alphanumericRegex = /^[a-zA-Z0-9]+$/;
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const FileUpload = ({
  onCertificateFileChange,
  onFileChange,
  setImageSrc = () => {},
  setFile = () => {},
  setPersonalImgId,
  name = "",
  attachments = {},
  setAttachments = () => {},
  edit = false,
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime","video/webm","video/hevc"],
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "personalPicture"
          ? [...fileTypes.images]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }
      let totalFile = attachments?.covidCopy?.length + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments ? organisationImage?.numberOfAttachments : 10;

      let totalSize = organisationImage?.attachmentsSize ? organisationImage?.attachmentsSize : 200;

      if ( name !== "personalPicture" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
       
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
        ((isVideo || isImage || isDocument) && file.size < totalSize * 1024 * 1024) 
        if (!validSize) {
          toast.error(`${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`);
          return false;
        }
        return true;
      });

      name === "covidCopy"
        ? onCertificateFileChange(validFiles, name)
        : onFileChange(validFiles, name)
    },
 
  });

  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation
    } else {
      return null;
    }
  });

  const renderFile = (file, index) => (
    <div>
      <label>
        {file?.name}
        <span
          className="ml-3 mr-3 c-pointer"
          onClick={(e) => {
            e.stopPropagation();
            const newCovidCopy = attachments.covidCopy.filter(
              (covidFile, i) => i !== index
            );
            setAttachments({
              ...attachments,
              covidCopy: newCovidCopy,
            });
          }}
        >
          <DeleteIcon />
        </span>
      </label>
    </div>
  );
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "personalPicture" ? (
       attachments?.personalPicture?.url ? (
          <div style={{ position: "relative" }}>
            <Image
              avatar
              src={`${IMAGES_URL}${attachments?.personalPicture?.url}`}
              style={{ width: "80px", height: "80px" }}
            ></Image>
            <span
              className={`people-circle-image ${
                selectedLanguage == "en" ? "" : "rtl-people"
              }`}
            >
              <span
                className={`edit-container ${
                  selectedLanguage == "en" ? "" : "rtl-edit"
                }`}
              >
                <EditWhiteIcon /> {t.common.edit}
              </span>
            </span>

            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setFile("");
                setImageSrc("");
                setPersonalImgId(null);
                setAttachments({
                  ...attachments,
                  personalPicture: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </div>
        ) : (
          <div className="image-dropzone  text-center mb-5">
          <div>
            <p className="p1">
              <div className="d-flex align-items-start justify-content-center ">
                <div>
                  {" "}
                  <ImgPhotos />
                </div>
                <div
                  className={`mr-2 ml-2 ${
                    selectedLanguage == "en" ? "text-left" : "text-right"
                  }`}
                >
                  {" "}
                  <div className="" style={{ color: "#0C66E4" }}>
                    {" "}
                    {t.common.upload_img}
                  </div>{" "}
                  <p
                    className="mb-0"
                    style={{ color: "#788CA5", fontSize: "12px" }}
                  >
                    {" "}
                    {t.common.valid_files_img}
                  </p>{" "}
                </div>
              </div>
            </p>
          </div>
        </div>
        )
      ) : name === "contractCopy" ? (
        attachments?.contractCopy?.name ? (
          <div className="warrenty-file-dropzone text-center mt-5">
            <label>
              {attachments?.contractCopy?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    contractCopy: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="warrenty-file-dropzone d-flex align-items-center mt-15 justify-content-between">
            <div className="d-flex ml-4 mr-4">
              <span className="mr-3">
                <TextSnippet />
              </span>
              <div className="pl-4 pr-4">
                <p className="p1 m-0">
                  {" "}
                  {t.people.attachments_form.contract_copy}
                </p>
                <p className="p2 m-0">
                  {t.people.attachments_form.allowed_types}
                </p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        )
      ) : name === "idCopyFront" ? (
        attachments?.idCopyFront?.name ? (
          <div className="warrenty-file-dropzone text-center mt-8">
            <label>
              {attachments?.idCopyFront?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    idCopyFront: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="warrenty-file-dropzone d-flex align-items-center mt-8 justify-content-between">
            <div className="d-flex ml-4 mr-4">
              <span className="mr-3">
                {" "}
                <ContractIcon />
              </span>

              <div className="pl-4 pr-4">
                <p className="p1 m-0">
                  {" "}
                  {t.people.attachments_form.ID_copy_front}
                </p>
                <p className="p2 m-0">
                  {t.people.attachments_form.allowed_types}
                </p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        )
      ) : name === "passportCopy" ? (
        attachments?.passportCopy?.name ? (
          <div className="warrenty-file-dropzone text-center mt-8">
            <label>
              {attachments?.passportCopy?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    passportCopy: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="warrenty-file-dropzone d-flex align-items-center mt-8 justify-content-between">
            <div className="d-flex ml-4 mr-4">
              <span className="mr-3">
                {" "}
                <PassportIcon />
              </span>

              <div className="pl-4 pr-4">
                <p className="p1 m-0">
                  {" "}
                  {t.people.attachments_form.passport_copy}
                </p>
                <p className="p2 m-0">
                  {t.people.attachments_form.allowed_types}
                </p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        )
      ) : name === "visaCopy" ? (
        attachments?.visaCopy?.name ? (
          <div className="warrenty-file-dropzone text-center mt-8">
            <label>
              {attachments?.visaCopy?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    visaCopy: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="warrenty-file-dropzone d-flex align-items-center mt-8 justify-content-between">
            <div className="d-flex ml-4 mr-4">
              <span className="mr-3">
                {" "}
                <VisaIcon />
              </span>

              <div className="pl-4 pr-4">
                <p className="p1 m-0"> {t.people.attachments_form.visa_copy}</p>
                <p className="p2 m-0">
                  {t.people.attachments_form.allowed_types}
                </p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        )
      ) : attachments?.covidCopy.length > 0 ? (
        <div className="image-dropzone text-center mb-5 mt-7">
          {attachments.covidCopy.map((file, index) => renderFile(file, index))}
        </div>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center mt-8 justify-content-between">
          <div className="d-flex ml-4 mr-4">
            <span className="mr-3">
              <TextSnippet />
            </span>

            <div className="pl-4 pr-4">
              <p className="p1 m-0">
                {" "}
                {t.people.attachments_form.certificates}
              </p>
              <p className="p2 m-0">
                {t.people.attachments_form.allowed_types}
              </p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};
const PeopleForm = ({
  setPersonalImgId,
  roleData,
  userProfileLoc,
  data,
  PersonValidationSchema,
  PersonTechnicianValidationSchema,
  PersonLimitedValidationSchema,
  PersonRequesterValidationSchema,
  handleSubmit = () => {},
  fetchJobTitleData = () => {},
  setImageSrc = () => {},
  setFile = () => {},
  activePane,
  imageSrc,
  file,
  changeTab = () => {},
  dropdownOptions,
  fetchCountries = () => {},
  setData = () => {},
  // fetchUsersData = () => {},
  fetchLocations = () => {},
  cancelHandler = () => {},
  toggleCancelModal = () => {},
  setNewCancelModal = () => {},
  onFileChange = () => {},
  setDropdownOptions = () => {},
  onCertificateFileChange = () => {},
  organisationId,
  edit,
  initChange,
  buttonStatus,
  attachments,
  setAttachments,
}) => {
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const fetchTeamsData = async (allIds = [],userProfileLoc) => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData == "super_admin") ?allIds?.length>0 ?  allIds : [] :allIds?.length>0 ? allIds : []
    );

    if (fetchData?.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        teams: the_options,
      });
    }
  };

  const fetchUsersData = async (allIds = [],userProfileLoc) => {
    const fetchData = await getActiveUsersByRole(
      ["admin", "limited_admin", "super_admin"],
      organisationId,
      !(roleData == "super_admin") ? allIds?.length>0?allIds:[] :allIds?.length>0? allIds : [],
      true
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        return {
          key: el.id,
          value: el.id,
          text: el.firstName
            ? `${el.firstName} ${el.lastName ? el.lastName : ""}`
            : el.username,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        users: the_options,
      });
    }
  };

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.type) {
      return state?.auth?.user?.role?.type;
    } else {
      return "";
    }
  });

  const [previousValues, setPreviousValues] = useState(data);

  const { userPermissions } = usePermissionsSimplified();
  const handleDecimalPoints = (value) => {
    const decimalPlaces = 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point

    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return formattedValue;
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const t = useSelector(selectTranslations);
  // console.log(t, 'hii');
  const [phone, setPhone] = useState(false);
  const [emergencyPhone, setEmergencyPhone] = useState(false);
  const contractCopyRef = createRef();
  const personalPictureRef = createRef();
  const passportCopyRef = createRef();
  const idCopyFrontRef = createRef();
  const visaCopyRef = createRef();
  const covidCopyRef = createRef();
  const [openJobTitleAddModal, setOpenJobTitleAddModal] = useState(false);

  // console.log(data);
  return (
    <>
      {buttonStatus == 1 ? (
        <Dimmer active inverted>
          <Loader inverted content={t.common.loading} />
        </Dimmer>
      ) : (
        ""
      )}

      <Formik
        // initialValues={data}
        initialValues={{ ...previousValues, ...data }}
        enableReinitialize
        validationSchema={
          data?.role == 8
            ? PersonTechnicianValidationSchema
            : data?.role == 6
            ? PersonRequesterValidationSchema
            : data?.role == 9
            ? PersonLimitedValidationSchema
            : PersonValidationSchema
        }
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          setFieldTouched,
          handleBlur,
          submitForm,
        }) => (
          <FormikForm>
            <div
              className="body p-6"
              style={{
                height: "90vh",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <Button.Group
                className="add-people-btn mt-5"
                basic
                fluid
                style={{ width: "80%" }}
                size="medium"
              >
                <Button
                  active={activePane == 0}
                  onClick={() => {
                    setFieldValue("tab", 0).then((errors) => {
                      changeTab(0, errors, setFieldTouched, values);
                      if (errors) {
                        scrollToError();
                      }
                    });
                  }}
                  type="button"
                >
                  {t.people.personal_info_form.work_info}
                </Button>

                <Button
                  className="add-people-tab"
                  active={activePane == 1}
                  onClick={() => {
                    setFieldValue("tab", 1).then((errors) => {
                      changeTab(1, errors, setFieldTouched, values);
                      if (errors) {
                        scrollToError();
                      }
                    });
                  }}
                  type="button"
                >
                  {t.people.personal_info_form.personal_info}
                </Button>

                <Button
                  active={activePane == 2}
                  onClick={() => {
                    setFieldValue("tab", 2).then((errors) => {
                      changeTab(2, errors, setFieldTouched, values);
                      if (errors) {
                        scrollToError();
                      }
                    });
                  }}
                  type="button"
                >
                  {t.people.attachments_form.attachment_title}
                </Button>
              </Button.Group>
              <Divider className="mt-0" />
              <div
                style={
                  {
                    // overflowY: 'auto',
                    // overflowX: 'hidden',
                    // height: '100%',
                    // maxHeight: '84%',
                  }
                }
              >
                <div style={{ display: activePane == 1 ? "block" : "none" }}>
                  {/* <Input
                        autoComplete="new-password"
                        label={
                          <Dropdown
                            button
                            basic
                            search
                            defaultValue="962"
                            options={country_codes}
                            onChange={(e, value) => {
                              setFieldValue('countryCode', value.value);
                            }}
                          />
                        }
                        type="text"
                        fluid
                        placeholder="Phone"
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue('phone', e.target.value);
                        }}
                        error={errors.phone && touched.phone}
                        onBlur={(e) => {
                          setFieldTouched('phone', true);
                        }}
                      />
                      {errors.phone && touched.phone ? (
                        <div className="label-error">{errors.phone}</div>
                      ) : (
                        ''
                      )} */}

                  <Form.Field>
                    <label className="label-item mt-7">
                      {t.people.personal_info_form.address}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={t.people.personal_info_form.add_address}
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {" "}
                      {t.people.personal_info_form.gender}
                    </label>
                    <Dropdown
                      placeholder={t.people.personal_info_form.gender}
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      selection
                      options={dropdownOptions.gender}
                      value={values.gender}
                      onChange={(e, value) =>
                        setFieldValue("gender", value.value)
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.martial_status}
                    </label>
                    <Dropdown
                      placeholder={t.people.personal_info_form.martial_status}
                      noResultsMessage={t.common.no_results_found}
                      selection
                      fluid
                      options={dropdownOptions.martialStatus}
                      value={values.martialStatus}
                      onChange={(e, value) =>
                        setFieldValue("martialStatus", value.value)
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {" "}
                      {t.people.personal_info_form.number_of_dependents}
                    </label>

                    <Dropdown
                      placeholder={
                        t.people.personal_info_form.add_number_of_dependents
                      }
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      search
                      clearable
                      selection
                      options={dropdownOptions.childrenNumber}
                      value={values.childrenNo}
                      onChange={(e, value) =>
                        setFieldValue("childrenNo", value.value)
                      }
                      onOpen={() => fetchCountries()}
                    />
                    {/* <Input
                          autoComplete="new-password"
                          type="text"
                          fluid
                          placeholder={
                            t.people.personal_info_form.number_of_dependents
                          }
                          value={values.childrenNo}
                          onChange={
                            (e) => {
                              // console.log(numberRegex.test(e.target.value));
                              if (
                                e.target.value &&
                                numberRegex.test(e.target.value)
                              ) {
                                setFieldValue('childrenNo', e.target.value);
                              } else if (!e.target.value) {
                                setFieldValue('childrenNo', '');
                              }

                              // validationHandler();
                            }

                            // onValuesChange('quantity')
                          }
                        /> */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.education_level}
                    </label>
                    <Dropdown
                      placeholder={t.people.personal_info_form.education_level}
                      fluid
                      selection
                      options={dropdownOptions.educationLevel}
                      value={values.educationLevel}
                      onChange={(e, value) =>
                        setFieldValue("educationLevel", value.value)
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {" "}
                      {t.people.personal_info_form.filed_of_study}
                    </label>
                    <Dropdown
                      placeholder={
                        t.people.personal_info_form.add_filed_of_study
                      }
                      fluid
                      clearable
                      selection
                      options={dropdownOptions.studyFiled}
                      value={values.fieldOfStudy}
                      onChange={(e, value) =>
                        setFieldValue("fieldOfStudy", value.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.nationality}
                    </label>
                    {/* {console.log(dropdownOptions.nationalities)} */}
                    <Dropdown
                      placeholder={t.people.personal_info_form.add_nationality}
                      fluid
                      noResultsMessage={t.common.no_results_found}
                      search
                      selection
                      clearable
                      options={dropdownOptions.nationalities}
                      value={values.nationality}
                      onChange={(e, value) =>
                        setFieldValue("nationality", value.value)
                      }
                      onOpen={() => fetchCountries()}
                    />
                    {/* <ReactFlagsSelect
                        searchable
                        placeholder="Nationality"
                        selected={values.nationality}
                        onSelect={(code) => setFieldValue('nationality', code)}
                      /> */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.country_of_birth}
                    </label>
                    <Dropdown
                      placeholder={
                        t.people.personal_info_form.add_country_of_birth
                      }
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      search
                      clearable
                      selection
                      options={dropdownOptions.nationalities}
                      value={values.cob}
                      onChange={(e, value) => setFieldValue("cob", value.value)}
                      onOpen={() => fetchCountries()}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.date_of_birth}
                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                    </label>

                    <div className="change-date-time-inll position-relative">
                      <DatePicker
                        style={{ width: "100%" }}
                        value={data.dob ? new Date(data.dob) : null}
                        onChange={(value) => {
                          if (value && value.valueOf()) {
                            setFieldValue(
                              "dob",
                              format(value.valueOf(), "yyyy-MM-dd")
                            );
                          } else {
                            setFieldValue("dob", null);
                          }
                        }}
                        containerClassName="date-picker-people"
                        showMonthDropdown
                        showYearDropdown
                        placeholder={
                          t.people.personal_info_form.add_date_of_birth
                        }
                        className={"active"}
                        // locale={lang == 'ar' ? arabic_ar : ''}
                        weekDays={[
                          t.workOrders.details_view.sun,
                          t.workOrders.details_view.mon,
                          t.workOrders.details_view.tue,
                          t.workOrders.details_view.wed,
                          t.workOrders.details_view.thu,
                          t.workOrders.details_view.fri,
                          t.workOrders.details_view.sat,
                        ]}
                        months={[
                          t.common.january,
                          t.common.february,
                          t.common.march,
                          t.common.april,
                          t.common.may,
                          t.common.june,
                          t.common.july,
                          t.common.august,
                          t.common.september,
                          t.common.october,
                          t.common.november,
                          t.common.december,
                        ]}
                        placeholderStyle={{ color: "red" }}
                        format="DD/MM/YYYY" 
                      ></DatePicker>
                      <img
                        className="position-absolute"
                        style={
                          selectedLanguage == "en"
                            ? { top: "8px", right: "7px" }
                            : { top: "8px", left: "7px" }
                        }
                        src={calendar_icon}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item mt-4">
                      {t.people.personal_info_form.passport_no}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      type="text"
                      placeholder={t.people.personal_info_form.add_passport_no}
                      value={values.passportNo}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          alphanumericRegex.test(e.target.value)
                        ) {
                          setFieldValue("passportNo", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("passportNo", "");
                        }
                      }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.identification_no}
                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      type="text"
                      placeholder={
                        t.people.personal_info_form.add_identification_no
                      }
                      value={values.identificationNo}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          alphanumericRegex.test(e.target.value)
                        ) {
                          setFieldValue("identificationNo", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("identificationNo", "");
                        }
                      }}
                      // error={
                      //   validation.identificationNo.error &&
                      //   validation.identificationNo.skipped
                      // }
                      // onBlur={(e) => {
                      //   if (
                      //     validation.identificationNo.pattern(
                      //       data.identificationNo
                      //     )
                      //   ) {
                      //     setValidation({
                      //       ...validation,
                      //       identificationNo: {
                      //         ...validation.identificationNo,
                      //         error: false,
                      //         skipped: true,
                      //       },
                      //     });
                      //   } else {
                      //     setValidation({
                      //       ...validation,
                      //       identificationNo: {
                      //         ...validation.identificationNo,
                      //         error: true,
                      //         skipped: true,
                      //       },
                      //     });
                      //   }
                      // }}
                    />
                    {/* {validation.identificationNo.error &&
                    validation.identificationNo.skipped ? (
                      <div className="label-error">
                        {validation.identificationNo.message}
                      </div>
                    ) : (
                      ''
                    )} */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.visa_no}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      type="text"
                      placeholder={t.people.personal_info_form.add_visa_no}
                      value={values.visaNo}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          alphanumericRegex.test(e.target.value)
                        ) {
                          setFieldValue("visaNo", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("visaNo", "");
                        }
                      }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {" "}
                      {t.people.personal_info_form.visa_expire_date}
                    </label>
                    <div className="change-date-time-inll position-relative">
                      <DatePicker
                        style={{ width: "100%" }}
                        containerClassName="date-picker-people"
                        value={
                          data.visaExpireDate
                            ? new Date(data.visaExpireDate)
                            : null
                        }
                        onChange={(value) => {
                          if (value && value.valueOf()) {
                            setFieldValue(
                              "visaExpireDate",
                              format(value.valueOf(), "yyyy-MM-dd")
                            );
                          } else {
                            setFieldValue("visaExpireDate", null);
                          }
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholder={
                          t.people.personal_info_form.add_visa_expire_date
                        }
                        className={"active"}
                        // locale={lang == 'ar' ? arabic_ar : ''}
                        weekDays={[
                          t.workOrders.details_view.sun,
                          t.workOrders.details_view.mon,
                          t.workOrders.details_view.tue,
                          t.workOrders.details_view.wed,
                          t.workOrders.details_view.thu,
                          t.workOrders.details_view.fri,
                          t.workOrders.details_view.sat,
                        ]}
                        months={[
                          t.common.january,
                          t.common.february,
                          t.common.march,
                          t.common.april,
                          t.common.may,
                          t.common.june,
                          t.common.july,
                          t.common.august,
                          t.common.september,
                          t.common.october,
                          t.common.november,
                          t.common.december,
                        ]}
                        format="DD/MM/YYYY" 
                      />
                      <img
                        className="position-absolute"
                        style={
                          selectedLanguage == "en"
                            ? { top: "8px", right: "7px" }
                            : { top: "8px", left: "7px" }
                        }
                        src={calendar_icon}
                      />
                    </div>
                    {/* <Input
                        autoComplete="new-password"
                        type="date"
                        fluid
                        placeholder="Visa Expire Date"
                        value={values.visaExpireDate}
                        onChange={(e) =>
                          setFieldValue('visaExpireDate', e.target.value)
                        }
                      /> */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item mt-4">
                      {t.people.personal_info_form.emergency_contact}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={
                        t.people.personal_info_form.add_emergency_contact
                      }
                      value={values.emergencyName}
                      onChange={(e) =>
                        setFieldValue("emergencyName", e.target.value)
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.emergency_phone}
                    </label>

                    <PhoneInput
                      defaultCountry="jo"
                      // forceDialCode={true}
                      value={values.emergencyPhone || ""}
                      placeholder={t.people.personal_info_form.add_phone}
                      onChange={(value) => {
                        // console.log('values', value);

                        if (!emergencyPhone) {
                          setEmergencyPhone(true);
                          return;
                        }
                        setFieldValue("emergencyPhone", value);
                      }}
                      onBlur={(e) => setFieldTouched("emergencyPhone", true)}
                      // onBlur={(e) => setFieldTouched('emergencyPhone', false)}
                      error={errors.emergencyPhone && touched.emergencyPhone}
                    />
                    {errors.emergencyPhone && touched.emergencyPhone ? (
                      <div className="label-error">{errors.emergencyPhone}</div>
                    ) : (
                      ""
                    )}
                    {/* <Input
                        autoComplete="new-password"
                        label={
                          <Dropdown
                            button
                            basic
                            search
                            defaultValue="962"
                            options={country_codes}
                            onChange={(e, value) =>
                              setFieldValue('emergencyCountryCode', value.value)
                            }
                          />
                        }
                        type="number"
                        fluid
                        placeholder="Emergency Phone"
                        value={values.emergencyPhone}
                        onChange={(e) =>
                          setFieldValue('emergencyPhone', e.target.value)
                        }
                      /> */}
                  </Form.Field>

                  {/* <Form.Field>
                        <label className="label-item">
                          {t.people.personal_info_form.filed_of_study}
                        </label>
                        <Input
                          autoComplete="new-password"
                          fluid
                          placeholder={
                            t.people.personal_info_form.filed_of_study
                          }
                          value={values.fieldOfStudy}
                          onChange={(e) =>
                            setFieldValue('fieldOfStudy', e.target.value)
                          }
                        />
                      </Form.Field> */}
                </div>
                <div style={{ display: activePane == 0 ? "block" : "none" }}>
                
                    <FileUpload
                      onFileChange={onFileChange}
                      name="personalPicture"
                      attachments={attachments}
                      setAttachments={setAttachments}
                      edit={edit}
                      setPersonalImgId={setPersonalImgId}
                    />

{imageSrc && (
                  <ImageCrop
                    // ratio={1 / 1}
                    src={imageSrc}
                    minWidth={20}
                    minHeight={20}
                    fileData={file}
                    onAccept={async(croppedImageUrl) => {
                      //console.log('Cropped', croppedImageUrl);
                      let CroppedFile = new File([croppedImageUrl], file.name, {
                        type: file.type,
                      });
                      //console.log(CroppedFile);
                      var randomnumber =
                        Math.floor(Math.random() * (0 - 100 + 1)) + 100;

                        const personalPictureResponse = await uploadFile([CroppedFile]);

                        if (personalPictureResponse?.status == 200) {
                          let fileData = personalPictureResponse?.data[0]?.url;
                          setPersonalImgId(personalPictureResponse.data[0].id);
                          setImageSrc('');
                          setFile("");
                          setAttachments({
                            ...attachments,
                            ["personalPicture"]: {
                              file: CroppedFile,
                              id: randomnumber,
                              name: CroppedFile.name,
                              type: CroppedFile.type,
                              cancelPopup: false,
                              isChanged: true,
                              url: fileData ? fileData : null,
                            },
                          });
                        }else{
                          toast.error(t.common.something_wrong)
                        }
                   
                      // setAttachments({
                      //   ...attachments,
                      //   profileImage: {
                      //     file: CroppedFile,
                      //     id: randomnumber,
                      //     name: CroppedFile.name,
                      //     type: CroppedFile.type,
                      //     cancelPopup: false,
                      //     isChanged: true,
                      //   },
                      // });
                      // Resizer.imageFileResizer(
                      //   croppedImageUrl,
                      //   300,
                      //   300,
                      //   'PNG',
                      //   100,
                      //   0,
                      //   async (uri) => {
                      //     let data = {
                      //       fileName:
                      //         file.name.replace(/[\W_]+/g, '_') +
                      //         '.' +
                      //         file.type.split('/')[1],
                      //       source: uri,
                      //       folder: 'job',
                      //     };
                      //     api(`file`, data, 'post', true)
                      //       .then((data) => {
                      //         setFieldValue('image', data.data);
                      //       })
                      //       .catch((err) => {
                      //         Toast.error(
                      //           t(`apiResponses.${err.error}`) ||
                      //             t('generalValidation.somethingWrongTry')
                      //         );
                      //       });
                      //   },
                      //   'base64',
                      //   300,
                      //   300
                      // );
                      // };
                      // reader.readAsDataURL(croppedImageUrl);
                    }}
                    onCancel={() => {
                      setImageSrc("");
                      setFile("");
                    }}
                  />
                )}
                

                  <Form.Field>
                    <label className="label-item">
                      {" "}
                      {t.people.filter.role}
                    </label>
                    <Dropdown
                      placeholder={t.people.filter.select_role}
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      selection
                      disabled={
                        edit === true &&
                        ((userRole !== "limited_admin" &&
                          userRole !== "super_admin" &&
                          userRole !== "admin") ||
                          // ((values.role == 5 || values.role === 9) &&
                          //   userRole == 'limited_admin')
                          // ||
                          values.role == 4)
                      }
                      // disabled={edit == true && (userRole !== "limited_admin" || userRole !== "super_admin" ||userRole !== "admin")}
                      options={
                        edit === true && values.role == 4
                          ? [
                              {
                                key: 4,
                                text: t.people.add_modal.super_admin,
                                value: 4,
                              },
                            ]
                          : edit === true && userRole === "limited_admin"
                          ? dropdownOptions.limitedRoles
                          : dropdownOptions.userRole
                      }
                      value={values.role}
                      onChange={(e, value) => {
                        setFieldValue("role", value.value);
                        // setPreviousValues(values)
                        setData({
                          ...values,
                          role: value.value, // Ensure the role value is correctly updated in data
                        });
                      }}
                      onBlur={(e) => {
                        setFieldTouched("role", true);
                      }}
                      error={errors.role && touched.role}
                    />
                    {errors.role && touched.role ? (
                      <div className="label-error">{errors.role}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item mt-1">
                      {t.people.personal_info_form.firstname}
                      <Asterisk color="#172B4D" />
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={t.people.personal_info_form.add_firstname}
                      value={values.firstName}
                      onChange={(e) => {
                        let name = e.target.value;
                        setFieldValue("firstName", name);
                      }}
                      error={errors.firstName && touched.firstName}
                      onBlur={(e) => {
                        setFieldTouched("firstName", true);
                      }}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="label-error">{errors.firstName}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.lastname}
                      <Asterisk color="#172B4D" />
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={t.people.personal_info_form.add_lastname}
                      value={values.lastName}
                      onChange={(e) =>
                        setFieldValue("lastName", e.target.value)
                      }
                      error={errors.lastName && touched.lastName}
                      onBlur={(e) => {
                        setFieldTouched("lastName", true);
                      }}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="label-error">{errors.lastName}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.phone}
                      <Asterisk color="#172B4D" />
                    </label>

                    <PhoneInput
                      defaultCountry="jo"
                      value={values.phone || ""}
                      disabled={data.role == 4}
                      placeholder={t.people.personal_info_form.add_phone}
                      onChange={(value) => {
                        // if (!phone) {
                        //   setPhone(true);
                        //   return;
                        // }
                        setFieldValue("phone", value);
                      }}
                      onBlur={(e) => setFieldTouched("phone", true)}
                      error={errors.phone && touched.phone}
                    />
                    {errors.phone && touched.phone ? (
                      <div className="label-error">{errors.phone}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="label-item">
                      {t.people.personal_info_form.email}
                      {values.role == 8 || values.role == 6 ? null : (
                        <Asterisk color="#172B4D" />
                      )}
                    </label>
                    <Input
                      autoComplete="new-password"
                      type="email"
                      fluid
                      disabled={data.role == 4}
                      className="email-password-ltr"
                      placeholder={t.people.personal_info_form.add_email}
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      error={errors.email && touched.email}
                      onBlur={(e) => {
                        setFieldTouched("email", true);
                      }}
                    />
                    {errors.email && touched.email ? (
                      <div className="label-error">{errors.email}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.job_title}
                      {/* {values?.role == 6 ? null : <Asterisk color="#172B4D" />} */}
                    </label>
                    <div className="d-flex">
                      <Dropdown
                        placeholder={t.people.personal_info_form.add_jobtitle}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        search
                        clearable
                        selection
                        options={dropdownOptions.jobTitle}
                        value={values.jobTitle}
                        onChange={(e, value) =>
                          setFieldValue("jobTitle", value.value)
                        }
                        onOpen={() => fetchJobTitleData()}
                      />

                      {userPermissions?.jobTitle?.create &&
                      userPermissions?.jobTitle?.view ? (
                        <span
                          className={`mr-2 p-1 d-flex align-items-center justify-content-center c-pointer ${
                            selectedLanguage == "en" ? "ml-5" : "mr-5"
                          }`}
                          onClick={() => {
                            setOpenJobTitleAddModal(true);
                          }}
                          style={{ backgroundColor: "#E9F2FF" }}
                        >
                          <span className="">
                            {" "}
                            <AddIcon />
                          </span>
                        </span>
                      ) : null}
                    </div>
                  </Form.Field>

                  {data.role == 8 || data.role == 9 ? (
                    <Form.Field>
                      <label className="label-item">
                        {" "}
                        {t.people.table.supervisor}
                        {values?.role == 6 ? null : (
                          <Asterisk color="#172B4D" />
                        )}
                      </label>
                      <Dropdown
                        placeholder={t.people.work_info_form.add_supervisor}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        search
                        clearable
                        selection
                        options={dropdownOptions.users}
                        value={values.supervisor}
                        onChange={(e, value) =>
                          setFieldValue("supervisor", value.value)
                        }
                        // disabled={
                        //   values?.locations.length == 0 &&
                        //   !(userRole == "super_admin" || userRole == "admin")
                        // }
                        onOpen={() => fetchUsersData(values?.selectedLocation,userProfileLoc)}
                        error={errors.supervisor && touched.supervisor}
                        onBlur={(e) => {
                          setFieldTouched("supervisor", true);
                        }}
                      />

                      {errors.supervisor && touched.supervisor ? (
                        <div className="label-error">{errors.supervisor}</div>
                      ) : (
                        ""
                      )}
                    </Form.Field>
                  ) : null}

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.locations}
                      <Asterisk color="#172B4D" />
                    </label>
                    <Dropdown
                      placeholder={t.people.personal_info_form.add_location}
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      search
                      selection
                      multiple
                      className="people-dropdown"
                      options={dropdownOptions?.locations?.options}
                      value={values.locations}
                      onChange={(e, value) => {

                        const selectedOptions = dropdownOptions?.locations?.initOptions?.filter((option) =>
                          value?.value?.includes(option.value)
                        );
                      
                        // Clone the options to avoid direct mutation
                        let newSelectedValues = [...value?.value];
                        let allSelectedValues = [...value?.value];
                        let newInitOptions = [...dropdownOptions?.locations?.initOptions]; // This keeps the original locations intact
                      
                        selectedOptions.forEach((option) => {
                          if (!option.disabled) {
                            // If the selected option is a parent, find its sub-locations
                            const subLocations = dropdownOptions?.locations?.initOptions?.filter(
                              (subOption) => subOption.parentId === option.key
                            );
                
                            allSelectedValues = [
                              ...allSelectedValues,
                              ...subLocations.map((sub) => sub.value),
                            ];
                
                            if (subLocations.length > 0) {
                              // If it's a parent location, remove any previously added sub-location IDs
                              // Remove sub-locations from the selected values
                              newSelectedValues = newSelectedValues.filter(
                                (value) => !subLocations.some((sub) => sub.value === value)
                              );
                      
                              // Add the parent location value to newSelectedValues
                              // newSelectedValues.push(option.value);
                            }
                      
                            // Add sub-locations to new selected values
                            // newSelectedValues = [
                            //   ...newSelectedValues,
                            //   option.value,  // Add only the parent location
                            // ];
                      
                            // Remove sub-locations from initOptions if the parent is selected
                            newInitOptions = newInitOptions.filter(
                              (initOption) => initOption.parentId !== option.key
                            );
                          }
                        });
                
                        // Update the selected locations
                        // setCustomerData({
                        //   ...customerData,
                        //   [name]: [...new Set(newSelectedValues)], 
                        //   selectedLocation: [...new Set(allSelectedValues)], // Unique selected locations
                        // });

                        setFieldValue("locations", [
                          ...new Set(newSelectedValues),
                        ]);

                        setFieldValue("selectedLocation", [
                          ...new Set(allSelectedValues),
                        ]);
                      
                        // Update the options with the filtered sub-locations
                        setDropdownOptions((prev) => ({
                          ...prev,
                          locations: {
                            ...prev.locations,
                            options: newInitOptions, // Only keep parent locations or non-selected sub-locations
                          },
                        }));
                   
                        // Find selected options in the dropdown
                        // const selectedOptions = dropdownOptions.locations.filter(
                        //   (option) => value?.value.includes(option.value)
                        // );

                        // // Create a new list of selected values
                        // let newSelectedValues = [...value?.value];

                        // selectedOptions.forEach((option) => {
                        //   // If the selected option is a parent, find its sub-locations
                        //   if (!option.disabled) {
                        //     const subLocations = dropdownOptions.locations.filter(
                        //       (subOption) => subOption.parentId === option.key
                        //     );

                        //     // Add sub-locations to new selected values
                        //     newSelectedValues = [
                        //       ...newSelectedValues,
                        //       ...subLocations.map((sub) => sub.value),
                        //     ];
                        //   }
                        // });

                        // Update the form values with the new selected values
                        // setFieldValue("locations", [
                        //   ...new Set(newSelectedValues),
                        // ]);
                        setFieldValue("teams", "");
                        setFieldValue("supervisor", "");
                      }}
                      onOpen={() => fetchLocations()}
                      error={errors.locations && touched.locations}
                      onBlur={(e) => {
                        setFieldTouched("locations", true);
                      }}
                    />
                    {errors.locations && touched.locations ? (
                      <div
                        className="label-error"
                        style={{ marginTop: "-15px" }}
                      >
                        {errors.locations}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.teams}
                    </label>
                    <Dropdown
                      placeholder={t.people.personal_info_form.add_teams}
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      search
                      selection
                      multiple
                      // disabled={
                      //   values?.locations.length == 0 &&
                      //   !(userRole == "super_admin" || userRole == "admin")
                      // }
                      options={dropdownOptions.teams}
                      value={values.teams}
                      onChange={(e, value) =>
                        setFieldValue("teams", value.value)
                      }
                      onOpen={() => fetchTeamsData(values?.selectedLocation,userProfileLoc)}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.hour_rate}
                      {/* {values?.role == 6 ? null : <Asterisk color="#172B4D" />} */}
                    </label>
                    <Input
                      autoComplete="new-password"
                      type="number"
                      fluid
                      placeholder={t.people.personal_info_form.add_hr_rate}
                      value={values.hourRate}
                      onChange={(e) => {
                        let valueData = Number(e.target.value);
                        if (e.target.value) {
                          let hrRate = Number(valueData.toFixed(2));
                          setFieldValue("hourRate", String(hrRate));
                        } else if (!e.target.value) {
                          setFieldValue("hourRate", "");
                        } else {
                          setFieldValue("hourRate", "");
                        }
                      }}
                    >
                      <input />

                      <span
                        className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-5" : "mr-5"
                        }`}
                        // onClick={() => {
                        //   setOpenJobTitleAddModal(true);
                        // }}
                        style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                      >
                        {organisationCurrency}
                      </span>
                      {/* <Label style={{ height: '100%' }}>
                        {organisationCurrency}
                      </Label> */}
                    </Input>
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.department}
                      {/* {values?.role == 6 ? null : <Asterisk color="#172B4D" />} */}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={t.people.personal_info_form.add_department}
                      value={values.department}
                      onChange={(e) =>
                        setFieldValue("department", e.target.value)
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.working_hours_week}

                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                    </label>
                    <Input
                      autoComplete="new-password"
                      type="text"
                      fluid
                      placeholder={
                        t.people.work_info_form.add_working_hours_week
                      }
                      value={values.whpw}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          numberRegex.test(e.target.value)
                        ) {
                          setFieldValue("whpw", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("whpw", "");
                        }
                      }}

                      // error={validation.whpw.error && validation.whpw.skipped}
                      // onBlur={(e) => {
                      //   if (validation.whpw.pattern(data.whpw)) {
                      //     setValidation({
                      //       ...validation,
                      //       whpw: {
                      //         ...validation.whpw,
                      //         error: false,
                      //         skipped: true,
                      //       },
                      //     });
                      //   } else {
                      //     setValidation({
                      //       ...validation,
                      //       whpw: {
                      //         ...validation.whpw,
                      //         error: true,
                      //         skipped: true,
                      //       },
                      //     });
                      //   }
                      // }}
                    >
                      <input />
                      <span
                        className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-5" : "mr-5"
                        }`}
                        // onClick={() => {
                        //   setOpenJobTitleAddModal(true);
                        // }}
                        style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                      >
                        {t.common.hrs}
                      </span>
                    </Input>
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.working_hours_day}

                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                    </label>
                    <Input
                      autoComplete="new-password"
                      type="text"
                      fluid
                      placeholder={
                        t.people.work_info_form.add_working_hours_day
                      }
                      value={values.whpd}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          numberRegex.test(e.target.value)
                        ) {
                          setFieldValue("whpd", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("whpd", "");
                        }
                      }}

                      // error={validation.whpd.error && validation.whpd.skipped}
                      // onBlur={(e) => {
                      //   if (validation.whpd.pattern(data.whpd)) {
                      //     setValidation({
                      //       ...validation,
                      //       whpd: {
                      //         ...validation.whpd,
                      //         error: false,
                      //         skipped: true,
                      //       },
                      //     });
                      //   } else {
                      //     setValidation({
                      //       ...validation,
                      //       whpd: {
                      //         ...validation.whpd,
                      //         error: true,
                      //         skipped: true,
                      //       },
                      //     });
                      //   }
                      // }}
                    >
                      {" "}
                      <input />
                      <span
                        className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-5" : "mr-5"
                        }`}
                        // onClick={() => {
                        //   setOpenJobTitleAddModal(true);
                        // }}
                        style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                      >
                        {t.common.hrs}
                      </span>
                    </Input>

                    {/* {validation.whpd.error && validation.whpd.skipped ? (
                      <div className="label-error">
                        {validation.whpd.message}
                      </div>
                    ) : (
                      ''
                    )} */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.contract_start_date}

                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                    </label>

                    <div className="change-date-time-inll position-relative">
                      <DatePicker
                        style={
                          !data.contractStart
                            ? { width: "100%", color: "#44546f" }
                            : { width: "100%" }
                        }
                        value={
                          data.contractStart
                            ? new Date(data.contractStart)
                            : null
                        }
                        onChange={(value) => {
                          if (value && value.valueOf()) {
                            setFieldValue(
                              "contractStart",
                              format(value.valueOf(), "yyyy-MM-dd")
                            );
                          } else {
                            setFieldValue("contractStart", "");
                          }
                        }}
                        containerClassName="date-picker-people"
                        showMonthDropdown
                        showYearDropdown
                        placeholder={
                          t.people.work_info_form.add_contract_start_date
                        }
                        // locale={lang == 'ar' ? arabic_ar : ''}
                        weekDays={[
                          t.workOrders.details_view.sun,
                          t.workOrders.details_view.mon,
                          t.workOrders.details_view.tue,
                          t.workOrders.details_view.wed,
                          t.workOrders.details_view.thu,
                          t.workOrders.details_view.fri,
                          t.workOrders.details_view.sat,
                        ]}
                        months={[
                          t.common.january,
                          t.common.february,
                          t.common.march,
                          t.common.april,
                          t.common.may,
                          t.common.june,
                          t.common.july,
                          t.common.august,
                          t.common.september,
                          t.common.october,
                          t.common.november,
                          t.common.december,
                        ]}
                        format="DD/MM/YYYY" 
                      ></DatePicker>
                      <img
                        className="position-absolute"
                        style={
                          selectedLanguage == "en"
                            ? { top: "8px", right: "7px" }
                            : { top: "8px", left: "7px" }
                        }
                        src={calendar_icon}
                      />
                    </div>
                    {/* <Input
                        autoComplete="new-password"
                        type="date"
                        fluid
                        placeholder="Contract Start Date"
                        value={values.contractStart}
                        onChange={(e) =>
                          setFieldValue('contractStart', e.target.value)
                        }
                        // error={
                        //   validation.contractStart.error &&
                        //   validation.contractStart.skipped
                        // }
                        // onBlur={(e) => {
                        //   if (
                        //     validation.contractStart.pattern(data.contractStart)
                        //   ) {
                        //     setValidation({
                        //       ...validation,
                        //       contractStart: {
                        //         ...validation.contractStart,
                        //         error: false,
                        //         skipped: true,
                        //       },
                        //     });
                        //   } else {
                        //     setValidation({
                        //       ...validation,
                        //       contractStart: {
                        //         ...validation.contractStart,
                        //         error: true,
                        //         skipped: true,
                        //       },
                        //     });
                        //   }
                        // }}
                      /> */}
                    {/* {validation.contractStart.error &&
                    validation.contractStart.skipped ? (
                      <div className="label-error">
                        {validation.contractStart.message}
                      </div>
                    ) : (
                      ''
                    )} */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.contract_expiry_date}

                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                    </label>

                    <div className="change-date-time-inll position-relative">
                      <DatePicker
                        style={
                          !data.contractEnd
                            ? { width: "100%", color: "#44546f" }
                            : { width: "100%" }
                        }
                        containerClassName="date-picker-people"
                        value={
                          data.contractEnd ? new Date(data.contractEnd) : null
                        }
                        onChange={(value) => {
                          if (value && value.valueOf()) {
                            setFieldValue(
                              "contractEnd",
                              format(value.valueOf(), "yyyy-MM-dd")
                            );
                          } else {
                            setFieldValue("contractEnd", "");
                          }
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholder={
                          t.people.work_info_form.add_contract_expiry_date
                        }
                        className={"active"}
                        // locale={lang == 'ar' ? arabic_ar : ''}
                        weekDays={[
                          t.workOrders.details_view.sun,
                          t.workOrders.details_view.mon,
                          t.workOrders.details_view.tue,
                          t.workOrders.details_view.wed,
                          t.workOrders.details_view.thu,
                          t.workOrders.details_view.fri,
                          t.workOrders.details_view.sat,
                        ]}
                        months={[
                          t.common.january,
                          t.common.february,
                          t.common.march,
                          t.common.april,
                          t.common.may,
                          t.common.june,
                          t.common.july,
                          t.common.august,
                          t.common.september,
                          t.common.october,
                          t.common.november,
                          t.common.december,
                        ]}
                        format="DD/MM/YYYY" 
                      ></DatePicker>

                      <img
                        className="position-absolute"
                        style={
                          selectedLanguage == "en"
                            ? { top: "8px", right: "7px" }
                            : { top: "8px", left: "7px" }
                        }
                        src={calendar_icon}
                      />
                      {errors.contractEnd && touched.contractEnd ? (
                        <div
                          style={{ marginTop: "-20px" }}
                          className="label-error"
                        >
                          {errors.contractEnd}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <DatePicker
                      selected={
                        values.contractEnd ? new Date(values.contractEnd) : ''
                      }
                      locale={selectedLanguage}
                      showIcon={true}
                      icon="fa fa-calendar"
                      maxDate={new Date()}
                      placeholder={t.people.work_info_form.contract_expiry_date}
                      className="date-input-picker"
                      onChange={(date) => setFieldValue('contractEnd', date)}
                      dateFormat="yyyy-MM-dd"
                    /> */}
                    {/* <Input
                        autoComplete="new-password"
                        type="date"
                        fluid
                        placeholder="Contract Expiration Date"
                        value={values.contractEnd}
                        onChange={(e) =>
                          setFieldValue('contractEnd', e.target.value)
                        }
                        // error={
                        //   validation.contractEnd.error &&
                        //   validation.contractEnd.skipped
                        // }
                        // onBlur={(e) => {
                        //   if (validation.contractEnd.pattern(data.contractEnd)) {
                        //     setValidation({
                        //       ...validation,
                        //       contractEnd: {
                        //         ...validation.contractEnd,
                        //         error: false,
                        //         skipped: true,
                        //       },
                        //     });
                        //   } else {
                        //     setValidation({
                        //       ...validation,
                        //       contractEnd: {
                        //         ...validation.contractEnd,
                        //         error: true,
                        //         skipped: true,
                        //       },
                        //     });
                        //   }
                        // }}
                      /> */}
                    {/* {validation.contractEnd.error &&
                    validation.contractEnd.skipped ? (
                      <div className="label-error">
                        {validation.contractEnd.message}
                      </div>
                    ) : (
                      ''
                    )} */}
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.badge_ID}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={t.people.work_info_form.add_badge_ID}
                      value={values.badgeId}
                      onChange={(e) => setFieldValue("badgeId", e.target.value)}
                      onBlur={(e) => {
                        setFieldTouched("badgeId", true);
                      }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {" "}
                      {t.people.work_info_form.registration_no}
                    </label>
                    <Input
                      autoComplete="new-password"
                      type="text"
                      fluid
                      placeholder={t.people.work_info_form.add_registration_no}
                      value={values.registrationNo}
                      onBlur={(e) => {
                        setFieldTouched("registrationNo", true);
                      }}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          numberRegex.test(e.target.value)
                        ) {
                          setFieldValue("registrationNo", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("registrationNo", "");
                        }
                      }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className="label-item">
                      {t.people.work_info_form.work_permit_no}
                    </label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      type="text"
                      placeholder={t.people.work_info_form.add_work_permit_no}
                      onBlur={(e) => {
                        setFieldTouched("workPermitNo", true);
                      }}
                      value={values.workPermitNo}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          alphanumericRegex.test(e.target.value)
                        ) {
                          setFieldValue("workPermitNo", e.target.value);
                        } else if (!e.target.value) {
                          setFieldValue("workPermitNo", "");
                        }
                      }}
                    />
                  </Form.Field>

                  <Form.Field error={errors.days && touched.days}>
                    <label className="label-item">
                      {t.people.work_info_form.working_days}
                      {values?.role == 6 ? null : <Asterisk color="#172B4D" />}
                    </label>
                    <Button.Group basic>
                      <Button
                        onClick={() => {
                          // setData({
                          //   ...data,
                          //   days: {
                          //     ...data.days,
                          //     sat: !data.days.sat,
                          //   },
                          // });
                          setFieldValue("days.sat", !values.days.sat);
                        }}
                        className={
                          values.days.sat ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.sat}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("days.sun", !values.days.sun);
                        }}
                        className={
                          values.days.sun ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.sun}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("days.mon", !values.days.mon);
                        }}
                        className={
                          values.days.mon ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.mon}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("days.tue", !values.days.tue);
                        }}
                        className={
                          values.days.tue ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.tue}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("days.wed", !values.days.wed);
                        }}
                        className={
                          values.days.wed ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.wed}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("days.thu", !values.days.thu);
                        }}
                        className={
                          values.days.thu ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.thu}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("days.fri", !values.days.fri);
                        }}
                        className={
                          values.days.fri ? "day-btn active" : "day-btn"
                        }
                        type="button"
                      >
                        {t.people.work_info_form.working_day_options.fri}
                      </Button>
                    </Button.Group>

                    {errors.days && touched.days ? (
                      <div className="label-error">{errors.days}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>

                  <Form.Field
                    style={{ marginTop: "-10px" }}
                    error={errors.shifts && touched.shifts}
                  >
                    <label className="label-item">
                      {t.people.work_info_form.shifts}
                      {values?.role == 6 ? null : <Asterisk color="#172B4D" />}
                    </label>
                    <Button.Group basic>
                      <Button
                        onClick={() => {
                          // setData({
                          //   ...data,
                          //   shifts: {
                          //     ...data.shifts,
                          //     shiftA: !data.shifts.shiftA,
                          //   },
                          // });
                          setFieldValue("shifts.shiftA", !values.shifts.shiftA);
                        }}
                        className={
                          values.shifts.shiftA
                            ? "shift-btn active"
                            : "shift-btn"
                        }
                        type="button"
                      >
                        <span className="mr-3">
                          {" "}
                          <FlagIcon />
                        </span>
                        {t.people.work_info_form.shift_A}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("shifts.shiftB", !values.shifts.shiftB);
                        }}
                        className={
                          values.shifts.shiftB
                            ? "shift-btn active"
                            : "shift-btn"
                        }
                        type="button"
                      >
                        <span className="mr-3">
                          {" "}
                          <FlagIcon />
                        </span>

                        {t.people.work_info_form.shift_B}
                      </Button>

                      <Button
                        onClick={() => {
                          setFieldValue("shifts.shiftC", !values.shifts.shiftC);
                        }}
                        className={
                          values.shifts.shiftC
                            ? "shift-btn active"
                            : "shift-btn"
                        }
                        type="button"
                      >
                        <span className="mr-3">
                          {" "}
                          <FlagIcon />
                        </span>
                        {t.people.work_info_form.shift_C}
                      </Button>
                    </Button.Group>

                    {errors.shifts && touched.shifts ? (
                      <div className="label-error">{errors.shifts}</div>
                    ) : (
                      ""
                    )}
                  </Form.Field>
                </div>

                <div style={{ display: activePane == 2 ? "block" : "none" }}>
                  <Form.Field>
                    <FileUpload
                      onFileChange={onFileChange}
                      name="contractCopy"
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </Form.Field>
                  <Form.Field className="">
                    <FileUpload
                      onFileChange={onFileChange}
                      name="idCopyFront"
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </Form.Field>
                  <Form.Field className="">
                    <FileUpload
                      onFileChange={onFileChange}
                      name="passportCopy"
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </Form.Field>

                  <Form.Field>
                    <FileUpload
                      onFileChange={onFileChange}
                      name="visaCopy"
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </Form.Field>

                  <Form.Field>
                    <FileUpload
                      onCertificateFileChange={onCertificateFileChange}
                      name="covidCopy"
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </Form.Field>
                </div>

                <div
                  className={`form-button-container ${
                    activePane == 2 ? "" : "mt-8"
                  }`}
                >
                  <button
                    content={t.common.cancel}
                    className="form-action-button"
                    basic
                    type="button"
                    onClick={() =>
                      initChange ? toggleCancelModal(true) : cancelHandler()
                    }
                  >
                    {t.assets.form.cancel}
                  </button>
                  {activePane == 2 ? (
                    <button
                      content={t.people.attachments_form.submit_btn}
                      primary
                      type="button"
                      onClick={() => {
                        submitForm();
                      }}
                      loading={buttonStatus == 1}
                      className="form-action-button blue"
                    >
                      {t.common.submit}
                    </button>
                  ) : (
                    <button
                      className="form-action-button blue"
                      type="button"
                      primary
                      onClick={() => {
                        setFieldValue("tab", activePane + 1).then((errors) => {
                          changeTab(
                            activePane + 1,
                            errors,
                            setFieldTouched,
                            values
                          );
                          if (errors) {
                            scrollToError();
                          }
                        });
                      }}
                      loading={buttonStatus == 1}
                    >
                      {t.common.next}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <JobTitleCreateModal
              isOpen={openJobTitleAddModal}
              setModalOpenState={setOpenJobTitleAddModal}
              handleSuccess={async (res) => {
                await fetchJobTitleData();

                setFieldValue("jobTitle", res?.data?.data?.id);
              }}
            />
          </FormikForm>
        )}
      </Formik>
    </>
  );
};
export default PeopleForm;
