import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllParentLocations,
  getAllTeams,
  getUsersByRole,
} from '../../app/config/functions';
const initialState = {
  peopleData: [],
  allPeopleData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  peopleInitData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch people data
 */
export const fetchPeople = createAsyncThunk(
  'people/list',
  async ({ role = [], organisationId,roleData }, { dispatch, rejectWithValue }) => {
    try {
      const data = await getUsersByRole(role, organisationId, '', 1, 25,[],[],[],[],[],{},[],[],roleData,[]);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial people data
 */
export const fetchPeopleInitData = createAsyncThunk(
  'people/initData',
  async ({ role = [], organisationId,roleData}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getUsersByRole(role, organisationId, '', 1, 25,[],[],[],[],[],{},[],[],roleData,[]);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all people data
 */
export const fetchAllPeopleData = createAsyncThunk(
  'people/allData',
  async ({ role, organisationId,roleData }, { dispatch, rejectWithValue }) => {
    try {
      const data = await getUsersByRole(role, organisationId, '', 1, 9999,[],[],[],[],[],{},[],[],roleData,[]);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter people data
 */
export const fetchPeopleWithFilter = createAsyncThunk(
  'people/filter',
  async (
    {
      role,
      organisationId,
      searchName,
      page,
      pageSize,
      role_type = [],
      supervisor = [],
      team = [],
      status = '',
      job = [],
      rate = {},
      sort = [],
      roleData='',
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getUsersByRole(
        role,
        organisationId,
        searchName,
        page,
        pageSize,
        role_type,
        supervisor,
        team,
        status,
        job,
        rate,
        sort,
        [],
        roleData,[]
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const peopleSlice = createSlice({
  name: 'poeple',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.teamsData = action.payload;
    },
    setInitData: (state, action) => {
      state.teamsInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeople.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPeople.fulfilled, (state, action) => {
        state.isLoading = false;
        state.peopleInitData = action.payload.response;
        state.peopleData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchPeople.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchPeopleInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchPeopleInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.peopleInitData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchPeopleInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchPeopleWithFilter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPeopleWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.peopleData = action.payload.response;
        state.allPeopleData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchPeopleWithFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllPeopleData.pending, (state) => {})
      .addCase(fetchAllPeopleData.fulfilled, (state, action) => {
        state.allPeopleData = action.payload.response;
      })
      .addCase(fetchAllPeopleData.rejected, (state, action) => {});
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = peopleSlice.actions;

export default peopleSlice.reducer;
