import React, { useEffect, useState, createRef } from "react";
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Loader,
  Label,
} from "semantic-ui-react";

import DataTable from "react-data-table-component";

import md5 from "md5";

import Moment from "react-moment";

import { NavLink } from "react-router-dom";

import { useLocation, useHistory } from "react-router";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";

import {
  currencyDecimalPlaces,
  getAllParts,
  getUserData,
  humanize,
  IMAGES_URL,
} from "../../config/functions";

import Add from "./add";
import Edit from "./edit";

import Preview from "./preview/index";

import Filters from "./filters";

import { Country, State, City } from "country-state-city";

import { useDispatch, useSelector, connect } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import UserProfilePlaceholder, {
  AssetProfilePlaceholder,
  LetteredAvatarImageUrl,
} from "../../components/common/userProfilePlaceholder";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";
import moment from "moment";
import DateConvert from "../../../utilFunctions/DateFormat";
import * as auth from "../../modules/Auth/_redux/authRedux";
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CreateIcon,
  PartIcon,
  PartsBlueIcon,
  PeopleIcon,
  PlusIcon,
  ProcedurePlusIcon,
  PurchaseBlueIcon,
  SortIcon,
} from "../../../Assets/Icons/svg";
import {
  fetchAllPartsData,
  fetchPartsInitData,
  fetchPartsWithFilter,
} from "../../../redux/reducers/partReducer";
import Pagination from "../../components/common/CommonPagination";
import ToolTip from "react-power-tooltip";
import { useDebounce } from "use-debounce";
import {
  fetchAllPOData,
  fetchPOInitData,
  fetchPOWithFilter,
} from "../../../redux/reducers/purchaseOrderReducer";
import Animation from "../../components/common/CustomLoader";

const customStyles = {
  // rows: {
  //   style: {
  //     height: '72px',
  //     // override the row height
  //   },
  // },
  headCells: {
    style: {
      paddingTop: "11px", // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

/*
 * The `PurchaseOrder` function in JavaScript is a component that handles the display and management of
 * purchase orders, including filtering, sorting, pagination, and modals for adding, editing, and previewing purchase orders.
 */
const PurchaseOrder = (props) => {
  const { userPermissions } = usePermissionsSimplified();
  const dispatch = useDispatch();

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      // location.subLocations.forEach((subLocation) => {
      //   if (!allIds.some((inv) => inv == subLocation?.id)) {
      //     allIds.push(subLocation?.id);
      //   }
      // });
    });

  /*
   * The handleDecimalPoints function formats a numerical value to a specified number of decimal places and removes trailing zeros after the decimal point.
   */
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return formattedValue;
  };

  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const [loadUserData, setLoadUserData] = useState(false);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [filterOptions, setFilterOptions] = useState({
    vendors: [],
    totalCost: {
      from: null,
      to: null,
    },
    status: [],
  });

  const [selectedDates, setSelectedDates] = useState([]);
  const [dueDates, setDueDates] = useState([]);

  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const partId = queryParams.get("id");
  const history = useHistory();

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [sortData, setSortData] = useState();
  const [fetching, setFetchLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [expandedRow, setExpandedRow] = useState();
  const [targetedData, setTargetedData] = useState();
  const [loader, setloader] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [activeChecklistReport, setActiveChecklistReport] = useState({
    report: "",
    isOpen: false,
  });

  const statusOptions = [
    {
      value: "requested",
      color: "yellow",
      label: t.po.status.requested,
    },
    {
      value: "approved",
      color: "green",
      label: t.po.status.approved,
    },
    {
      value: "declined",
      color: "red",
      label: t.po.status.declined,
    },
    {
      value: "fulfilled",
      color: "black",
      label: t.po.status.fulfilled,
    },
    {
      value: "partiallyfulfilled",
      color: "blue",
      label: t.po.status.partially_fulfilled,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.po.status.cancelled,
    },
  ];
  const [activeInspectionReport, setActiveInspectionReport] = useState({
    report: "",
    isOpen: false,
  });

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  /*
   * The handleSort function sets sorting parameters, fetches data with filters and sorting, and updates the loader state accordingly.
   */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchPOWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        vendors: filterOptions.vendors,
        status: filterOptions.status,
        totalCost: filterOptions.totalCost,
        submissionDate: selectedDates,
        dueDate: dueDates,
        sort: sort,
        role,
        allIds,
      })
    );

    setloader(false);
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchPOWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        vendors: filterOptions.vendors,
        status: filterOptions.status,
        totalCost: filterOptions.totalCost,
        submissionDate: selectedDates,
        dueDate: dueDates,
        sort: sort,
        role,
        allIds,
      })
    );

    setloader(false);
  };

  const partTypeOptions = [
    {
      key: 0,
      text: t.common.all,
      value: "",
      color: "",
    },
    {
      key: 1,
      text: t.parts.type.critical,
      value: "critical",
    },
    {
      key: 2,
      text: t.parts.type.non_critical,
      value: "non-critical",
    },
  ];

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.purchaseOrder.POData,
    isLoading: state.purchaseOrder.isLoading,
    error: state.purchaseOrder.error,
    initData: state.purchaseOrder.POInitData,
    page: state.purchaseOrder.page,
    pageSize: state.purchaseOrder.pageSize,
    total: state.purchaseOrder.total,
    initLoading: state.purchaseOrder.initLoading,
  }));

  /* The above code is setting the data by fetching it from a source using the `fetchData`
  function. */
  useEffect(() => {
    const fetchData = async () => {
      setLoadUserData(true);
      if (!(role === "super_admin")) {
        const userData = await getUserData(user.id);

        dispatch(props.fulfillUser(userData));
      }
      const fetchData = await dispatch(
        fetchPOInitData({ organisationId, role, allIds })
      );
      setLoadUserData(false);
      if (fetchData) {
        // setData(fetchData);
        // setInitData(fetchData);
        setFetchLoading(false);
        if (partId) {
          if (partId == "true") {
            setToggleAsideModal(true);
          } else {
            const fetchAllData = await dispatch(
              fetchAllPOData({ organisationId, role, allIds })
            );

            let selectedPart = fetchAllData.payload.response.find(
              (each) => each.attributes?.countId == partId
            );
            if (selectedPart) {
              setTargetedData(selectedPart);
              setTogglePreviw(true);
            }
          }
        }
      }
    };

    fetchData();
  }, [partId]);

  const [searchTitle, setSearchTitle] = useState("");
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [debounceRate] = useDebounce(filterOptions.totalCost, 500);

  /* The above code is defining an array of column configurations for a table. Each object in the
`columns` array represents a column in the table with properties such as `name`, `sortField`,
`sortable`, `minWidth`, and `cell` function to render the content of the cell. */
  const columns = [
    {
      width: selectedLanguage === "en" ? "113px" : "107px",
      name: t.assets.table.ID,
      sortField: "countNumId",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.countId}
        </span>
      ),
    },
    {
      name: t.workOrders.table.title,
      sortField: "details.title",
      sortable: true,
      minWidth: "165px",

      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.details?.title
            ? DisplayFormattedText(
                humanize(row?.attributes?.details?.title),
                14
              )
            : t.common.na}
        </span>
      ),
    },

    {
      name: t.po.table.po_number,
      sortField: "details.poNumber",
      sortable: true,
      minWidth: selectedLanguage == "en" ? "144px" : "170px",

      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className=""
        >
          {row?.attributes?.details?.poNumber
            ? row?.attributes?.details?.poNumber
            : t.common.na}
        </span>
      ),
    },

    {
      minWidth: "170px",
      sortField: "status",
      name: t.workOrders.table.status,
      // selector: (row) => row?.attributes?.status,
      sortable: true,
      cell: (row) => {
        const workOrderStatus = statusOptions.find(
          (status) => status.value === row.attributes.status
        );

        return (
          <div
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="d-flex align-items-center"
          >
            <Label
              circular
              empty
              color={workOrderStatus?.color || "#788ca5"}
              style={{ width: "12px", height: "12px" }}
            />
            <div>
              <span className="ml-2 mr-2">
                {workOrderStatus?.label || t.common.na}
              </span>
            </div>
          </div>
        );
      },
    },

    {
      minWidth: selectedLanguage === "en" ? "134px" : "144px",
      sortField: "details.vendor.name",
      name: t.po.table.vendor,
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.details?.vendor?.data?.attributes?.name
            ? DisplayFormattedText(
                humanize(
                  row?.attributes?.details?.vendor?.data?.attributes?.name
                ),
                12
              )
            : t.common.na}
        </span>
      ),
    },

    {
      name: t.po.table.no_items,
      sortField: "totalItems",
      sortable: true,
      minWidth: "143px",

      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className=""
        >
          {row?.attributes?.totalItems ? row?.attributes?.totalItems : "0"}
        </span>
      ),
    },

    {
      name: t.po.table.total_qty,
      sortField: "totalQty",
      sortable: true,
      minWidth: selectedLanguage == "en" ? "140px" : "160px",

      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className=""
        >
          {row?.attributes?.totalQty ? row?.attributes?.totalQty : "0"}
        </span>
      ),
    },
    {
      name: t.po.table.total_cost,
      sortField: "orderItems.totalCost",
      sortable: true,
      minWidth: selectedLanguage == "en" ? "140px" : "162px",

      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className=""
        >
          {row?.attributes?.orderItems?.totalCost
            ? handleDecimalPoints(row?.attributes?.orderItems?.totalCost)
            : "0"}{" "}
          {organisationCurrency}
        </span>
      ),
    },
    {
      name: t.po.table.due_date,
      sortField: "details.dueDate",
      sortable: true,
      minWidth: selectedLanguage === "en" ? "144px" : "171px",
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment
              format="DD/MM/YYYY"
              date={row?.attributes?.details?.dueDate}
            />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.details?.dueDate} />
          </div>
        </div>
      ),
    },

    {
      name: t.parts.table.created_on,
      sortField: "createdAt",
      sortable: true,
      minWidth: "144px",
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD/MM/YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },

    {
      name: t.procedures.table.createdByUser,
      sortField: "createdByUser",
      sortable: true,
      minWidth: selectedLanguage === "en" ? "144px" : "164px",
      cell: (row) => {
        let createdByUser = row?.attributes?.createdByUser?.data ? (
          <div>
            <Image
              avatar
              title={
                row.attributes.createdByUser?.data?.attributes?.firstName +
                " " +
                row.attributes.createdByUser?.data?.attributes?.lastName
              }
              src={
                row?.attributes?.createdByUser?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? row?.attributes?.createdByUser?.data?.attributes
                      ?.profileImage?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.createdByUser?.data?.attributes
                        .firstName +
                        " " +
                        row?.attributes?.createdByUser?.data?.attributes
                          ?.lastName
                    )
              }
              style={
                selectedLanguage == "en"
                  ? { width: "34px", height: "34px", right: "5px" }
                  : { width: "34px", height: "34px", left: "5px" }
              }
            />
            {/* <span className="id-text-style">
              {row.attributes.submittedBy.data.attributes.firstName}
            </span> */}
          </div>
        ) : (
          t.common.na
        );
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="ml-1 mr-1"
          >
            {createdByUser}
          </span>
        );
      },
      // cell: (row) => (
      //   <span
      //     onClick={() => {
      //       setTargetedData(row);
      //       setTogglePreviw(true);
      //     }}
      //     className=""
      //   >
      //     {row.attributes.createdByUser.data.attributes.firstName +
      //       " " +
      //       row.attributes.createdByUser.data.attributes.lastName}
      //   </span>
      // ),
    },

    {
      name: t.po.table.approved_by,
      sortField: "approvedByUser",
      sortable: true,
      minWidth: selectedLanguage === "en" ? "148px" : "204px",
      cell: (row) => {
        let createdByUser = row?.attributes?.approvedByUser?.data ? (
          <div>
            <Image
              avatar
              title={
                row.attributes.approvedByUser?.data?.attributes?.firstName +
                " " +
                row.attributes.approvedByUser?.data?.attributes?.lastName
              }
              src={
                row?.attributes?.approvedByUser?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? row?.attributes?.approvedByUser?.data?.attributes
                      ?.profileImage?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.approvedByUser?.data?.attributes
                        .firstName +
                        " " +
                        row?.attributes?.approvedByUser?.data?.attributes
                          ?.lastName
                    )
              }
              style={
                selectedLanguage == "en"
                  ? { width: "34px", height: "34px", right: "5px" }
                  : { width: "34px", height: "34px", left: "5px" }
              }
            />
            {/* <span className="id-text-style">
              {row.attributes.submittedBy.data.attributes.firstName}
            </span> */}
          </div>
        ) : (
          t.common.na
        );
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="ml-1 mr-1"
          >
            {createdByUser}
          </span>
        );
      },
      // cell: (row) => (
      //   <span
      //     onClick={() => {
      //       setTargetedData(row);
      //       setTogglePreviw(true);
      //     }}
      //   >
      //     {row?.attributes?.approvedByUser?.data?.attributes?.firstName ||
      //     row?.attributes?.approvedByUser?.data?.attributes?.lastName
      //       ? row?.attributes?.approvedByUser?.data?.attributes?.firstName +
      //         " " +
      //         row?.attributes?.approvedByUser?.data?.attributes?.lastName
      //       : t.common.na}
      //   </span>
      // ),
    },
  ];

  const handleToggleEdit = (location) => {
    setTargetedData(location);
    setTogglePreviw(false);
    setToggleEditModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };
  /*
   * The function `getPaginatedData` takes an array of data and returns a paginated subset based on the current page and page size.
   */
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };
  const totalFilteredPages = Math.ceil(data.length / pageSize);
  return (
    <>
      <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <PartIcon />
            {t.po.title}
          </Header>
          <div></div>

          {userPermissions?.["purchase-order"]?.create &&
          initData?.length > 0 ? (
            //   <button
            //   onClick={() => {
            //     setToggleAsideModal(true);
            //   }}
            //   className={`add-item-btn ${
            //     selectedLanguage == "en" ? "mr-7" : "ml-7"
            //   }`}
            // >
            //   <span>
            //     <CreateIcon />
            //     {t.po.add}
            //   </span>
            // </button>

            <button
              onClick={() => {
                setToggleAsideModal(true);
              }}
              type="button"
              className="add-button po"
            >
              <span className="add-button-text"> {t.po.title_single}</span>
              <span className="add-button-img">
                <PlusIcon />
              </span>
            </button>
          ) : // <Button
          //   icon="add"
          //   primary
          //   className={`add-item-btn ${
          //     selectedLanguage == "en" ? "mr-7" : "ml-7"
          //   }`}
          //   onClick={() => {
          //     setToggleAsideModal(true);
          //   }}
          // >
          //   {t.po.add}
          //   {/* <ProcedurePlusIcon /> */}
          // </Button>
          null}
        </div>
        {initData?.length > 0 ? (
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
            <Filters
              data={initData}
              role={role}
              allIds={allIds}
              fileData={data}
              setSortData={setSortData}
              setloader={setloader}
              loader={loader}
              debounceValue={debounceValue}
              setSearchTitle={setSearchTitle}
              searchTitle={searchTitle}
              sortData={sortData}
              setDataByFilter={handleSetDataByFilter}
              hasStatus={false}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              setSelectedDates={setSelectedDates}
              selectedDates={selectedDates}
              setDueDates={setDueDates}
              dueDates={dueDates}
              debounceRate={debounceRate}
            />
          </div>
        ) : null}
      </div>
      <div className="new-listing-container">
        {/* <Header as="h2">{t.parts.title}</Header>
      <div
        className="header-actions"
        style={{ flex: 1, justifyContent: 'flex-end' }}
      >
        {userPermissions?.part?.create ? (
          <Button
            icon="add"
            content={t.parts.add}
            primary
            onClick={() => {
              setToggleAsideModal(true);
            }}
          />
        ) : null}
      </div> */}
        {initLoading || loadUserData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
            {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
          </div>
        ) : (
          // <div>
          //   <Placeholder fluid>
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //   </Placeholder>
          // </div>
          <div className="new-listing-filter">
            {initData?.length > 0 ? (
              <>
                {error ? (
                  <div className="mt-8 mb-8 ">
                    <Animation />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-div"
                    columns={columns}
                    data={getPaginatedData(data)}
                    onSort={handleSort}
                    fixedHeader
                    customStyles={customStyles}
                    noDataComponent={
                      initData.length > 0 ? (
                        <div className="no-data-container">
                          <div className="">
                            <p className="no-data-text">
                              {t.common.no_result_found}
                            </p>
                          </div>
                        </div>
                      ) : null
                    }
                    direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                    onRowClicked={(row) => {
                      setTargetedData(row);
                      setTogglePreviw(true);
                    }}
                    sortIcon={
                      sortData ? (
                        <div>
                          <span>
                            <SortIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSorting();
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )
                    }
                    persistTableHead={true}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    progressPending={loader}
                    progressComponent={
                      <div className="mt-8 mb-8 ">{t.common.loading}</div>
                    }
                  />
                )}
                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchPOWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page + 1,
                          pageSize: pageSize,
                          vendors: filterOptions.vendors,
                          status: filterOptions.status,
                          totalCost: filterOptions.totalCost,
                          submissionDate: selectedDates,
                          dueDate: dueDates,

                          sort: sortData,
                          role,
                          allIds,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchPOWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page - 1,
                          pageSize: pageSize,
                          vendors: filterOptions.vendors,
                          status: filterOptions.status,
                          totalCost: filterOptions.totalCost,
                          submissionDate: selectedDates,
                          dueDate: dueDates,

                          sort: sortData,
                          role,
                          allIds,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : (
              //       <>
              //         <Filters
              //           data={initData}
              //           setDataByFilter={handleSetDataByFilter}
              //           hasStatus={false}
              //         />
              //         <Table selectable>
              //           <Table.Header>
              //             <Table.Row>
              //               {/* <Table.HeaderCell>
              //   <Checkbox />
              // </Table.HeaderCell> */}
              //               <Table.HeaderCell>{t.parts.table.ID}</Table.HeaderCell>
              //               <Table.HeaderCell>{t.parts.table.name}</Table.HeaderCell>
              //               <Table.HeaderCell>
              //                 {t.parts.table.serialNumber}
              //               </Table.HeaderCell>
              //               <Table.HeaderCell>
              //                 {t.parts.table.quantity}
              //               </Table.HeaderCell>
              //               <Table.HeaderCell>{t.parts.table.cost}</Table.HeaderCell>
              //               {/* <Table.HeaderCell>{t.parts.table.barcode}</Table.HeaderCell> */}
              //               <Table.HeaderCell>{t.parts.table.area}</Table.HeaderCell>
              //               <Table.HeaderCell>
              //                 {t.parts.table.category}
              //               </Table.HeaderCell>
              //               <Table.HeaderCell>{t.parts.table.vendor}</Table.HeaderCell>
              //               <Table.HeaderCell>
              //                 {t.parts.table.createdByUser}
              //               </Table.HeaderCell>
              //               <Table.HeaderCell>
              //                 {t.parts.table.createdAt}
              //               </Table.HeaderCell>
              //               <Table.HeaderCell>
              //                 {t.parts.table.updatedAt}
              //               </Table.HeaderCell>
              //             </Table.Row>
              //           </Table.Header>

              //           <Table.Body>
              //             {getPaginatedData(data).map((el) => {
              //               let nameIMG = el?.attributes?.image?.data ? (
              //                 <div>
              //                   <Image
              //                     avatar
              //                     title={el.attributes.name}
              //                     src={
              //                       IMAGES_URL + el.attributes.image.data.attributes.url
              //                     }
              //                   />
              //                   <span style={{ marginLeft: '4px' }}>
              //                     {el.attributes.name.substring(0, 20) + '...'}
              //                   </span>
              //                 </div>
              //               ) : (
              //                 el?.attributes?.name?.substring(0, 20) + '...'
              //               );

              //               let createdByUser = el?.attributes?.createdByUser?.data ? (
              //                 <div>
              //                   <Image
              //                     avatar
              //                     title={
              //                       el.attributes.createdByUser.data.attributes
              //                         .firstName +
              //                       ' ' +
              //                       el.attributes.createdByUser.data.attributes.lastName
              //                     }
              //                     src={
              //                       el?.attributes?.createdByUser?.data?.attributes
              //                         ?.profileImage?.data?.attributes?.url
              //                         ? IMAGES_URL +
              //                           el?.attributes?.createdByUser?.data?.attributes
              //                             ?.profileImage?.data?.attributes?.url
              //                         : LetteredAvatarImageUrl(
              //                             el.attributes.createdByUser.data.attributes
              //                               .firstName +
              //                               ' ' +
              //                               el.attributes.createdByUser.data.attributes
              //                                 .lastName
              //                           )
              //                     }
              //                     style={{ width: '20px', height: '20px' }}
              //                   />
              //                   <span style={{ marginLeft: '4px' }}>
              //                     {
              //                       el.attributes.createdByUser.data.attributes
              //                         .firstName
              //                     }
              //                   </span>
              //                 </div>
              //               ) : (
              //                 t.common.na
              //               );
              //               let vendor = el?.attributes?.vendor?.data ? (
              //                 <div>
              //                   <Image
              //                     avatar
              //                     title={el.attributes.vendor.data.attributes.name}
              //                     src={
              //                       el?.attributes?.vendor?.data?.attributes?.logo?.data
              //                         ?.attributes?.url
              //                         ? IMAGES_URL +
              //                           el?.attributes?.vendor?.data?.attributes?.logo
              //                             ?.data?.attributes?.url
              //                         : LetteredAvatarImageUrl(
              //                             el.attributes.vendor.data.attributes.name
              //                           )
              //                     }
              //                     style={{ width: '20px', height: '20px' }}
              //                   />
              //                   <span style={{ marginLeft: '4px' }}>
              //                     {el.attributes.vendor.data.attributes.name}
              //                   </span>
              //                 </div>
              //               ) : (
              //                 t.common.na
              //               );
              //               return (
              //                 <>
              //                   <Table.Row
              //                     key={el.attributes.id}
              //                     id={el.attributes.id}
              //                     className="accordion-row"
              //                     onClick={() => {
              //                       setTargetedData(el);
              //                       setTogglePreviw(true);
              //                     }}
              //                   >
              //                     <Table.Cell>{el.id}</Table.Cell>
              //                     <Table.Cell>{nameIMG}</Table.Cell>
              //                     <Table.Cell>
              //                       {el.attributes.serialNumber || t.common.na}
              //                     </Table.Cell>
              //                     <Table.Cell>{el.attributes.quantity}</Table.Cell>
              //                     <Table.Cell>{el.attributes.cost}</Table.Cell>
              //                     {/* <Table.Cell>{el.attributes.barcode}</Table.Cell> */}
              //                     <Table.Cell>
              //                       {el.attributes.area || t.common.na}
              //                     </Table.Cell>
              //                     <Table.Cell>
              //                       {el?.attributes?.categories?.data?.attributes
              //                         ?.name || t.common.na}
              //                     </Table.Cell>
              //                     <Table.Cell>{vendor}</Table.Cell>
              //                     <Table.Cell>{createdByUser}</Table.Cell>
              //                     <Table.Cell>
              //                       {
              //                         <Moment
              //                           format="DD/MM/YYYY LT"
              //                           date={el.attributes.createdAt}
              //                         />
              //                       }
              //                     </Table.Cell>
              //                     <Table.Cell>
              //                       {
              //                         <Moment
              //                           format="DD/MM/YYYY LT"
              //                           date={el.attributes.updatedAt}
              //                         />
              //                       }
              //                     </Table.Cell>
              //                   </Table.Row>
              //                 </>
              //               );
              //             })}
              //           </Table.Body>
              //         </Table>

              //         {data?.length > 0 ? (
              //           <Pagination
              //             activePage={currentPage}
              //             totalPages={totalFilteredPages}
              //             onPageChange={(_, { activePage }) =>
              //               setCurrentPage(activePage)
              //             }
              //             firstItem={
              //               selectedLanguage === 'en'
              //                 ? {
              //                     content: <Icon name="angle double left" />,
              //                     icon: true,
              //                   }
              //                 : {
              //                     content: <Icon name="angle double left" />,
              //                     icon: true,
              //                   }
              //             }
              //             lastItem={
              //               selectedLanguage === 'en'
              //                 ? {
              //                     content: <Icon name="angle double right" />,
              //                     icon: true,
              //                   }
              //                 : {
              //                     content: <Icon name="angle double right" />,
              //                     icon: true,
              //                   }
              //             }
              //             prevItem={
              //               selectedLanguage === 'en'
              //                 ? { content: <Icon name="angle left" />, icon: true }
              //                 : { content: <Icon name="angle left" />, icon: true }
              //             }
              //             nextItem={
              //               selectedLanguage === 'en'
              //                 ? { content: <Icon name="angle right" />, icon: true }
              //                 : { content: <Icon name="angle right" />, icon: true }
              //             }
              //           />
              //         ) : (
              //           <div style={{ textAlign: 'center', marginTop: '48px' }}>
              //             <Header as="h2">{t.common.no_result_found}</Header>
              //           </div>
              //         )}
              //       </>
              <div className="">
                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <PurchaseBlueIcon />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "170px" : "70px"
                          }
                          arrowAlign="start"
                          fontSize="14px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={
                            selectedLanguage == "ar" ? "40px" : "120px"
                          }
                          moveUp="-1px"
                        >
                          <span className="work-order-tooltip">
                            {t.po.how_to_add}
                          </span>
                        </ToolTip>
                        {" " + t.po.title + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.["purchase-order"]?.create ? (
                      <button
                        className="work-order-button-transparent"
                        onClick={() => {
                          setToggleAsideModal(true);
                        }}
                      >
                        {t.po.start_add}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {toggleAsideModal && (
          <Add
            toggled={toggleAsideModal}
            untoggle={() => {
              setToggleAsideModal(false);
              setModalWidth(Math.max(window.innerWidth / 2, 514));
            }}
            role={role}
            allIds={allIds}
            setListSearchTitle={setSearchTitle}
            setSortData={setSortData}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            setDueDates={setDueDates}
            setSearchTitle={setSearchTitle}
            setSelectedDates={setSelectedDates}
          />
        )}
        {togglePreview && (
          <Preview
            targetedData={targetedData}
            role={role}
            allIds={allIds}
            toggled={togglePreview}
            setModalWidth={setModalWidth}
            modalWidth={modalWidth}
            edit={() => {
              setTogglePreviw(false);
              setModalWidth(Math.max(window.innerWidth / 2, 514));
              setToggleEditModal(true);
            }}
            // toggleEdit={handleToggleEdit}
            untoggle={() => {
              setTargetedData();
              setModalWidth(Math.max(window.innerWidth / 2, 514));
              setTogglePreviw(false);
              history.push("/purchase-orders");
            }}
            setFilterOptions={setFilterOptions}
            setSelectedDates={setSelectedDates}
            setDueDates={setDueDates}
            setSearchTitle={setSearchTitle}
          />
        )}

        {toggleEditModal && (
          <Edit
            targetedData={targetedData}
            role={role}
            allIds={allIds}
            toggled={toggleEditModal}
            untoggle={() => {
              setTargetedData();
              setToggleEditModal(false);
              setModalWidth(Math.max(window.innerWidth / 2, 514));
            }}
            setFilterOptions={setFilterOptions}
            setSelectedDates={setSelectedDates}
            setDueDates={setDueDates}
          />
        )}

        {/* <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
      />

      <Edit
        targetedData={targetedData}
        toggled={toggleEditModal}
        untoggle={() => {
          setTargetedData();
          setToggleEditModal(false);
        }}
        setFilterOptions={setFilterOptions}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      */}
      </div>{" "}
    </>
  );
};

export default connect(null, auth.actions)(PurchaseOrder);
