import React, { useState, useEffect, useRef } from "react";

import { toast } from "react-toastify";
import {
  Image as SemanticImage,
  Loader,
  Dimmer,
  Input,
  Tab,
  Modal,
} from "semantic-ui-react";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { CloseIcon, Success, Close } from "../../../../Assets/Icons/svg";
import {
  IMAGES_URL,
  deleteAsset,
  getPreventiveOrdersOfAsset,
  updateAssetsStatus,
} from "../../../config/functions";

import Details from "./details";
import Orders from "./orders";
import { selectTranslations } from "../../../config/i18n/slice";
import { useDispatch, useSelector } from "react-redux";
import { useDeletePermissionModal } from "../../../../hooks/Messages/useDeletePermissionModal";
import { useDeleteResultModal } from "../../../../hooks/Messages/useDeleteResultModal";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import moment from "moment";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import Asterisk from "../../../components/common/Asterisk";
import ToolTip from "react-power-tooltip";

import { Resizable } from "re-resizable";
import ChildAsset from "./childAsset";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
import ReactTooltip from "react-tooltip";

function Preview({
  toggled,
  untoggle,
  setActiveIndex,
  edit,
  targetedData,
  refresh,
  setRefresh,
  setFilterOptions,
  setModalWidth,
  modalWidth,
  setSearchTitle,
  setSelectedDates,
  activeIndex,
  allIds,
  role,
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const handleTabChange = (e, { activeIndex }) => {
    let setFlag = false;

    if (activeIndex == 1 && !(role === "super_admin")) {
      if (targetedData?.attributes?.isChild === false) {
        setFlag = !panes.some((pane) => pane.pane.key === "child");

        if (targetedData?.attributes?.childAssets?.data?.length > 0) {
          targetedData.attributes.childAssets.data.forEach((el) => {
            if (allIds?.includes(el?.attributes?.location?.data?.id)) {
              setFlag = true;
            }
          });
        }

        if (targetedData?.attributes?.grandChildAssets?.data?.length > 0) {
          targetedData.attributes.grandChildAssets.data.forEach((el) => {
            if (allIds?.includes(el?.attributes?.location?.data?.id)) {
              setFlag = true;
            }
          });
        }
      } else {
        setFlag = !panes.some((pane) => pane.pane.key === "child");
        if (targetedData?.attributes?.grandChildAssets?.data?.length > 0) {
          targetedData.attributes.grandChildAssets.data.forEach((el) => {
            if (allIds?.includes(el?.attributes?.location?.data?.id)) {
              setFlag = true;
            }
          });
        }
      }
    }
    if (!(role === "super_admin") && activeIndex == 1) {
      if (setFlag) {
        setActiveIndex(activeIndex);
      }
      if (
        targetedData?.attributes?.childAssets &&
        !targetedData?.attributes?.grandChildAssets
      ) {
        setActiveIndex(activeIndex);
      } else if (
        targetedData?.attributes?.isChild == false &&
        activeIndex == 1
      ) {
        if (setFlag || activeIndex !== 1) {
          setActiveIndex(activeIndex);
        } else {
          e.preventDefault();
        }
      }
    } else {
      setActiveIndex(activeIndex);
    }
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const dispatch = useDispatch();

  const [fetching, setFetchLoading] = useState(true);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [allWOData, setAllWOData] = useState([]);
  const [completedWOData, setCompletedWOData] = useState([]);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [disposeModal, setDisposeModal] = useState(false);
  const [reason, setReason] = useState();
  const [cost, setCost] = useState();

  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const popUpRef = useRef(null);
  let panes;
  let storredAsset = {};
  let updatedTargetedData = {};
  /* update the component's data based on changes to `targetedData`. */
  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await getPreventiveOrdersOfAsset(
        targetedData?.id,
        organisationId,
         []
      );

      if (fetchData) {
        setAllWOData(fetchData);

        let compltedData = fetchData?.find(
          (el) => el.attributes.status === "completed"
        );
        setCompletedWOData(compltedData);
        setFetchLoading(false);
      }
    };
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      fetchData();
      setFetchLoading(false);
    }
  }, [targetedData]);

  /* close a popup or hide a specific element when a click occurs outside of it. */
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      let execlude_ids = ["popupTrigger"];

      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);

  /*
   * The function `confirmDeclineUser` displays a button to dispose an asset if its status is not already set to 'dispose'.
   */
  const confirmDeclineUser = () => {
    if (data?.attributes.status !== "dispose") {
      return (
        <div className="hold-button-container">
          <button
            onClick={() => {
              setDisposeModal(true);
            }}
            style={
              selectedLanguage == "en"
                ? { marginRight: "22px" }
                : { marginLeft: "22px" }
            }
            className="form-action-button  light-blue"
          >
            {t.assets.disposed}
          </button>
        </div>
      );
    }
  };

  /*
   * The function `updateStatusHandler` handles updating the status of assets with a success message displayed upon successful update and error messages for different scenarios.
   */
  const updateStatusHandler = async () => {
    if (!reason || !cost) {
      return toast.error(t.assets.add_cost_reason);
    }

    let disposeData = {
      reason: reason,
      costs: cost,
    };
    const response = await updateAssetsStatus(
      data?.id,
      "dispose",
      disposeData,
      organisationId
    );
    setDisposeModal(false);
    if (response.status == 200) {
      toast.success(t.assets.form.update_status_asset_success_deactivate);
      setFilterOptions({
        status: [],
        searchTitleType: 0,
        wed: {
          value: 0,
          text: "Any Date",
        },
        createdAt: {
          value: 0,
          text: t.assets.filter.createdAt,
        },
        locations: [],
        createdByUser: [],
        customers: [],
        vendors: [],
        categories: [],
        serialNumber: "",
      });

      setSearchTitle("");
      setSelectedDates([]);
      setCost("");
      setReason("");
      untoggle();
      // setActiveIndex(0)
      // toggleUpdateSuccessModal('close');

      // toggleUpdateSuccessModal();
    } else if (response.status == 400) {
      // toggleUpdateSuccessModal();
      toast.error(t.assets.form.related_status_asset_error);
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  /* Define headers for excel and pdf file */
  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.assets.table.name, key: "name" },
    { label: t.assets.table.status, key: "status" },
    { label: t.assets.form.description, key: "description" },
    { label: t.assets.form.image, key: "image" },
    { label: t.assets.table.serialNumber, key: "serialNumber" },
    { label: t.assets.form.location, key: "location" },
    { label: t.assets.form.sub_locations, key: "subLocations" },
    { label: t.assets.form.area, key: "area" },
    { label: t.assets.form.category, key: "category" },
    { label: t.assets.table.model, key: "model" },
    { label: t.assets.form.procedure, key: "procedure" },
    { label: t.assets.form.customer, key: "customer" },
    { label: t.assets.form.vendor, key: "vendor" },
    { label: t.assets.form.parts, key: "parts" },
    { label: t.assets.form.warranty_file, key: "expiryFile" },
    { label: t.assets.form.warranty_expiration_date, key: "expiryDate" },
    { label: t.assets.form.placed_in_service_date, key: "placedInServiceDate" },
    {
      label: t.assets.form.additional_information,
      key: "additionalInformation",
    },
  ];

  /*
   * The function `downloadXLS` generates CSV data for assets information and downloads it as an Excel file.
   */
  const downloadXLS = () => {
    let csvData = [];

    let assets_data = [];

    assets_data.id = data?.attributes?.countId;
    assets_data.name = data.attributes.name || t.common.na;
    assets_data.status =
      data?.attributes?.status === "active"
        ? t.assets.active
        : data?.attributes?.status === "inactive"
        ? t.assets.inActive
        : t.assets.disposed_asset;
    assets_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;
    assets_data.image = data.attributes?.image?.data?.attributes?.url
      ? IMAGES_URL + data.attributes.image.data.attributes.url
      : t.common.na;
    assets_data.serialNumber = data.attributes.serialNumber || t.common.na;
    assets_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    assets_data.subLocation = data?.attributes?.subLocations?.data?.attributes
      ?.name
      ? data.attributes.subLocations.data.attributes.name
      : t.common.na;

    let areaData = data?.attributes?.area;
    let areaString = "";

    if (areaData?.length > 0) {
      areaString = areaData.map((each) => each.area).join(", ");
    } else {
      areaString = t.common.na;
    }

    assets_data.area = areaString;
    assets_data.category = data.attributes?.categories?.data?.attributes?.name
      ? data.attributes.categories.data.attributes.name
      : t.common.na;
    assets_data.model = data.attributes.model || t.common.na;

    let procedureData = data.attributes?.procedure?.data;
    let procedureString = "";

    if (procedureData?.length > 0) {
      procedureString = procedureData
        .map((each) => each.attributes?.title)
        .join(", ");
    } else {
      procedureString = t.common.na;
    }

    assets_data.procedure = procedureString;

    assets_data.customer = data.attributes?.customer?.data?.attributes.name
      ? data.attributes.customer.data.attributes.name
      : t.common.na;
    assets_data.vendor = data.attributes?.vendor?.data?.attributes?.name
      ? data.attributes?.vendor?.data?.attributes?.name
      : t.common.na;

    let partsData = data.attributes?.parts?.data;
    let partsString = "";

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(", ");
    } else {
      partsString = t.common.na;
    }

    assets_data.parts = partsString;

    assets_data.warrantyExpirationDate = data.attributes?.warrantyExpirationDate
      ? moment(data.attributes?.warrantyExpirationDate).format("YYYY-MM-DD")
      : t.common.na;

    assets_data.warranty_file = data.attributes?.warrantyFile?.data?.attributes
      ?.url
      ? IMAGES_URL + data.attributes?.warrantyFile?.data?.attributes?.url
      : t.common.na;

    assets_data.placedInServiceDate =
      moment(data.attributes.createdAt).format("YYYY-MM-DD") || t.common.na;

    assets_data.additionalInformation = data.attributes?.additionalInformation
      ? data.attributes?.additionalInformation
      : t.common.na;
    csvData.push([
      assets_data.id,
      assets_data.name,
      assets_data.status,
      assets_data.description,
      assets_data.image,
      assets_data.serialNumber,
      assets_data.location,
      assets_data.subLocation,
      assets_data.area,
      assets_data.category,
      assets_data.model,
      assets_data.procedure,
      assets_data.customer,
      assets_data.vendor,
      assets_data.parts,
      assets_data.warranty_file,
      assets_data.warrantyExpirationDate,
      assets_data.placedInServiceDate,
      assets_data.additionalInformation,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 10 }, // Title column
      { wch: 35 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 15 }, // Created At column
      { wch: 15 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 35 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      data?.attributes?.name || t.assets.title
    );
  };

  /*
   * The `generatePDF` function creates a PDF file with asset data formatted in a table structure.
   */
  const generatePDF = () => {
    let csvData = [];

    let assets_data = [];

    assets_data.id = data?.attributes?.countId;
    assets_data.name = data.attributes.name || t.common.na;
    assets_data.status =
      data?.attributes?.status === "active"
        ? t.assets.active
        : data?.attributes?.status === "inactive"
        ? t.assets.inActive
        : t.assets.disposed_asset;
    assets_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;
    assets_data.image = data.attributes?.image?.data?.attributes?.url
      ? IMAGES_URL + data.attributes.image.data.attributes.url
      : t.common.na;
    assets_data.serialNumber = data.attributes.serialNumber || t.common.na;
    assets_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    assets_data.subLocation = data?.attributes?.subLocations?.data?.attributes
      ?.name
      ? data.attributes.subLocations.data.attributes.name
      : t.common.na;

    let areaData = data?.attributes?.area;
    let areaString = "";

    if (areaData?.length > 0) {
      areaString = areaData.map((each) => each.area).join(", ");
    } else {
      areaString = t.common.na;
    }

    assets_data.area = areaString;
    assets_data.category = data.attributes?.categories?.data?.attributes?.name
      ? data.attributes.categories.data.attributes.name
      : t.common.na;
    assets_data.model = data.attributes.model || t.common.na;

    let procedureData = data.attributes?.procedure?.data;
    let procedureString = "";

    if (procedureData?.length > 0) {
      procedureString = procedureData
        .map((each) => each.attributes?.title)
        .join(", ");
    } else {
      procedureString = t.common.na;
    }

    assets_data.procedure = procedureString;

    assets_data.customer = data.attributes?.customer?.data?.attributes.name
      ? data.attributes.customer.data.attributes.name
      : t.common.na;
    assets_data.vendor = data.attributes?.vendor?.data?.attributes?.name
      ? data.attributes?.vendor?.data?.attributes?.name
      : t.common.na;

    let partsData = data.attributes?.parts?.data;
    let partsString = "";

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(", ");
    } else {
      partsString = t.common.na;
    }

    assets_data.parts = partsString;

    assets_data.warrantyExpirationDate = data.attributes?.warrantyExpirationDate
      ? moment(data.attributes?.warrantyExpirationDate).format("YYYY-MM-DD")
      : t.common.na;

    assets_data.warranty_file = data.attributes?.warrantyFile?.data?.attributes
      ?.url
      ? IMAGES_URL + data.attributes?.warrantyFile?.data?.attributes?.url
      : t.common.na;

    assets_data.placedInServiceDate =
      moment(data.attributes.createdAt).format("YYYY-MM-DD") || t.common.na;

    assets_data.additionalInformation = data.attributes?.additionalInformation
      ? data.attributes?.additionalInformation
      : t.common.na;

    csvData.push([
      assets_data.id,
      assets_data.name,
      assets_data.status,
      assets_data.description,
      assets_data.image,
      assets_data.serialNumber,
      assets_data.location,
      assets_data.subLocation,
      assets_data.area,
      assets_data.category,
      assets_data.model,
      assets_data.procedure,
      assets_data.customer,
      assets_data.vendor,
      assets_data.parts,
      assets_data.warranty_file,
      assets_data.warrantyExpirationDate,
      assets_data.placedInServiceDate,
      assets_data.additionalInformation,
    ]);

    const columnWidths = [
      10,
      20,
      ,
      20,
      50,
      24,
      22,
      15,
      20,
      ,
      ,
      25,
      ,
      ,
      ,
      45,
      20,
    ];
    DownloadPdfFileWithData(
      headers,
      csvData,
      data?.attributes?.name || t.assets.title,
      columnWidths,
      "A2"
    );
  };

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeletePermissionModal(true);
    setPopupIsOpen(false);
  };

  if (
    targetedData?.attributes?.isChild === true &&
    !targetedData?.attributes?.childAssets &&
    targetedData?.attributes?.grandChildAssets
  ) {
    let attributesCopy = { ...targetedData.attributes };
    let storedAssetsData = {
      storedAssets: [
        {
          grandChildAsset: {
            data: targetedData?.attributes?.grandChildAssets?.data || [],
          },
        },
      ],
    };
    attributesCopy.storedAssets = storedAssetsData.storedAssets;
    // Adding storedAssetsData to targetedData attributes
    // targetedData.attributes.storedAssets = storedAssetsData.storedAssets;
    updatedTargetedData = {
      ...targetedData,
      attributes: attributesCopy,
    };
    // You can now use targetedData with the new structure
  }

  /* The above code is defining an array called `panes` which contains objects with `menuItem` and `pane`
properties. Each object represents a tab pane with a menu item and content to be displayed when the
tab is selected. */

  if (
    targetedData?.attributes?.isChild == false ||
    (!targetedData?.attributes?.childAssets &&
      targetedData?.attributes?.grandChildAssets)
  ) {
    panes = [
      {
        menuItem: t.common.details,
        pane: {
          key: "details",
          content: (
            <div className="pane-body">
              <Details
                untoggle={untoggle}
                refresh={refresh}
                toggled={toggled}
                setRefresh={setRefresh}
                isChildAsset={targetedData?.attributes?.isChild}
                data={
                  targetedData?.attributes?.isChild == false
                    ? data
                    : updatedTargetedData
                }
                setDisposeModal={setDisposeModal}
                setFilterOptions={setFilterOptions}
                setSearchTitle={setSearchTitle}
                setSelectedDates={setSelectedDates}
              />
            </div>
          ),
        },
      },
      {
        menuItem: t.assets.form.child_asset,
        pane: {
          key: "child",
          content: (
            <div className="pane-body">
              <ChildAsset
                completedWOData={completedWOData}
                allIds={allIds}
                role={role}
                isChildAsset={targetedData?.attributes?.isChild}
                data={
                  targetedData?.attributes?.isChild == false
                    ? data
                    : updatedTargetedData
                }
              />
            </div>
          ),
        },
      },
      {
        menuItem: t.workOrders.title,
        pane: {
          key: "orders",
          content: (
            <div className="pane-body">
              <Orders fetching={fetching} data={allWOData} />
            </div>
          ),
        },
      },
      // {
      //   menuItem:"WOs",
      //   pane: {
      //     key: 'WOs',
      //     content: (
      //       <div className="pane-body">
      //         <WorkOrder id={data?.id} />
      //       </div>
      //     ),
      //   },
      // },
      // {
      //   menuItem: t.workOrders.details_view.log,
      //   pane: {
      //     key: 'logs',
      //     content: (
      //       <div className="pane-body">
      //         <Logs completedWOData={completedWOData} targetedData={data} />
      //       </div>
      //     ),
      //   },
      // },
    ];

    let setFlag = false;

    if (!(role === "super_admin")) {
      if (targetedData?.attributes?.isChild === false) {
        if (targetedData?.attributes?.childAssets?.data?.length > 0) {
          targetedData.attributes.childAssets.data.forEach((el) => {
            if (allIds?.includes(el?.attributes?.location?.data?.id)) {
              setFlag = true;
            }
          });
        }

        if (targetedData?.attributes?.grandChildAssets?.data?.length > 0) {
          targetedData.attributes.grandChildAssets.data.forEach((el) => {
            if (allIds?.includes(el?.attributes?.location?.data?.id)) {
              setFlag = true;
            }
          });
        }
      }

      if (setFlag == false) {
        panes = panes.filter((pane) => pane.pane.key !== "child");
      }
    }
  } else {
    panes = [
      {
        menuItem: t.common.details,
        pane: {
          key: "details",
          content: (
            <div className="pane-body">
              <Details
                untoggle={untoggle}
                refresh={refresh}
                setRefresh={setRefresh}
                data={data}
                setFilterOptions={setFilterOptions}
                setSearchTitle={setSearchTitle}
                setSelectedDates={setSelectedDates}
                setDisposeModal={setDisposeModal}
              />
            </div>
          ),
        },
      },
      {
        menuItem: t.workOrders.title,
        pane: {
          key: "orders",
          content: (
            <div className="pane-body">
              <Orders fetching={fetching} data={allWOData} />
            </div>
          ),
        },
      },
      // {
      //   menuItem:"WOs",
      //   pane: {
      //     key: 'WOs',
      //     content: (
      //       <div className="pane-body">
      //         <WorkOrder id={data?.id} />
      //       </div>
      //     ),
      //   },
      // },
      // {
      //   menuItem: t.workOrders.details_view.log,
      //   pane: {
      //     key: 'logs',
      //     content: (
      //       <div className="pane-body">
      //         <Logs completedWOData={completedWOData} targetedData={data} />
      //       </div>
      //     ),
      //   },
      // },
    ];
  }

  /*
   * The `deleteHandler` function sends a request to delete an asset and displays a success or error message based on the response status.
   */

  const deleteHandler = async () => {
    const response = await deleteAsset(targetedData.id);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      toast.success(t.assets.form.delete_asset_success);

      setData();
      untoggle();

      setFilterOptions({
        status: [],
        searchTitleType: 0,
        wed: {
          value: 0,
          text: "Any Date",
        },
        createdAt: {
          value: 0,
          text: t.assets.filter.createdAt,
        },
        locations: [],
        createdByUser: [],
        customers: [],
        vendors: [],
        categories: [],
        serialNumber: "",
      });

      setSearchTitle("");
      setSelectedDates([]);

      // setDeleteResultModal(true);
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.assets.form.related_asset_error);
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  /*
   * The deleteSuccessHandler function resets data and filter options after a successful deletion.
   */
  const deleteSuccessHandler = (data) => {
    if (data) {
      setData();
      untoggle();
      setFilterOptions({
        status: [],
        searchTitleType: 0,
        wed: {
          value: 0,
          text: "Any Date",
        },
        createdAt: {
          value: 0,
          text: t.assets.filter.createdAt,
        },
        locations: [],
        createdByUser: [],
        customers: [],
        vendors: [],
        categories: [],
        serialNumber: "",
      });

      setSearchTitle("");
      setSelectedDates([]);
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.assets.asset} #{data?.attributes?.countId}
              </div>
              <div className="flexbox align-center detail-header-buttons">
                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={
                    userPermissions?.asset?.delete ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                  isEdit={
                    userPermissions?.asset?.edit ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                />
                <div
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="body">
            {data ? (
              <Tab
                panes={panes}
                onTabChange={handleTabChange}
                defaultActiveIndex={activeIndex == 1 ? 1 : 0}
                activeIndex={activeIndex ? activeIndex : 0}
                className={"location-tab"}
                renderActiveOnly={false}
              />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => {
          setData();
          untoggle();
          // setActiveIndex(0)
        }}
      ></div>

      {/* Delete Permission Modal */}
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.assets.add}
      >
        <span> {t.assets.form.delete_asset_statement}</span>
        <p style={{ lineHeight: "30px" }}>
          {" "}
          {t.assets.form.delete_asset_statement_1}
        </p>
      </DeletePermissionModal>

      {/* Delete Result Modal */}
      <DeleteResultModal
        closeCallback={() => {
          deleteSuccessHandler(deleteErrorMessage ? "" : "close");
        }}
        deleteErrorMessage={deleteErrorMessage}
      >
        <div className="mb-2">
          {deleteErrorMessage ? <Close /> : <Success />}
        </div>
        <p className="status-modal-popup-message">
          {deleteErrorMessage
            ? t.assets.form.related_asset_error
            : t.assets.form.delete_asset_success}
        </p>
      </DeleteResultModal>

      {/* dipose Modal */}
      <Modal
        className="assets-modal"
        size="tiny"
        open={disposeModal}
        onClose={() => {
          setDisposeModal(false);
          setCost("");
          setReason("");
        }}
      >
        <div className={"header-container red"}>
          <span className="assets-header"> {t.assets.disposed}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setDisposeModal(false);
                setCost("");
                setReason("");
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          <div className="comment-container p-2">
            <div className="">
              <label className="comment-label mb-1">
                {t.assets.add_reason}
                <Asterisk color="#172B4D" />
              </label>

              <Input
                autoComplete="new-password"
                type="text"
                fluid
                placeholder={t.assets.add_reason}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></Input>
            </div>

            <div className="mt-6">
              <label className="comment-label mb-1">
                {t.assets.added_cost}
                <Asterisk color="#172B4D" />
              </label>

              <Input
                autoComplete="new-password"
                type="number"
                fluid
                placeholder={t.assets.added_cost_placeholder}
                value={cost}
                onChange={(e) => setCost(e.target.value)}
              >
                <input />
                <span
                  className={`mr-2 d-flex align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-5" : "mr-5 p-1"
                  }`}
                  style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                >
                  {organisationCurrency}
                </span>
              </Input>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-6" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button"
              onClick={() => {
                setDisposeModal(false);
                setCost("");
                setReason("");
              }}
              style={{ color: "black" }}
            >
              {t.common.cancel}
            </button>

            <button
              onClick={() => {
                updateStatusHandler();
              }}
              className={`modal-form-action-button blue`}
            >
              {t.assets.dispose}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default Preview;
