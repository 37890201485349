import React, { useState, useEffect, createRef, useRef } from "react";
import {
  Icon,
  Button,
  Label,
  Table,
  Loader,
  Dimmer,
  TextArea,
  Form,
  Header,
  Modal,
  Tab,
} from "semantic-ui-react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import moment from "moment";
import {
  humanize,
  bytesToSize,
  deleteWorkRequest,
} from "../../../config/functions";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import {
  CloseIcon,
  DownloadIconBucket,
  EditTextIcon,
  DeleteIcon,
} from "../../../../Assets/Icons/svg";
import Details from "./details";
import Logs from "./logs";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import { Resizable } from "re-resizable";
import useDeletePermissionModal from "../../../../hooks/Messages/useDeletePermissionModal";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";

function Preview({
  targetedData,
  toggled,
  approveOrder,
  declineOrder,
  approveLoading,
  declineLoading,
  untoggle,
  modalWidth,
  setModalWidth,
  edit,
  setFilterOptions,
  setSearchTitle,
  setSelectedDates,
  currentUserId,
  roleData,
}) {
  const [fetching, setFetchLoading] = useState(true);
  const t = useSelector(selectTranslations);
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [toggleDeclineModal, setToggleDeclineModal] = useState(false);
  const [declinedReason, setDeclinedReason] = useState("");
  const [showExclTooltip, setShowExclTooltip] = useState(false);

  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);

  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [toggleAsideModal, setToggleAsideModal] = useState(false);

  const {
    userPermissions,
    checkForPermission,
    checkForWRPermission,
  } = usePermissionsSimplified();

  const [deleteModal, setDeleteModal] = useState(false);

  const WRstatusOptions = [
    {
      value: "approved",
      color: "green",
      label: t.workRequests.status.approved,
    },
    {
      value: "pending",
      color: "orange",
      label: t.workRequests.status.pending,
    },
    {
      value: "declined",
      color: "red",
      label: t.workRequests.status.declined,
    },
  ];
  const priorityOptions = [
    {
      value: "none",
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: "low",
      label: t.workOrders.priority_status.low,

      color: "yellow",
    },
    {
      value: "medium",
      label: t.workOrders.priority_status.medium,

      color: "orange",
    },
    {
      value: "high",
      label: t.workOrders.priority_status.high,
      color: "red",
    },
  ];

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "reOpen",
      color: "light-purple",
      label: t.workOrders.work_order_status.reOpen,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "green",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "darkblue",
      label: t.workOrders.work_order_status.completed,
    },
  ];

  const headers = [
    { label: t.workOrders.table.ID, key: "id" },
    { label: t.workOrders.table.title, key: "title" },
    { label: t.assets.form.description, key: "description" },
    { label: t.workRequests.table.wr_status, key: "status" },
    { label: t.workRequests.table.wo_status, key: "wo_status" },
    { label: t.workOrders.table.priority, key: "priority" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.workRequests.table.asset, key: "asset" },
    { label: t.workRequests.form.people, key: "people" },
    { label: t.workRequests.form.teams, key: "teams" },
    { label: t.workRequests.table.wo_id, key: "wo_id" },
    { label: t.workRequests.table.sub_date, key: "sub_date" },
    { label: t.workRequests.table.sub_by, key: "sub_by" },
  ];

  const generatePDF = () => {
    let csvData = [];

    let wr_data = [];

    wr_data.id = data?.attributes?.countId;
    wr_data.title = data.attributes.title || t.common.na;

    wr_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;

    const workRequestStatus = WRstatusOptions.find(
      (status) => status?.value === data?.attributes?.status
    );

    wr_data.status = workRequestStatus?.label || t.common.na;

    const workOrderStatus = statusOptions.find(
      (status) =>
        status.value === data?.attributes?.workOrder?.data?.attributes?.status
    );
    wr_data.wo_status = workOrderStatus?.label || "";

    const PriorityStatus = priorityOptions.find(
      (status) => status.value === data.attributes.priority
    );

    wr_data.priority = PriorityStatus?.label || t.common.na;

    wr_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    let assets = data.attributes?.assets?.data;
    let assetsString = "";

    if (assets?.length > 0) {
      assetsString = assets.map((asset) => asset?.attributes?.name).join(", ");
    } else {
      assetsString = t.common.na;
    }
    wr_data.asset = assetsString;

    let people = data?.attributes?.people?.data;
    let peopleString = "";

    if (people?.length > 0) {
      peopleString = people
        .map(
          (people) =>
            people?.attributes?.firstName + " " + people?.attributes?.lastName
        )
        .join(", ");
    } else {
      peopleString = t.common.na;
    }
    wr_data.people = peopleString;

    let teams = data?.attributes?.teams?.data;
    let teamsString = "";

    if (teams?.length > 0) {
      teamsString = teams.map((team) => team?.attributes?.name).join(", ");
    } else {
      teamsString = t.common.na;
    }
    wr_data.team = teamsString;

    wr_data.wo_id = data.attributes?.workOrder?.data?.id
      ? data.attributes?.workOrder?.data?.attributes?.countNumId
      : "";

    wr_data.sub_date = data?.attributes?.createdAt
      ? moment(data.attributes?.createdAt).format("DD/MM/YYYY")
      : t.common.na;

    wr_data.sub_by =
      data.attributes?.submittedBy?.data?.attributes?.firstName ||
      data?.attributes?.submittedBy?.data?.attributes?.lastName
        ? data.attributes?.submittedBy?.data?.attributes?.firstName +
          " " +
          data.attributes.submittedBy.data.attributes.lastName
        : t.common.na;

    csvData.push([
      wr_data.id,
      wr_data.title,
      wr_data.description,
      wr_data.status,
      wr_data.wo_status,
      wr_data.priority,
      wr_data.location,
      wr_data.asset,
      wr_data.people,
      wr_data.team,
      wr_data.wo_id,
      wr_data.sub_date,
      wr_data.sub_by,
    ]);

    const columnWidths = [10, 22, 22, 22, 23, 20, 23, 25, 25, 23, 16, 26, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      data?.attributes?.title || t.workRequests.title,
      columnWidths,
      "A3"
    );
  };

  /* The above code is defining an array called `panes` which contains objects with `menuItem` and `pane`
properties. Each object represents a tab pane with a menu item and content to be displayed when the
tab is selected. */
  const panes = [
    {
      menuItem: t.common.details,
      pane: {
        key: "details",
        content: (
          <div className="pane-body">
            <Details
              untoggle={untoggle}
              allIds={currentUserId}
              roleData={roleData}
              setmodalWidth={setModalWidth}
              modalWidth={modalWidth}
              setToggleAsideModal={setToggleAsideModal}
              toggleAsideModal={toggleAsideModal}
              data={data}
              setMessage={setMessage}
              message={message}
              setData={setData}
              setFilterOptions={setFilterOptions}
              setSearchTitle={setSearchTitle}
              setSelectedDates={setSelectedDates}
              targetedData={targetedData}
            />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'logs',
    //     content: (
    //       <div className="pane-body">
    //         <Logs targetedData={data} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeletePermissionModal(true);
    setPopupIsOpen(false);
  };

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      let execlude_ids = ["popupTrigger"];
      // console.log(e.target.id);
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }
  };
  const deleteHandler = async () => {
    const response = await deleteWorkRequest(targetedData.id);

    // if (response.status == 200) {
    //   untoggle();
    //   setModalWidth(Math.max(window.innerWidth / 2, 514));
    //   setMessage('');
    // }
    if (response?.status == 200) {
      toast.success(t.workRequests.form.delete_success);
      setFilterOptions({
        requestedBy: [],
        locations: [],
        assets: [],
        priority: [],
        status: [],
        priority: [],
        status: [],
      });
      setSearchTitle("");
      toggleDeleteSuccessModal("close");
    } else if (response?.status == 400) {
      toast.error(t.workRequests.form.delete_related_error);
      toggleDeleteSuccessModal();
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={`aside-modal preview procedure ${toggled ? "active" : ""}`}
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.workRequests.work_request} #{data?.attributes?.countId}
              </div>
              {/* {orderActions()} */}

              <div className="flexbox align-center workorder-header-buttons">
                {/* <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setShowPdfTooltip(true)}
                  onMouseLeave={() => setShowPdfTooltip(false)}
                  onClick={() => generatePDF()}
                >
                  <DownloadIconBucket />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showPdfTooltip}
                    textBoxWidth="60px"
                    arrowAlign="center"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.export_pdf}
                    </span>
                  </ToolTip>
                </span> */}
                {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </ToolTip>
              </span> */}
                {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />

                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </ToolTip>
              </span> */}

                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={
                    userPermissions?.["work-request"]?.delete &&
                    targetedData?.attributes?.status != "approved"
                  }
                  isEdit={
                    (userPermissions?.["work-request"]?.edit ||
                      checkForWRPermission(
                        targetedData?.attributes?.submittedBy?.data?.id
                      )) &&
                    targetedData?.attributes?.status === "pending"
                  }
                />

                {/* {(userPermissions?.['work-request']?.edit ||
                  checkForWRPermission(
                    targetedData?.attributes?.submittedBy?.data?.id
                  )) &&
                targetedData?.attributes?.status === 'pending' ? (
                  <span
                    className="header-button c-pointer mt-1"
                    style={{ position: 'relative' }}
                    onMouseOver={() => setEditTooltip(true)}
                    onMouseLeave={() => setEditTooltip(false)}
                    onClick={() => {
                      edit();
                      setPopupIsOpen(false);
                    }}
                  >
                    <EditTextIcon />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="bottom center"
                      show={showEditTooltip}
                      textBoxWidth="33px"
                      arrowAlign="center"
                      padding="5px 5px 5px 8px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.edit}
                      </span>
                    </ToolTip>
                  </span>
                ) : null} */}
                {/* {(userPermissions?.['work-request']?.delete 
             ) && targetedData?.attributes?.status !='approved' ?(
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    // setDeleteModal(true);
                    setDeletePermissionModal(true);
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                     arrowAlign="center"
                  position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                  
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </ToolTip>
                </span>
              ) : null}  */}

                <span
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() => {
                    untoggle();
                    setModalWidth(Math.max(window.innerWidth / 2, 514));
                    setToggleAsideModal(false);
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </span>
              </div>
            </div>
          </div>

          <div className="body">
            {data ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled || toggleAsideModal
            ? "aside-modal-background active"
            : "aside-modal-background"
        }
        onClick={() => {
          setData();
          untoggle();
          setMessage("");
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setToggleAsideModal(false);
        }}
      ></div>

      {/* delete modal */}

      {/* <Modal
        size="tiny"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper red">
              <Icon name="close" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              Are you sure want to delete this user?
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setDeleteModal(false);
              deleteHandler();
            }}
          >
            Delete
          </Button>
          <Button color="black" onClick={() => setDeleteModal(false)}>
            Keep
          </Button>
        </Modal.Actions>
      </Modal>*/}
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.workRequests.work_request}
      >
        <span>{t.workRequests.form.decline_wr_statement}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
