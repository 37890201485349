export default {
  title: 'اللإجراءات المتبعة',
  title2: 'المكتبة العالمية',
  tabs: {
    first: 'إجراءاتي',
    second: 'المكتبة العالمية',
  },
  procedure: 'إجراء',
  add: 'إجراء',
  filter: {
    search: 'بحث',
  },

  form: {
    form_title: {
      add: 'إضافة إجراء',
      edit: 'تعديل إجراء',
    },
    procedure_title: 'عنوان اللإجراء',
    add_procedure_title: 'أضف عنوان اللإجراء',
    description: 'الوصف',
    add_description: 'أضف الوصف',
    procedure_items: 'بنود اللإجراءات',
    title: 'العنوان',
    title_placeholder: 'ادخل العنوان',
    type: 'النوع',
    type_options: [
      'خانة الاختيار',
      'نص',
      'رقم',
      'المبلغ',
      'متعدد الخيارات',
      'قائمة تدقيق',
      'فحص التدقيق',
      'توقيع',
      'تاريخ',
      'ملف',
      'صورة',
    ],
    checkbox_label: 'خانة اختيار',
    text_placeholder: 'سيقوم المكلفون بإضافة نص هنا',
    number_placeholder: 'سيقوم المكلفون بإضافة رقم هنا',
    wo_placeholder: 'سيقوم المكلفون بإضافة أمر العمل',
    amount_placeholder: 'سوف يقوم المكلفون بإضافة مبلغ هنا',
    signature_placeholder: 'سيقوم المكلفون بإضافة توقيع هنا',
    file_placeholder: 'سيقوم المكلفون بإضافة ملف هنا',
    photo_placeholder: 'سيقوم المكلفون بإضافة صورة هنا',
    options: 'خيارات',
        date_placeholder: 'سيقوم المكلفون بإضافة تاريخ هنا',
    header: 'العنوان',
    option: 'خيار',
    add_option: 'إضافة خيار',
    inspection_type: 'نوع الفحص',
    inspection_types: [
      ['نعم', 'لا', 'غير متاح'],
      ['جيد', 'منصف', 'ضعيف', 'غير متاح'],
      ['آمن', 'خطر', 'غير متاح'],
      ['نجح', 'فشل', 'غير متاح'],
      ['متوافق', 'غير متوافق', 'غير متاح'],
      ['مقياس إلى خمسة (0-5)'],
    ],
    inspection_type_placeholder: 'اختر نوع الفحص',
    failed_value: 'قيمة الفشل',
    failed_value_placeholder: 'اختر قيمة الفشل',
    add_logic: 'أضف منطق',
    if_answer_is: 'إذا كان الجواب',
    if_answer_is_placeholder: 'حدد إجابة',
    then: 'إذا',
    // then_options: ['مطلوب إجراء', 'مطلوب دليل'],
    then_options: ['مطلوب أمر العمل',  'مطلوب دليل'],
    required_evidence: 'مطلوب دليل',
    // required_action: 'مطلوب إجراء',
    required_action: 'مطلوب أمر العمل',
    then_placeholder: 'حدد',
    submit: {
      add: 'تقديم',
      edit: 'تحديث',
    },
    cancel_button: 'إلغاء',
    success: {
      msg: "تم إضافة اللإجراء بنجاح",
      ok: 'موافق',
    },
    cancel: {
      msg: 'هل أنت متأكد أنك تريد إلغاء؟',
      cancel: 'نعم ، إلغاء',
      keep: 'استمر',
    },
    is_required: 'مطلوب',
    remove_button: 'إزالة',
    add_procedure_item: 'بند اللإجراء',
    add_heading: 'العنوان',
    heading: 'عنوان',
    clone_template: 'تكرار النموذج',
    select_local_statement: 'اختر من المكتبة المحلية',
    add: 'إضافة',
    update_success_statement: 'تم تحديث اللإجراء بنجاح',
    delete_procedure_statement: 'هل أنت متأكد أنك تريد حذف هذا اللإجراء؟',
    delete_success_statement: 'تم حذف اللإجراء بنجاح',
    delete_connected_error_statement: 'لايمكن حذف اللإجراء. مرتبط بأشكال أخرى',
  },
  table: {
    title: 'العنوان',
    ID: 'الرمز',
    description: 'الوصف',
    fields: 'الحقول',
    fields_small: ' الحقول',
    createdByUser: 'أنشىء من قبل',
    createdAt: 'أنشىئ في',
    updatedAt: 'تم التحديث في',
  },
  details_view: {
    category: 'الفئة',
    add_category: 'اختر الفئة',
    all: 'الفئة',
    created_by: 'انشا من قبل',
    parts: 'القطع والمخزون',
    assets: 'الممتلكات',
    locations: 'المواقع',
    procedure_items: 'عناصر اللإجراء',
  },
  procedure_category: {
    Damage: 'ضرر',
    Electrical: 'الكهرباء',
    Inspection: 'تفتيش',
    Mechanical: 'ميكانيكي',
    Preventive: 'وقائي',
    Project: 'مشروع',
    Refrigeration: 'تبريد',
    Safety: 'أمان',
    Sop: 'إجراءات التشغيل القياسية',
  },
  inspectionTypes: {
    'n/a': 'غير متاح',
    no: 'لا',
    yes: 'نعم',
    good: 'جيد',
    fair: 'منصف',
    poor: 'ضعيف',
    pass: 'آمن',
    fail: 'خطر',
    safe: 'نجح',
    risk: 'فشل',
    compliant: 'متوافق',
    'non-compliant': 'غير متوافق',
    'scale to five (0 - 5)': 'مقياس إلى خمسة (0-5)',
  },
  no_procedure_available: 'يبدو أنك ليس لديك إجراءات بعد',
  press_to_add_procedure: "اضغط على زر '+' لإضافة أول إجراء لديك",
  procedures: 'اللإجراءات',
  procedures_small: 'إجراءات',
  create_procedure: 'إضافة إجراء',

  add_procedure: 'ابدأ بإضافة اللإجراءات',
  procedure_library: 'مكتبة اللإجراءات',
  how_to_add_procedure: 'كيفية إضافة اللإجراءات',
  item:" غرض",
  global:"المكتبة العالمية",
  answer: 'إذا كان الجواب',
  logic: 'المنطق',
  duplicate:"تكرار",
  add_type:"Add type",
    length_valid:"الرجاء إضافة عنصر إجراء واحد على الأقل."
};
