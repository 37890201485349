import React, { useState, useEffect, createRef, useRef } from "react";
import { Image, Loader, Dimmer, Tab } from "semantic-ui-react";

import { IMAGES_URL, deleteCustomer } from "../../../config/functions";

import { Country, State } from "country-state-city";

import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { LetteredAvatarImageUrl } from "../../../components/common/userProfilePlaceholder";
import { toast } from "react-toastify";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import Details from "./details";
import Customers from "./customers";
import { CloseIcon } from "../../../../Assets/Icons/svg";
import useDeletePermissionModal from "../../../../hooks/Messages/useDeletePermissionModal";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import { Resizable } from "re-resizable";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
import ReactTooltip from "react-tooltip";

function Preview({
  toggled,
  untoggle,
  edit,
  targetedData,
  setFilterOptions,
  setSearchTitle,
  setModalWidth,
  modalWidth,
}) {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      let execlude_ids = ["popupTrigger"];

      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
    });
  }, [popUpRef]);

  let createdByName = data?.attributes?.createdByUser?.data?.attributes ? (
    <div>
      <Image
        avatar
        title={
          data?.attributes?.createdByUser?.data?.attributes.firstName +
          " " +
          data?.attributes?.createdByUser?.data?.attributes.lastName
        }
        src={
          data?.attributes?.createdByUser?.data?.attributes.profileImage?.data
            ?.attributes?.url
            ? IMAGES_URL +
              data?.attributes?.createdByUser?.data?.attributes.profileImage
                ?.data?.attributes?.url
            : LetteredAvatarImageUrl(
                data?.attributes?.createdByUser?.data?.attributes.firstName +
                  " " +
                  data?.attributes?.createdByUser?.data?.attributes.lastName
              )
        }
      />
      <span style={{ marginLeft: "4px" }}>
        {data?.attributes?.createdByUser?.data?.attributes.firstName +
          " " +
          data?.attributes?.createdByUser?.data?.attributes.lastName}
      </span>
    </div>
  ) : (
    t.common.na
  );

  const deleteHandler = async () => {
    const response = await deleteCustomer(targetedData.id);

    if (response.status == 200) {
      toast.success(t.customers.form.customer_delete_success);
      toggleDeleteSuccessModal("close");
    } else if (response.status == 400) {
      toast.error(t.customers.form.customer_delete_related_error);
      toggleDeleteSuccessModal();
    } else {
      toast.error("Something went wrong.");
    }
  };

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeletePermissionModal(true);
    setPopupIsOpen(false);
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      setData();
      untoggle();
      setFilterOptions({
        countries: [],
        cities: [],
        locations: [],

        rate: {
          from: null,
          to: null,
        },
      });
      setSearchTitle("");
    }
  };

  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.customers.table.name, key: "name" },
    { label: t.customers.form.description, key: "description" },
    { label: t.customers.form.email, key: "email" },
    { label: t.customers.form.phone, key: "phone" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.customers.form.website, key: "website" },
    { label: t.customers.form.service_type, key: "service_type" },
    { label: t.common.industry, key: "industry" },
    { label: t.customers.contact_name, key: "contact_name" },
    { label: t.customers.contact_email, key: "contact_email" },
    { label: t.customers.contact_phone, key: "contact_phone" },
    { label: t.customers.billing_name, key: "billing_name" },
    { label: t.locations.table.country, key: "country" },
    { label: t.locations.table.city, key: "city" },
    { label: t.customers.form.postal_code, key: "postal_code" },
    { label: t.customers.form.address, key: "address" },
    { label: t.customers.form.rate, key: "rate" },
    { label: t.customers.form.currency, key: "currency" },
    { label: t.workOrders.table.createdByUser, key: "createdBy" },
  ];

  const downloadXLS = () => {
    let csvData = [];

    let customers_data = [];

    customers_data.id = targetedData.id;
    customers_data.name = targetedData.attributes.name || t.common.na;

    customers_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, " ").trim()
      : t.common.na;

    customers_data.email = targetedData?.attributes?.email
      ? targetedData.attributes?.email
      : t.common.na;

    customers_data.phone = targetedData?.attributes?.phone
      ? `+${targetedData?.attributes?.phone}`
      : t.common.na;

    customers_data.locations = targetedData?.attributes?.location?.data?.id
      ? targetedData?.attributes?.location?.data?.attributes?.name
      : t.common.na;

    customers_data.website = targetedData?.attributes?.website
      ? targetedData.attributes?.website
      : t.common.na;

    customers_data.service_type = targetedData?.attributes?.serviceType
      ? targetedData.attributes?.serviceType
      : t.common.na;

    customers_data.industry = targetedData?.attributes?.Industry
      ? targetedData.attributes?.Industry
      : t.common.na;

    customers_data.contact_name = targetedData?.attributes?.contactInformation
      ?.name
      ? targetedData?.attributes?.contactInformation?.name
      : t.common.na;

    customers_data.contact_email = targetedData?.attributes?.contactInformation
      ?.email
      ? targetedData?.attributes?.contactInformation?.email
      : t.common.na;

    customers_data.contact_phone = targetedData?.attributes?.contactInformation
      ?.phone
      ? `+${targetedData?.attributes?.contactInformation?.phone}`
      : t.common.na;

    customers_data.billing_name = targetedData?.attributes?.billingInformation
      ?.name
      ? targetedData?.attributes?.billingInformation?.name
      : t.common.na;

    customers_data.country = targetedData?.attributes?.billingInformation
      ?.country
      ? Country.getCountryByCode(
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.city = targetedData?.attributes?.billingInformation?.city
      ? State.getStateByCodeAndCountry(
          targetedData?.attributes?.billingInformation?.city,
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.postal_code = targetedData?.attributes?.billingInformation
      ?.postalCode
      ? targetedData?.attributes?.billingInformation?.postalCode
      : t.common.na;

    customers_data.address = targetedData?.attributes?.billingInformation
      ?.address
      ? targetedData?.attributes?.billingInformation?.address
      : t.common.na;

    customers_data.rate = targetedData?.attributes?.billingInformation?.rate
      ? targetedData?.attributes?.billingInformation?.rate +
        " " +
        (targetedData?.attributes?.billingInformation?.currency
          ? targetedData?.attributes?.billingInformation?.currency
          : "")
      : t.common.na;

    customers_data.currency = targetedData?.attributes?.billingInformation
      ?.currency
      ? targetedData?.attributes?.billingInformation?.currency
      : t.common.na;

    customers_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        " " +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      customers_data.id,
      customers_data.name,
      customers_data.description,
      customers_data.email,

      customers_data.phone,
      customers_data.locations,
      customers_data.website,
      customers_data.service_type,

      customers_data.industry,
      customers_data.contact_name,
      customers_data.contact_email,
      customers_data.contact_phone,

      customers_data.billing_name,
      customers_data.country,
      customers_data.city,
      customers_data.postal_code,

      customers_data.address,
      customers_data.rate,
      customers_data.currency,
      customers_data.createdBy,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 15 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 10 }, // Created By column
      { wch: 10 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData.attributes.name || t.customers.customer
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let customers_data = [];

    customers_data.id = targetedData.id;
    customers_data.name = targetedData.attributes.name || t.common.na;

    customers_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, " ").trim()
      : t.common.na;

    customers_data.email = targetedData?.attributes?.email
      ? targetedData.attributes?.email
      : t.common.na;

    customers_data.phone = targetedData?.attributes?.phone
      ? `+${targetedData?.attributes?.phone}`
      : t.common.na;

    customers_data.locations = targetedData?.attributes?.location?.data?.id
      ? targetedData?.attributes?.location?.data?.attributes?.name
      : t.common.na;

    customers_data.website = targetedData?.attributes?.website
      ? targetedData.attributes?.website
      : t.common.na;

    customers_data.service_type = targetedData?.attributes?.serviceType
      ? targetedData.attributes?.serviceType
      : t.common.na;

    customers_data.industry = targetedData?.attributes?.Industry
      ? targetedData.attributes?.Industry
      : t.common.na;

    customers_data.contact_name = targetedData?.attributes?.contactInformation
      ?.name
      ? targetedData?.attributes?.contactInformation?.name
      : t.common.na;

    customers_data.contact_email = targetedData?.attributes?.contactInformation
      ?.email
      ? targetedData?.attributes?.contactInformation?.email
      : t.common.na;

    customers_data.contact_phone = targetedData?.attributes?.contactInformation
      ?.phone
      ? `+${targetedData?.attributes?.contactInformation?.phone}`
      : t.common.na;

    customers_data.billing_name = targetedData?.attributes?.billingInformation
      ?.name
      ? targetedData?.attributes?.billingInformation?.name
      : t.common.na;

    customers_data.country = targetedData?.attributes?.billingInformation
      ?.country
      ? Country.getCountryByCode(
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.city = targetedData?.attributes?.billingInformation?.city
      ? State.getStateByCodeAndCountry(
          targetedData?.attributes?.billingInformation?.city,
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.postal_code = targetedData?.attributes?.billingInformation
      ?.postalCode
      ? targetedData?.attributes?.billingInformation?.postalCode
      : t.common.na;

    customers_data.address = targetedData?.attributes?.billingInformation
      ?.address
      ? targetedData?.attributes?.billingInformation?.address
      : t.common.na;

    customers_data.rate = targetedData?.attributes?.billingInformation?.rate
      ? targetedData?.attributes?.billingInformation?.rate +
        " " +
        (targetedData?.attributes?.billingInformation?.currency
          ? targetedData?.attributes?.billingInformation?.currency
          : "")
      : t.common.na;

    customers_data.currency = targetedData?.attributes?.billingInformation
      ?.currency
      ? targetedData?.attributes?.billingInformation?.currency
      : t.common.na;

    customers_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        " " +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      customers_data.id,
      customers_data.name,
      customers_data.description,
      customers_data.email,

      customers_data.phone,
      customers_data.locations,
      customers_data.website,
      customers_data.service_type,

      customers_data.industry,
      customers_data.contact_name,
      customers_data.contact_email,
      customers_data.contact_phone,

      customers_data.billing_name,
      customers_data.country,
      customers_data.city,
      customers_data.postal_code,

      customers_data.address,
      customers_data.rate,
      customers_data.currency,
      customers_data.createdBy,
    ]);
    const columnWidths = [, 30, 30];

    DownloadPdfFileWithData(
      headers,
      csvData,
      customers_data.name || t.customers.customer,
      columnWidths,
      "A2"
    );
  };

  const panes = [
    {
      menuItem: t.customers.table.details,
      pane: {
        key: "details",
        content: (
          <div className="pane-body">
            <Details untoggle={untoggle} targetedData={targetedData} />
          </div>
        ),
      },
    },
    {
      menuItem: t.customers.form.billing_information,
      pane: {
        key: "customers",
        content: (
          <div className="pane-body">
            <Customers
              untoggle={untoggle}
              targetedData={targetedData?.attributes?.billingInformation}
            />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Logs
    //           targetedData={targetedData}
    //         />
    //       </div>
    //     ),
    //   },
    // },
  ];
  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.customers.customer} #{data?.attributes?.countId}
              </div>
              <div className="flexbox align-center detail-header-buttons">
                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={userPermissions?.customer?.delete}
                  isEdit={
                    userPermissions?.customer?.edit ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                />

                <div
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="body">
            {data ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => {
          setData();
          untoggle();
        }}
      ></div>

      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.customers.customer}
      >
        <span>{t.customers.form.delete_customer_statement}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
