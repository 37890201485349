import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../../config/i18n/slice";
import {
  ArrowIcon,
  DamageComments,
  DateDetailsIcon,

  FlagwithFilled,
  InspectionsComments,

} from "../../../../../../Assets/Icons/svg";
import { Divider, Image, Image as SemanticImage } from "semantic-ui-react";
import { LetteredAvatarImageUrl } from "../../../../../components/common/userProfilePlaceholder";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

function AllDetailCard({

}) {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="message-container">
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={
          
            LetteredAvatarImageUrl("Dummy")
          }
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updated the <span className="name">Status</span>{" "}
            </span>
          </p>

          <div className="status-body">
            <div className="status-change">
              <span className="status open">Open</span>
              <ArrowIcon />
              <span className="status inProgres">In-Progress</span>
            </div>
          </div>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
    
      <div className="message read">
        <Image
          avatar
          title={"Test"}
          src={
        
            LetteredAvatarImageUrl("Test")
          }
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-3">
            <span className="sub-name">
              Firas Smith an <span className="name">Image Attachment</span>{" "}
            </span>
          </p>
          <div className="message-body mb-1 image-attachment">
            <SemanticImage
              src={toAbsoluteUrl("/media/default-img.png")}
              size="medium"
              className={"no-img"}
            />
          </div>
          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={
          
            LetteredAvatarImageUrl("Dummy")
          }
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith added <span className="name">Secondary Assignees</span>{" "}
            </span>
          </p>

          <p className="message-body mb-1">Name Surname, Name Surname</p>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />{" "}
      <div className="message">
        <Image
          avatar
          title={"Dummy"}
          src={
          
            LetteredAvatarImageUrl("Dummy")
          }
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith added <span className="name">Estimation Time</span>{" "}
            </span>
          </p>

          <p className="message-body mb-1">
            10 <span className="hour">Hr</span>
          </p>
          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={
         
            LetteredAvatarImageUrl("Dummy")
          }
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updated the <span className="name">Procedure</span>{" "}
            </span>
          </p>

          <p className="message-body mb-1">ID 445 Procedure Name</p>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={
          
            LetteredAvatarImageUrl("Dummy")
          }
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updating the <span className="name">Description</span>
            </span>
          </p>
          

          <div>
            <div className="">
              <p className="message-body prev-content mb-0">
                The technician should do a daily check based on the items
                mentioned{" "}
              </p>
              <ArrowIcon />
              <p className="message-body mb-1">
                {" "}
                The technician should do a daily check based on the items
                mentioned{" "}
              </p>
            </div>
          </div>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={LetteredAvatarImageUrl("Dummy")}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updated the <span className="name">Priority</span>
            </span>
          </p>

          <div className="status-body">
            <div className="status-change">
              <div className={`message-body mt-0 low`}>
                <FlagwithFilled color={"#4FAB68"} />
                <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  Low{" "}
                </span>
              </div>
              <ArrowIcon />
              <div className={`message-body mt-0 medium`}>
                <FlagwithFilled color={"#E2B203"} />
                <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  Medium{" "}
                </span>
              </div>
            </div>
          </div>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={LetteredAvatarImageUrl("Dummy")}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updated the <span className="name">Category</span>
            </span>
          </p>

          <div className="status-body">
            <div className="status-change">
              <div className={`message-body mt-0 low`}>
                <DamageComments />
                <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  Damage{" "}
                </span>
              </div>
              <ArrowIcon />
              <div className={`message-body mt-0 medium`}>
                <InspectionsComments />
                <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  Inspection{" "}
                </span>
              </div>
            </div>
          </div>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={LetteredAvatarImageUrl("Dummy")}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updated the <span className="name">Start Date</span>
            </span>
          </p>

          <div className="status-body">
            <div className="status-change">
              <div className={`message-body mt-0 low`}>
                <DateDetailsIcon height="18" width="18" color="#44546F" />
                <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  12/01/2025{" "}
                </span>
              </div>
              <ArrowIcon />
              <div className={`message-body mt-0 medium`}>
                <DateDetailsIcon height="18" width="18" color="#44546F" />
                <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  15/01/2025{" "}
                </span>
              </div>
            </div>
          </div>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
      <Divider className="mb-0 mt-0" />
      <div className="message unread">
        <Image
          avatar
          title={"Dummy"}
          src={LetteredAvatarImageUrl("Dummy")}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div className="message-content">
          <p className="message-header mb-1">
            <span className="sub-name">
              John Smith updated the{" "}
              <span className="name">Estimation Time </span>
            </span>
          </p>

          <div className="status-body">
            <div className="status-change">
              <div className={`message-body mt-0 low`}>
                <span>
                  10 <span className="hour">Hr</span>
                </span>
              </div>
              <ArrowIcon />
              <div className={`message-body mt-0 medium`}>
                <span>
                  20 <span className="hour">Hr</span>
                </span>
              </div>
            </div>
          </div>

          <p
            className={`${
              selectedLanguage === "en" ? "text-right" : "text-left"
            }`}
          >
            <span className="time-data">November 12, 2024 at 12:55 PM</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AllDetailCard;
