import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllCustomers, getAllVendros } from '../../app/config/functions';
const initialState = {
  customerData: [],
  allCustomerData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  customerInitData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch customer data
 */
export const fetchCustomerData = createAsyncThunk(
  'customer/list',
  async ({organisationId,role}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllCustomers(organisationId, '', 1, 25,[],[],[],{},[],role,[]);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial customer data
 */
export const fetchCustomerInitData = createAsyncThunk(
  'customer/initData',
  async ({organisationId,role}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllCustomers(organisationId, '', 1, 25,[],[],[],{},[],role,[]);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all customer data
 */
export const fetchAllCustomerData = createAsyncThunk(
  'customer/allData',
  async ({organisationId,role}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllCustomers(organisationId, '', 1, 99999,[],[],[],{},[],role,[]);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter customer data
 */
export const fetchCustomerWithFilter = createAsyncThunk(
  'customer/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      location = [],
      country = [],
      city = [],
      rate = {},
      sort = [],
      role ='',
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllCustomers(
        organisationId,
        searchName,
        page,
        pageSize,
        location,
        country,
        city,
        rate,
        sort,
        role,
      []
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCustomerData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInitData = action.payload.response;
        state.customerData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchCustomerData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchCustomerInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.customerInitData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchCustomerInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCustomerWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerData = action.payload.response;
        state.allCustomerData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
        // if (state.customerInitData && state.customerInitData.length <= 0) {
        //   state.customerInitData = action.payload.response;
        // }
      })
      .addCase(fetchCustomerWithFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllCustomerData.pending, (state) => {})
      .addCase(fetchAllCustomerData.fulfilled, (state, action) => {
        state.allCustomerData = action.payload.response;
      })
      .addCase(fetchAllCustomerData.rejected, (state, action) => {});
  },
});

export default customerSlice.reducer;
