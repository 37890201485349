import React from "react";

//Import transition file
import { selectTranslations } from "../../../../config/i18n/slice";

//Import semantic-ui-react components
import { Dimmer, Loader, Modal } from "semantic-ui-react";
//Import svg
import {
  CloseIcon,
  DottedClock,
  PlusClock,
} from "../../../../../Assets/Icons/svg";
import { useSelector } from "react-redux";

//Import instance WO cards components
import WorkOrderItems from "./workOrderItems";

const ShowInstance = ({
  instancesModal,
  setInstancesModal,
  schedualWOdata,
  instancesModalLoading,
}) => {
  const t = useSelector(selectTranslations);

  return (
    <Modal
      className="assets-modal"
      size="tiny"
      open={instancesModal}
      onClose={() => {
        setInstancesModal(false);
      }}
    >
      <div className="header-container">
        <span className="assets-header">{t.workOrders.form.instances}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => {
              setInstancesModal(false);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content>
        <div className="instance-container scroll-data">
          {instancesModalLoading ? (
            <Dimmer active inverted>
              <Loader size="medium">Loading</Loader>
            </Dimmer>
          ) : (
            <>
              {schedualWOdata?.completedWO?.length > 0 ? (
                <div className="section">
                  <button className="button">
                    <DottedClock />
                    {t.workOrders.details_view.pastWO}
                  </button>
                  <div className="orders-list">
                    {schedualWOdata?.completedWO?.length > 0
                      ? schedualWOdata?.completedWO.map((order, index) => (
                          <WorkOrderItems
                            setInstancesModal={setInstancesModal}
                            key={index}
                            {...order}
                          />
                        ))
                      : null}
                  </div>
                </div>
              ) : null}
              {schedualWOdata?.nonCompletedWO?.length > 0 ? (
                <div className="section">
                  <button className="button blue">
                    <PlusClock />
                    {t.workOrders.details_view.upcomingWO}
                  </button>
                  <div className="orders-list">
                    {schedualWOdata?.nonCompletedWO?.length > 0 ? (
                      schedualWOdata?.nonCompletedWO.map((order, index) => (
                        <WorkOrderItems
                          setInstancesModal={setInstancesModal}
                          isPast={false}
                          key={index}
                          {...order}
                        />
                      ))
                    ) : (
                      <div className="text-center">{t.common.na}</div>
                    )}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ShowInstance;
