export default {
  vendors: 'Vendors',
  vendor: 'Vendors',
  add_vendor: 'Add Vendor',
  title_small: 'vendors',
  title_vendor: 'vendor',
  title_vendor_1: 'Vendor',
  how_to_add_vendor: 'how to add vendor',
  no_vendor_added: 'Start adding vendor',
  table: {
    id: 'ID',
    name: 'Vendor Name',
    phone: 'Phone',
    email: 'Email',
    website: 'Website',
    country: 'Country',
    city: 'City',
    service_type: 'Service Type',
    hourly_rate: 'Hourly Rate',
    created_by: 'Created By',
    created_at: 'Created At',
    updated_at: 'Updated At',
    vendor_info: 'Vendor Information',
  },
  filter: {
    search: 'Search',
    country: 'Country',
    search_by_country: 'Search by Country',
    cities: 'Cities',
    search_by_cities: 'Search by Cities',
    created_at: 'Created At',
    updated_at: 'Updated At',
    selected: 'selected',
  },
  created_at_options: {
    any_date: 'Any Date',
    today: 'Today',
    this_month: 'This Month',
    this_year: 'This Year',
    last_7_days: 'Last 7 Days',
    last_month: 'Last Month',
    last_year: 'Last Year',
  },
  no_vendor_statement: "Looks like you don't have Vendors yet.",
  press_to_add_vendor: 'Press the ' + ' button to add your first Vendor.',
  mode: {
    credit_card: 'Credit Card',
    cash: 'Cash',
    bank: 'Bank Transfer',
    procurement_card: 'Procurement Card',
    check: 'Check',
    cliq: 'Cliq',
  },
  account: {
    basic: 'Basic Bank Account',
    time_deposit: 'Time Deposit Account',
    time_deposit_price: 'Time deposit with prizes',
    current: 'Current Account',
    pos: 'POS Current Account',
    salary: 'Salaries Account',
  },
  form: {
    acc_no:"Account NO",
    mode: 'Mode',
    bank: 'Bank',
    account: 'Account Type',
    IBAN: 'IBAN',
    disbursement_currency: 'Disbursement Currency',
    add_disbursement_currency: 'Add disbursement currency',
    IBAN_placeholder: 'JO 0000 0000 0000 0000 0000 ',
    account_no: 'Account No.',
    add_account_no: 'Add account No.',
    add_account: 'Add account type',
    add_bank: 'Account bank',
    add_mode: 'Add mode',
    add_pre_days: 'Add pre-process days',
    pre_days: 'Pre-Process Days',
    add_credit_days: 'Add credit days',
    credit_days: 'Credit Days',
    start_dt: 'Start Dt',
    end_dt: 'End Dt',
    start_date_new:"Start Date/Time",
    due_date_new:"Due Date/Time",
    start_date: 'Start Date',
    end_date: 'End Date',
    add_start_date: 'Add start date',
    add_end_date: 'Add end date',
    add_vendor: 'Add Vendor',
    edit_vendor: 'Edit Vendor',
    name: 'Name',
    add_vendor_name: 'Add vendor name',
    description: 'Description',
    location: 'Location',
    select_location: 'Select Location',
    phone: 'Phone',
    website: 'Website',
    service_type: 'Service Type',
    example: 'Ex. Plumbing, Electrical, etc',
    email: 'Email',
    logo: 'Logo',
    add: 'Add+',
    billing_information: 'Billing Information',
    payment_info: 'Payment Details',
    country: 'Country',
    city: 'City',
    postal_code: 'Postal Code',
    address: 'Address',
    address_line_2: 'Address Line 2',
    address_line_3: 'Address Line 3',
    rate: 'Rate',
    currency: 'Currency',
    submit: 'Submit',
    cancel: 'Cancel',
    vendor_add_success: 'Vendor added successfully',
    vendor_updated_success: 'Vendor updated successfully',
    invalid_email: 'Invalid email',
    invalid_phone: 'Invalid phone',
    delete_vendor_statement: 'Are you sure you want to delete this vendor?',
    vendor_delete_success: 'Vendor deleted successfully',
    vendor_delete_related_error:
      'Unable to delete vendor. Linked with other forms',
  },
};
