import moment from "moment";

export const isStartDue = (status, startDate) => {
  const formattedStartDate = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : null;
  const today = moment().format("YYYY-MM-DD");

  return status === "open"
    ? formattedStartDate
      ? today > formattedStartDate
      : false
    : false;
};

export const isPastDue = (date, status) => {
  var date = moment(date).format("YYYY-MM-DD");
  var now = moment().format("YYYY-MM-DD");

  return status === "cancelled" || status === "completed"
    ? false
    : now > date;
};
